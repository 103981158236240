import React from 'react';
import TriggeredRebilling from 'users/containers/Organization/TriggeredRebilling/TriggeredRebilling.jsx';
import ManageCustomers from './ManageCustomers';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';

const ManageCustomersPage = ({ ...props }) => {
  const { appStore } = useRootStore();
  if (appStore.isKeyCloakManagement) {
    return <TriggeredRebilling {...props} />;
  }
  return <ManageCustomers {...props} />;
};

export default ManageCustomersPage;
