import React from 'react';
import PropTypes from 'prop-types';
import { dashLegend } from 'shared/components/chartComponents/Legends/LegendCommon';
import styles from 'shared/components/chartComponents/Legends/customizedLegend.module.scss';

const RecommendedCustomizedLegend = ({
  legend,
  turnedOffLines,
  onClickLegendPair,
  hideKeys,
  className,
  dashLegends,
}) => (
  <div className={`${styles?.customizedLegendWrapper} ${className?.customizedLegend}`}>
    <div className={styles.customizedLegend}>
      {legend?.map((entry) => {
        const entryId = `${entry.key}Legend`;
        return !hideKeys || !hideKeys.includes(entry.key) ? (
          <div
            className={styles.legendPair}
            onClick={onClickLegendPair}
            id={entryId}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onClickLegendPair(e);
              }
            }}
          >
            {!dashLegends?.includes(entry.key) ? (
              <div
                className={styles.legendSymbol}
                style={
                  turnedOffLines?.includes(entryId)
                    ? { border: `1px solid ${entry.color}` }
                    : { backgroundColor: entry.color }
                }
              />
            ) : (
              dashLegend(entry.color, entry.key)
            )}
            <div className={styles.legendLabel}>{entry.name}</div>
          </div>
        ) : null;
      })}
    </div>
  </div>
);

RecommendedCustomizedLegend.propTypes = {
  legend: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  turnedOffLines: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickLegendPair: PropTypes.func.isRequired,
  hideKeys: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.object,
  dashLegends: PropTypes.arrayOf(PropTypes.string),
};

export default RecommendedCustomizedLegend;
