import React from 'react';
import { CustomersProvider } from 'users/containers/Organization/Customers/contexts/customersContext.jsx';
import Customers from 'users/containers/Organization/Customers/Customers.jsx';

const CustomersWrapper = () => {
  return (
    <CustomersProvider>
      <Customers />
    </CustomersProvider>
  )
}

export default CustomersWrapper;
