import React from 'react';
import { Form, Input } from 'reactstrap';
import classNames from 'classnames';
import RecipientsSelect from 'shared/components/RecipientsSelect.jsx';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import { validateRecipients } from 'shared/utils/sharedUtils.js';
import { getAnodotReactSelectStyles } from 'shared/components/AnodotReactSelect';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { Option, ServiceCostAlert } from '../types.ts';
import t from '../texts.ts';
import ColoredBlock from 'shared/components/ColoredFormBlock';
import styles from '../alerts.module.scss';
import MyEmailBtn from '../components/MyEmailBtn.tsx';
import FiltersServiceCost from './FiltersServiceCost.tsx';
import AlertTypeFormBlock from './AlertTypeFormBlock.tsx';
import AlertChangesFormBlock from './AlertChangesFormBlock.tsx';

export interface FormServiceCostProps {
  alert: Partial<ServiceCostAlert>;
  disabled?: boolean;
  showErrors?: boolean;
  isChangesValid?: boolean;

  setAlert?(field: keyof ServiceCostAlert | string, value: string | number | Option[]): void;
}

const FormServiceCost: React.FC<FormServiceCostProps> = (props) => {
  const { isChangesValid, showErrors, setAlert, alert, disabled } = props;
  const { appStore } = useRootStore();
  const { isPpApplied } = appStore;

  const { currencySymbol } = useUserSettingsContext();
  const { getChannels } = useChannels();
  const { data: channels, isLoading: isLoadingChannels } = getChannels();

  const isValidRecipients = validateRecipients(alert.recipientsEmails, alert.recipients);

  const getPricingMode = () => {
    let label = isPpApplied ? 'Customer' : 'Partner';
    if (isPpApplied === undefined) {
      label = 'N/A';
    }
    return label;
  };
  const isEdit = !disabled && !!alert?.uuid;
  const isCreate = !disabled && !alert?.uuid;
  let automationPostfix = '';
  if (isEdit || isCreate) {
    automationPostfix = isEdit ? '-edit' : '-create';
  }

  return (
    <Form
      className={classNames(styles.serviceCostForm, { [styles.formDisabled]: disabled })}
      automation-id={`form${automationPostfix}`}
    >
      <div>
        <ColoredBlock className="mb-3" icon="memo" color="blue">
          <h5>{t('ALERT_NAME')}</h5>
          <Input
            automation-id={`alert-name-field${automationPostfix}`}
            value={alert.alertName}
            margin="normal"
            variant="outlined"
            placeholder={t('ALERT_NAME')}
            className={classNames(styles.inputServiceCost, 'w-100')}
            disabled={disabled}
            invalid={showErrors && !disabled && !isChangesValid && !alert.alertName}
            onChange={(e) => setAlert?.('alertName', e.target.value)}
          />
        </ColoredBlock>
        <AlertTypeFormBlock {...props} automationPostfix={automationPostfix} />
        <AlertChangesFormBlock {...props} automationPostfix={automationPostfix} currencySymbol={currencySymbol} />
        <ColoredBlock className={styles.unsetContentOverflowY} icon="envelope" color="eucaliptus">
          <div automation-id={`recipient-field-wrapper${automationPostfix}`}>
            <div className="d-flex justify-content-between">
              <h5>{t('RECIPIENTS')}</h5>
              {!disabled && <MyEmailBtn onClick={(myEmail) => setAlert?.('recipientsEmails', myEmail)} />}
            </div>
            <Input
              automation-id={`email-field${automationPostfix}`}
              value={alert.recipientsEmails}
              margin="normal"
              variant="outlined"
              placeholder={t('EXAMPLE_EMAIL')}
              className={styles.inputServiceCost}
              disabled={disabled}
              invalid={showErrors && !disabled && !isValidRecipients}
              onChange={(e) => setAlert?.('recipientsEmails', e.target.value)}
            />
            <RecipientsSelect
              className={classNames(styles.select, styles.recipientsSelect)}
              channels={channels}
              isLoading={isLoadingChannels}
              savedRecipients={alert.recipients}
              onChangeHandler={(recipients) => setAlert?.('recipients', recipients)}
              isDisabled={disabled}
              selectStylesConfig={getAnodotReactSelectStyles({ disabled, isEmpty: !alert.recipients, height: 35 })}
            />
          </div>
        </ColoredBlock>
      </div>
      <div className="position-relative">
        <ColoredBlock
          icon="filters"
          color="marine"
          scrollable
          style={{ position: 'absolute', height: '100%', width: '100%' }}
        >
          <FiltersServiceCost alert={alert} disabled={disabled} setAlert={setAlert} />
          <div className="mb-2 mt-2">
            <h5>{t('PRICING_MODE')}</h5>
            <Input
              automation-id={`pricing-mode-field${automationPostfix}`}
              value={getPricingMode()}
              margin="normal"
              variant="outlined"
              className={styles.inputServiceCost}
              disabled
            />
          </div>
        </ColoredBlock>
      </div>
    </Form>
  );
};

export default FormServiceCost;
