export const FILTER_OPERATIONS = {
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_OR_EQUAL: 'greaterThanOrEqual',
  LESS_THAN: 'lessThan',
  LESS_THAN_OR_EQUAL: 'lessThanOrEqual',
};

export const FILTER_STRING_OPERATIONS = [
  FILTER_OPERATIONS.CONTAINS,
  FILTER_OPERATIONS.NOT_CONTAINS,
  FILTER_OPERATIONS.EQUAL,
  FILTER_OPERATIONS.NOT_EQUAL,
];

export const FILTER_NUMBER_OR_DATE_OPERATIONS = [
  FILTER_OPERATIONS.EQUAL,
  FILTER_OPERATIONS.NOT_EQUAL,
  FILTER_OPERATIONS.GREATER_THAN,
  FILTER_OPERATIONS.GREATER_THAN_OR_EQUAL,
  FILTER_OPERATIONS.LESS_THAN,
  FILTER_OPERATIONS.LESS_THAN_OR_EQUAL,
];
