import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FilterTypes } from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { PAYMENT_OPTIONS, TERMS } from 'commitment/containers/spAnalyzerNew/utils/consts';
import useSpAnalyzer from 'commitment/containers/spAnalyzerNew/hooks/useSpAnalyzer.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { usePreferences } from 'users/hooks/react-query/usePreferences.js';
import { UsersType } from 'users/constants/usersConstants.js';


const SpAnalyzerContext = createContext();
export const SpAnalyzerProvider = observer(({ children }) => {
  const { usersStore, appStore } = useRootStore();
  const [viewType, setViewType] =
      useState(usersStore.currentDisplayedUserType === UsersType.RESELLER_CUSTOMER ? 'linkedAccount' : 'payer');
  const [planDataByKey, setPlanDataByKey] = useState({});
  const [daysToCheck, setDaysToCheck] = useState(null);
  const [coverage, setCoverage] = useState(null);
  const [excludeExpiringSPsDays, setExcludeExpiringSPsDays] = useState(null);
  const [hourlyCommitment, setHourlyCommitment] = useState(null);
  const [modifiedPlanKeyHourlyCommitment, setModifiedPlanKeyHourlyCommitment] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [filterStatusType, setFilterStatusType] = useState({
    [AwsCommonFields.LINKED_ACCOUNT_ID]: FilterTypes.INCLUDE,
  });
  const { fetchSavingsPlansAnalyzerData } = useSpAnalyzer();
  const { fetchPreferences } = usePreferences();
  const { data: preferencesData, isLoading: isPreferencesLoading } = fetchPreferences(usersStore.currDispUserAccountKey);
  const { data: recommendationPlanData, isLoading, refetch, isFetching } = fetchSavingsPlansAnalyzerData(
      {
          selectedOptions,
          daysToCheck,
          filterStatusType,
          selectedPaymentOption: PAYMENT_OPTIONS,
          selectedTerm: TERMS,
          coverage,
          hourlyCommitment,
          excludeExpiringSPsDays,
          limitEnableApi: (!usersStore.isCurrentUserReseller && (usersStore.currDispUserDivisionName || appStore.isCustomerPricingMode) )
              || usersStore.isCurrentUserReseller && appStore.isCustomerPricingMode,
      }
  );

  const preferences = useMemo(() => {
      if(!preferencesData) {
          return {};
      }
      const savingPreferences = preferencesData.find((pref) => pref.preferenceKey === 'ec2-savings-plans');
      return savingPreferences?.preferences || {};
  }, [preferencesData]);

  useEffect(() => {
     if (preferences) {
        setCoverage(preferences.coveragePercentage * 100 || 80);
        setDaysToCheck(preferences.daysToCheck || 14);
     }
  }, [preferences]);

    useEffect(() => {
        setViewType(!usersStore.isCurrentUserReseller && (usersStore.currDispUserDivisionName || appStore.isCustomerPricingMode) ? 'linkedAccount' : 'payer');
    }, [usersStore.isCurrentUserReseller, usersStore.currDispUserDivisionName, appStore.isCustomerPricingMode]);

  return (
    <SpAnalyzerContext.Provider
      value={{
        filterStatusType,
        recommendationPlanData,
        setFilterStatusType,
        selectedOptions,
        setSelectedOptions,
        daysToCheck,
        setDaysToCheck,
        coverage,
        setCoverage,
        excludeExpiringSPsDays,
        setExcludeExpiringSPsDays,
        hourlyCommitment,
        setHourlyCommitment,
        modifiedPlanKeyHourlyCommitment,
        setModifiedPlanKeyHourlyCommitment,
        preferences,
        planDataByKey,
        setPlanDataByKey,
        isPreferencesLoading,
        isLoading,
        refetch,
        isFetching,
        viewType,
        setViewType,
      }}
    >
      {children}
    </SpAnalyzerContext.Provider>
  );
});

SpAnalyzerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSpAnalyzerContext = () => useContext(SpAnalyzerContext);
