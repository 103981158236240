import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { HierarchicalEntityCategory, Action } from '@anodot-cost/rbac-client';
import ICONS from 'shared/constants/assetsConstants';
import { Routes } from 'shared/constants/routes';
import IconFromPng from 'shared/components/IconFromPng';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import PanelWithActions from 'shared/components/PanelWithActions';
import Spinner from 'shared/components/andtComponents/Spinner';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import EditCustomDashboardPanelModal from 'shared/components/EditCustomDashboardPanelModal';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { strNumToSize } from 'shared/utils/strUtil';
import CustomDashboardActivePanelBuilder from 'usage/store/subStores/helperClasses/customDashboardActivePanelBuilder';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { prepareRecommendationsFiltersMap } from 'recommendations/utils/recommUtils';
import {
  PANEL_COMPONENT_TYPES,
  propsEvaluatedChartTypeToChartOrTableComponent,
} from 'usage/containers/CustomDashboard/constants/customDashboardConstants';
import dashboardSummaryProvider from 'recommendations/hooks/react-query/dashboardSummaryProvider';
import recommendationOpportunitiesProvider from 'recommendations/hooks/react-query/recommendationOpportunitiesProvider';
import { cloneDeep } from 'lodash';

const actionButtonStyle = {
  marginInlineEnd: '5px',
  height: '23px',
  transform: 'scale(1.4)',
  marginRight: 12,
};

const CustomDashboardActivePanel = ({
  currentUserOwner,
  customDashboardStore,
  dashboardId,
  dbAccountId,
  fromTemplate,
  handleRemovePanelClicked,
  handlePanelLoading,
  hasDashboardFilters,
  id,
  isPpApplied,
  numStrAbriviaionByDisplayMetric,
  overrideCurrency,
  panelId,
  panelSettings,
  panelsForceReload,
  filters,
}) => {
  const { usersStore, usageStore } = useRootStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [activePanel, setActivePanel] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [currentOverrideCurrency, setCurrentOverrideCurrency] = useState(overrideCurrency);

  const dashboardSummaryDataProvider = dashboardSummaryProvider({
    isEnabled: usersStore.currentDisplaydUser.isPayingUser,
  });
  const opportunitiesProvider = recommendationOpportunitiesProvider();
  const loadData = async () => {
    setIsLoading(true);
    const panel = await customDashboardStore.customDashboardModel.getPanelById(
      panelId,
      fromTemplate ? dbAccountId : undefined,
    );
    const recommendationsFunctions = {
      fetchRecommendationOpportunities: (
        startDate,
        endDate,
        filterParams,
        periodGranLevel,
        recommendationType,
        dashboardId,
        currentLimit,
      ) =>
        opportunitiesProvider.fetchRecommendationOpportunities(
          startDate,
          endDate,
          filterParams,
          periodGranLevel,
          recommendationType,
          dashboardId,
          currentLimit,
        ),
      fetchCustomDashboardPanelSummaryTableData: (filtersMap, lastProcessDate, currentLimit) =>
        dashboardSummaryDataProvider.fetchCustomDashboardPanelSummaryTableData(
          filtersMap,
          lastProcessDate,
          currentLimit,
        ),
    };
    const builder = new CustomDashboardActivePanelBuilder(usersStore.rootStore, recommendationsFunctions, filters);
    const panelFormatted = builder.buildActiveDashboardInstance(panel, panelSettings, null, dashboardId, fromTemplate);
    setActivePanel(panelFormatted);
    try {
      const panelData = await panelFormatted.fetchData();
      setIsLoading(false);
      setPanelData(Array.isArray(panelData) ? panelData : [{ ...panelData }]);
      setActivePanel(panelFormatted);
      setCurrentOverrideCurrency(panel.currencyCode);
    } catch {
      setIsLoading(false);
    }
  };

  const handleEditPanelClicked = () => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  const removePanelClicked = async () => {
    await handleRemovePanelClicked(dashboardId, activePanel);
  };

  useEffect(() => {
    loadData();
  }, []);

  const getPreviousValue = (value) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current; // (This will have the previous value)
  };

  const prevIsLoadingValue = getPreviousValue(isLoading);
  const prevPanelsForceReload = getPreviousValue(panelsForceReload);
  const prevIsPpApplied = getPreviousValue(isPpApplied);

  useEffect(() => {
    if (isLoading !== prevIsLoadingValue) {
      handlePanelLoading(!isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (prevPanelsForceReload === undefined || prevIsPpApplied === undefined) {
      return;
    }
    if (panelsForceReload !== prevPanelsForceReload || isPpApplied !== prevIsPpApplied) {
      loadData();
    }
  }, [panelsForceReload, isPpApplied]);

  const getActionListParams = () => {
    const { type, ...rest } = activePanel;
    const actionListParams = [
      {
        title: 'Remove',
        handler: removePanelClicked,
        dashboardId,
        panelId: rest.uuid,
      },
    ];
    if (type !== 'kpis') {
      actionListParams.unshift({
        title: 'Edit',
        handler: handleEditPanelClicked,
      });
    }
    return actionListParams;
  };

  const getMagnifierGlassPathname = (panelType, isK8S) => {
    if (panelType.includes('assets')) {
      if (isK8S) {
        return Routes.K8S_ASSETS;
      }
      return Routes.ASSETS;
    }
    if (panelType.includes('k8s')) {
      return Routes.K8S_USAGE;
    }
    if (
      panelType === PANEL_COMPONENT_TYPES.RECOMM_CLOUD_WASTE ||
      panelType === PANEL_COMPONENT_TYPES.RECOMM_SUMMERY_TABLE
    ) {
      return Routes.REC_EXPLORER;
    }
    if (
      panelType === PANEL_COMPONENT_TYPES.HEAT_MAP ||
      panelType === PANEL_COMPONENT_TYPES.CLOUD_WASTE ||
      panelType === PANEL_COMPONENT_TYPES.SAVING_OPPORTUNITIES
    ) {
      return Routes.REC_EXPLORER;
    }
    return Routes.COST_USAGE_EXPLORER;
  };

  const getMagnifierGlassSearchParams = (panelType, startDate, endDate, id, state) => {
    if (
      panelType === PANEL_COMPONENT_TYPES.HEAT_MAP ||
      panelType === PANEL_COMPONENT_TYPES.CLOUD_WASTE ||
      panelType === PANEL_COMPONENT_TYPES.SAVING_OPPORTUNITIES
    ) {
      return `?isWasteChart=${state?.isWasteChart}&filterContext=${JSON.stringify(
        state?.filterContext,
      )}&groupBy=${JSON.stringify(state?.groupBy)}`;
    }
    return `?panelId=${JSON.stringify(id)}&startDate=${JSON.stringify(startDate)}&endDate=${JSON.stringify(endDate)}`;
  };
  const calcChartSpan = (activePanelSpan) => {
    const SpanToColMap = {
      1: 6,
      2: 12,
      3: 4,
      4: 3,
      5: 8,
      6: 9,
    };
    return SpanToColMap[activePanelSpan] || 6;
  };

  const loadCSVData = async () => {
    const formattedData = cloneDeep(panelData);
    if (activePanel?.state?.SelectedUsageType === 'Bytes') {
      formattedData.forEach((element) => {
        Object.entries(element).forEach(([k, v]) => {
          if (+v && k !== 'usagedate') {
            element[k] = strNumToSize(+v, 2, usersStore.currDispUserCloudAccountType);
          } else {
            element[k] = v;
          }
        });
      });
    }

    return [{ data: formattedData, filename: `${activePanel.name}-data.csv` }];
  };

  const isMagnifierAvailable = (type, cloudAccountId) => {
    const { getCurrentDisplayedAccountId, currDispUserAccountKey } = usersStore;
    if (type === PANEL_COMPONENT_TYPES.BUDGET_CHART) {
      return false;
    }
    return !type.includes('kpis') && cloudAccountId === getCurrentDisplayedAccountId(currDispUserAccountKey);
  };

  const handleEditPanel = async (preparedParams) => {
    try {
      setIsLoading(true);
      await customDashboardStore.updateCustomDashboardPanel(activePanel, preparedParams);
      await loadData();
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const renderMagnifierGlassComponent = (type, chartType, restParams) => {
    if (!isMagnifierAvailable(type, restParams.accountId)) {
      return null;
    }
    const { state, uuid, name, routeParams, startDate, endDate } = restParams;
    return (
      <LinkIconButton id={uuid} containerStyle={actionButtonStyle}>
        <Link
          key={uuid}
          to={{
            pathname: `${getMagnifierGlassPathname(type, (state || {}).isK8S)}`,
            search: `${getMagnifierGlassSearchParams(type, startDate, endDate, uuid, state)}`,
            source: {
              id: uuid,
              name,
              hasDashboardFilters,
              fromTemplate,
              isFromCustomDashboard: true,
              state: state
                ? {
                    ...state,
                    selectedGranLevel: (routeParams || {}).periodGranLevel || state.selectedGranLevel,
                    currPeriodGranLevel: (routeParams || {}).periodGranLevel || state.currPeriodGranLevel,
                    isWasteChart:
                      type === PANEL_COMPONENT_TYPES.SAVING_OPPORTUNITIES || type === PANEL_COMPONENT_TYPES.CLOUD_WASTE,
                  }
                : null,
              routeParams,
              isDisplayTable: chartType.includes('table'),
              isPieChart: chartType.includes('pie'),
              isLineChart: (state || {}).isLineChart,
              isAreaChart: (state || {}).isAreaChart,
              startDate,
              endDate,
              filtersMap: [
                PANEL_COMPONENT_TYPES.RECOMM_CLOUD_WASTE,
                PANEL_COMPONENT_TYPES.RECOMM_SUMMERY_TABLE,
              ].includes(type)
                ? prepareRecommendationsFiltersMap((routeParams || {}).filterSelectedValues)
                : (routeParams || {}).filtersMap,
            },
          }}
        />
        <IconFromPng size={23} icon={ICONS.reportGlass} />
      </LinkIconButton>
    );
  };

  const panelIsEmpty = (panelData, chartType) =>
    isEmptyArray(panelData) &&
    ![
      PANEL_COMPONENT_TYPES.HEAT_MAP,
      PANEL_COMPONENT_TYPES.CLOUD_WASTE,
      PANEL_COMPONENT_TYPES.SAVING_OPPORTUNITIES,
    ].includes(chartType);

  const renderChartOrTable = () => {
    const { chartType, ...rest } = activePanel;
    const { chartOrTableProps, ChartOrTable } = propsEvaluatedChartTypeToChartOrTableComponent(
      chartType,
      rest,
      panelData,
      usageStore,
    );
    if (!ChartOrTable || panelIsEmpty(panelData, chartType)) {
      return <NoDataFoundComponent bodyText=" " />;
    }
    if (chartType === PANEL_COMPONENT_TYPES.TWO_AXES_LINE) {
      return (
        <ChartOrTable
          data={panelData}
          {...chartOrTableProps}
          usersStore={usersStore}
          numStrAbriviaionByDisplayMetric={numStrAbriviaionByDisplayMetric}
          overrideCurrency={currentOverrideCurrency}
        />
      );
    }
    return (
      <ChartOrTable
        data={panelData}
        {...chartOrTableProps}
        usersStore={usersStore}
        overrideCurrency={currentOverrideCurrency}
      />
    );
  };

  const chartSpan = calcChartSpan(panelSettings?.span);
  if (isLoading && !activePanel) {
    return (
      <Col md={6} lg={chartSpan} style={{ padding: 50 }} className="panel-is-loading">
        <Spinner />
      </Col>
    );
  }
  const { chartType, type, ...rest } = activePanel;
  const selectedPanelPeriod =
    +(rest.periodParams || {}).relativeDatesDaysDiff || (rest.periodParams || {}).relativeDatesDaysDiff;
  return (
    <Col md={12} lg={chartSpan} className={isLoading ? 'panel-is-loading' : ''}>
      <div id={id}>
        <PanelWithActions
          permission={{
            category: HierarchicalEntityCategory.Dashboards,
            action: Action.Update,
          }}
          usersStore={usersStore}
          handleCSVDownload={type === 'cue' || type === 'k8s' ? loadCSVData : undefined}
          handleRefresh={loadData}
          title={activePanel.name}
          panelAccId={activePanel.accountId}
          panelAccName={(activePanel.panelAccountParams || {}).accountName}
          actionListParams={getActionListParams()}
          showRefreshButton={panelIsEmpty(panelData, chartType) && !isLoading}
          isShowAdditionalAction={rest.type !== 'kpis'}
          isShowActions={rest.type !== 'kpis' && currentUserOwner}
          additionalActionButtons={[{ func: renderMagnifierGlassComponent, props: [type, chartType, rest] }]}
        >
          {isLoading ? <Spinner /> : renderChartOrTable()}
        </PanelWithActions>
      </div>
      {isEditPanelOpen && (
        <EditCustomDashboardPanelModal
          customDashboardStore={customDashboardStore}
          modalIsOpen={isEditPanelOpen}
          onCloseEditPanelModal={handleEditPanelClicked}
          handleEditPanel={handleEditPanel}
          selectedPanelName={rest.name}
          selectedPanelGranularity={(rest.routeParams || {}).periodGranLevel}
          selectedPanelPeriod={selectedPanelPeriod}
          selectedPanelNumberOfItems={rest.numberOfItems}
          isDefaultOthers={(rest.state || {}).isShowOthers}
        />
      )}
    </Col>
  );
};

CustomDashboardActivePanel.propTypes = {
  currentUserOwner: PropTypes.bool,
  customDashboardStore: PropTypes.object.isRequired,
  dashboardId: PropTypes.string.isRequired,
  dbAccountId: PropTypes.string,
  fromTemplate: PropTypes.bool,
  handlePanelLoading: PropTypes.func.isRequired,
  handleRemovePanelClicked: PropTypes.func.isRequired,
  hasDashboardFilters: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isPpApplied: PropTypes.bool,
  numStrAbriviaionByDisplayMetric: PropTypes.object.isRequired,
  overrideCurrency: PropTypes.string,
  panelId: PropTypes.string.isRequired,
  panelSettings: PropTypes.object,
  panelsForceReload: PropTypes.bool,
  filters: PropTypes.object
};

CustomDashboardActivePanel.defaultProps = {
  currentUserOwner: true,
  fromTemplate: false,
  dbAccountId: null,
  panelsForceReload: false,
  isPpApplied: false,
  hasDashboardFilters: false,
  panelSettings: {},
  overrideCurrency: null,
};

const ObserverCustomDashboardActivePanel = withUserSettingsConsumer(CustomDashboardActivePanel);
export default ObserverCustomDashboardActivePanel;
