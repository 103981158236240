import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { useSpAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import styles from './recommPlanDetails.module.scss';
import RecommendedPlanCube from './RecommendedPlanCube';
import {PLAN_KEYS_SORT_ORDER} from "commitment/containers/spAnalyzerNew/utils/consts.js";
import CustomCSVDownload from "shared/components/buttons/CustomCSVDownload.jsx";
import _ from "lodash";

const RecommendedPlanDetails = ({ selectedPlan, setSelectedPlan, shareProperties }) => {
  const { preferences, recommendationPlanData: recPlanData, planDataByKey, coverage, daysToCheck } = useSpAnalyzerContext();
  const recPerPlan =  recPlanData?.recommendationPerPlan || {};
  const preferencePlanKey = useMemo(() => {
      if(preferences?.coveragePercentage * 100 !== coverage || preferences?.daysToCheck !== daysToCheck) {
          return null;
      }
      return Object.keys(recPerPlan).find(
          (key) =>
              recPerPlan[key]?.term.toLowerCase() === preferences?.term.toLowerCase() &&
              recPerPlan[key]?.paymentOption.toLowerCase() === preferences?.paymentOption.toLowerCase(),
      );
  }, [recPerPlan, preferences, coverage, daysToCheck]);

  const fetchDataForExport = () => {
    let exportData;
    exportData = recPlanData.recommendationPerPlan;
    const exportColumns = [
      { key: 'key' , label: 'Plan' },
      { key: 'totalSaving' , label: 'Estimated Savings' },
      { key: 'hourlyCommitment' , label: 'Hourly Commitment' },
      { key: 'esr' , label: 'ESR' }
    ];
    // prepare data for export
    const dataToExport = Object.keys(exportData || {}).sort((a, b) => PLAN_KEYS_SORT_ORDER.indexOf(a) - PLAN_KEYS_SORT_ORDER.indexOf(b))
      .map((plan) => {
        return {
          key: _.toUpper(_.startCase(plan)),
          hourlyCommitment: exportData[plan]?.recommendedHourlyCommitment,
          totalSaving: exportData[plan] ? Number(recPlanData.simulatedCurrentMonthlyNetCost - exportData[plan]?.recommendedMonthlyNetCost).toFixed(2) : '',
          esr: exportData[plan]?.potentialSavings,
        };
      });
      return [
        {
          data: dataToExport || [],
          filename: 'sp-analyzer-recommendations-plans.csv',
          headers: [...exportColumns],
        },
      ];
  }

  useEffect(() => {
      if(!preferencePlanKey && recPerPlan) {
          const summedCosts = Object.keys(recPerPlan).map(key => {
              const totalCost = recPerPlan[key] ? shareProperties.simulatedCurrentMonthlyNetCost - recPerPlan[key]?.recommendedMonthlyNetCost : null;
              return { key, totalCost };
          }).filter((item) => item.totalCost)

          const maxTotalCost = summedCosts.reduce((max, item) => item.totalCost > max.totalCost ? item : max, summedCosts[0]);
          setSelectedPlan(maxTotalCost.key);
          return;
      }
    setSelectedPlan(preferencePlanKey);
  }, [preferencePlanKey, recPerPlan]);

  return (
    <div className={styles.detailsContainer}>
        <div className={styles.downloadWrapper}>
            <CustomCSVDownload
                fetchData={fetchDataForExport}
                automationId="export-sp"
                showDownloadIcon
                isLoading={false}
                filesNumber={1}
                hideText
                style={{
                    height: 36,
                    color: '#3D4C59'
                }}
            />
        </div>
      <div className={styles.detailsWrapper}>
        {Object.keys(recPerPlan || {}).sort((a, b) => PLAN_KEYS_SORT_ORDER.indexOf(a) - PLAN_KEYS_SORT_ORDER.indexOf(b))
          .map((key) => (
            <RecommendedPlanCube
              key={key}
              plan={planDataByKey?.[key] || recPerPlan[key]}
              planKey={key}
              preferencePlanKey={preferencePlanKey}
              shareProperties={shareProperties}
              selectedKey={selectedPlan}
              setSelectedPlan={(key) => {
                if (recPerPlan[key]) {
                  setSelectedPlan(key);
                }
              }}
              automationId={`${key}-plan-cube`}
              hasInstanceSp={recPlanData.hasInstanceSp === 'True'}
              hasMultiLacsCommitments={recPlanData.hasMultiLacsCommitments === 'True'}
            />
          ))}
      </div>
      <div className={styles.centerContainer}>
        <GenerateIcon iconName={ICONS.caretDown.name} className={styles.arrowIcon} />
      </div>
    </div>
  );
};

RecommendedPlanDetails.propTypes = {
    selectedPlan: PropTypes.string,
    setSelectedPlan: PropTypes.func.isRequired,
    shareProperties: PropTypes.object.isRequired,
};
export default RecommendedPlanDetails;
