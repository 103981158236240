import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  IntegratedSelection,
  SelectionState,
  SortingState, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { palette } from 'shared/constants/colorsConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import TableWrapper from 'shared/components/tables/TableWrapper.jsx';
import React, { useEffect, useMemo, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext.jsx';
import TableHeader from 'shared/components/tableHeader/TableHeader.jsx';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { formatDateToLabel, formatDateToMonthLabel } from 'shared/utils/dateUtil.js';
import useRebilling from 'divisions/hooks/react-query/useRebilling.js';
import RebillingPopover from '../RebillingPopover/RebillingPopover.jsx';
import styles from './TriggerRebilling.module.scss';
import Filters from './Filters.jsx';

const columns = [
  {
    name: 'customerName',
    title: 'Customer',
  },
  {
    name: 'trigger',
    title: 'Add Trigger Rebilling',
  },
  {
    name: 'lastRebilling',
    title: 'Last Rebilling',
    align: 'center',
  },
  {
    name: 'processMonth',
    title: 'Process Month',
  },
  {
    name: 'status',
    title: 'Status',
  },
  {
    name: 'triggerType',
    title: 'Trigger Type',
  },
];

const TriggerRebilling = ({ customers }) => {
  const { NewTableWrapper, NewTableSelectionBar } = useTable();
  const { usersStore } = useRootStore();
  const [filters, setFilters] = useState({
    months: [],
    statuses: [],
    customers: [],
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [rebillingData, setRebillingData] = useState([]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'customerName', width: 300 },
    { columnName: 'trigger', width: 200 },
    { columnName: 'lastRebilling', width: 100 },
    { columnName: 'processMonth', width: 100 },
    { columnName: 'status', width: 200 },
    { columnName: 'triggerType', width: 'auto' },
  ]);
  const { useFetchRebillingHistory } = useRebilling();
  const { data, isLoading } = useFetchRebillingHistory();
  const rebillingDataFiltered = useMemo(() => {
    return rebillingData.filter(row => {
      return !filters.customers.length || filters.customers.some(c => c.value === row.customerId);
    });
  }, [filters, rebillingData]);

  useEffect(() => {
    if (data && customers) {
      const months = Array.from(new Set(data.allCustomers.map(ac => ac.month)));
      const rows = customers.map(c => ({
        customerName: c.name,
        customerId: c.id,
        history: months.reduce((acc, month, index) => {
          const manualRebilling = (data.statusPerCustomerAndMonth[c.name] || data.statusPerCustomerAndMonth[c.id])?.[month];
          if (manualRebilling) {
            manualRebilling.type = 'Manual';
          }
          let automaticRebilling = data.allCustomers.find(ac => ac.month === month);
          if (moment(automaticRebilling.date) < moment(c.createdDate) || automaticRebilling.status === 'none') {
            automaticRebilling = null;
          }
          if (automaticRebilling) {
            automaticRebilling.type = 'Automatic';
          }
          let rebilling;
          if (!manualRebilling) {
            rebilling = automaticRebilling;
          } else {
            rebilling = (moment(manualRebilling.date) > moment(automaticRebilling?.date) || !automaticRebilling) ? manualRebilling : automaticRebilling;
          }
          if (!rebilling || rebilling.status === 'none') {
            return acc;
          }
          return {
            ...acc,
            [month]: { ...rebilling, key: index },
          };
        }, {}),
      }));
      setRebillingData(rows);
    }
  }, [data, customers]);
  const renderCell = (row, renderContent) => {
    return (
      <div className={styles.subCellList}>
        {Object.keys(row.history).filter(key => {
          const record = row.history[key];
          return (!filters.months.length || filters.months.some(m => m.value === record.month))
            && (!filters.statuses.length || filters.statuses.some(s => s.value === record.status));
        }).map((key) => {
          const record = row.history[key];
          if (!record) {
            return null;
          }
          return (
            <div
              className={styles.subCell}
              key={record.key}
            >{renderContent(record)}</div>
          );
        })}
      </div>
    );
  };
  const formatTrigger = ({ row }) => {
    if (usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.AWS) {
      return;
    }
    return (
      <div className={styles.trigger}>
        <RebillingPopover
          setSelectedRows={() => {
          }}
          selectedRows={[rebillingDataFiltered.findIndex(rd => rd.customerId === row.customerId)]}
          customers={rebillingDataFiltered}
        />
      </div>
    );
  };
  const formatStatus = ({ row }) => {
    return renderCell(row, record => (
      <div>
        {record?.status === 'pending' ? (
          <span className={styles.pending}><span><Spinner
            transform="none"
            color={palette.gray[400]}
            size={12}
            position="static"
          /></span> <span className={styles.text}>Rebilling In Process</span></span>
        ) : record?.status === 'error' ? (
          <span className={styles.fail}>
              <GenerateIcon iconName={ICONS.xMark.name} /> Rebilling Failed
            </span>
        ) : (
          <span className={styles.success}>
              <GenerateIcon iconName={ICONS.check.name} /> Rebilling Succeeded
            </span>
        )}
      </div>
    ));
  };
  const formatTriggerType = ({ row }) => {
    return renderCell(row, record => <div className={styles.type}>{record.type}</div>);
  };
  const formatMonth = ({ row }) => {
    return renderCell(row, record => formatDateToMonthLabel(record.month));
  };
  const formatLastDate = ({ row }) => {
    return renderCell(row, record => formatDateToLabel(record.date));
  };
  return (
    <div className={styles.container}>
      <TableHeaderProvider>
        <TableHeader
          fetchCsvData={() => {
            return [{
              data: rebillingDataFiltered.flatMap(c => Object.values(c.history).map(h => ({
                customer_id: c.customerId,
                customer_name: c.customerName,
                process_month: h.month,
                status: h.status,
                trigger_type: h.type,
                last_rebilling_date: h.date,
              }))), filename: `triggered_rebilling.csv`,
            }];
          }}
          isCreatable={false}
          tableName="Triggered Rebilling"
          hideSearch
          filtersPosition="right"
          filters={<Filters
            filters={filters}
            setFilters={setFilters}
            customers={customers}
          />}
        />
      </TableHeaderProvider>
      {isLoading ? <Spinner /> : (
        <NewTableWrapper
          isCompact
          isLoading={isLoading}
        >
          <Grid
            rows={rebillingDataFiltered}
            columns={columns}
            columnExtensions={[
              { columnName: 'lastRebilling', width: '50px' },
            ]}
          >
            <SortingState
              defaultSorting={[{ columnName: 'customerName', direction: 'asc' }]}
              columnExtensions={[
                { columnName: 'trigger', sortingEnabled: false },
                { columnName: 'lastRebilling', sortingEnabled: false },
                { columnName: 'processMonth', sortingEnabled: false },
                { columnName: 'status', sortingEnabled: false },
                { columnName: 'triggerType', sortingEnabled: false },
              ]}
            />
            <IntegratedSorting />
            <DataTypeProvider
              for={['customerName']}
              formatterComponent={({ row }) => <div className={styles.customerName}>{row.customerName}</div>}
            />
            <DataTypeProvider
              for={['trigger']}
              formatterComponent={formatTrigger}
            />
            <DataTypeProvider
              for={['status']}
              formatterComponent={formatStatus}
            />
            <DataTypeProvider
              for={['triggerType']}
              formatterComponent={formatTriggerType}
            />
            <DataTypeProvider
              for={['processMonth']}
              formatterComponent={formatMonth}
            />
            <DataTypeProvider
              for={['lastRebilling']}
              formatterComponent={formatLastDate}
            />
            <SelectionState
              selection={selectedRows}
              onSelectionChange={(data) => setSelectedRows(data)}
            />
            <IntegratedSelection />
            <TableWrapper />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
              resizingMode="nextColumn"
            />
            <TableHeaderRow showSortingControls />
            {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && (
                <>
                  <TableSelection showSelectAll />
                </>
              )}
            <NewTableSelectionBar selectedRows={selectedRows}>
              <div>
                <RebillingPopover
                  setSelectedRows={setSelectedRows}
                  selectedRows={selectedRows}
                  customers={rebillingDataFiltered}
                />
              </div>
            </NewTableSelectionBar>
          </Grid>

        </NewTableWrapper>
      )}
    </div>
  );
};

TriggerRebilling.propTypes = {
  customers: PropTypes.array.isRequired,
};

export default TriggerRebilling;
