import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts.js';
import { getInitialAccountsDataWithCounters } from 'users/containers/Organization/components/LinkedAccounts/dataAccessHelperFunctions.js';
import Spinner from 'shared/components/andtComponents/Spinner.jsx';
import LinkedAccountsComponentWrapper from 'users/containers/Organization/components/LinkedAccounts/LinkedAccountsComponentWrapper.jsx';
import {
  COST_CENTER_LINKED_ACCOUNTS_COLUMNS,
  COST_CENTER_PAYER_ACCOUNTS_COLUMNS,
} from 'users/containers/Organization/consts.js';
import { getCostCenterAccountsData } from 'users/containers/Organization/CostCenters/costCentersHelperFunctions.js';

import styles from './CostCenterAccountsData.module.scss';

const CostCenterAccountsData = ({ row }) => {
  const { fetchCostCenterAccountsData } = useDataAccessAccounts();
  const { data: costCenterAccountsData, isLoading: isCostCenterAccountsDataLoading } = fetchCostCenterAccountsData(
    row?.id,
  );

  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();

  const dataToDisplay = useMemo(() => {
    if (!accountsData || !costCenterAccountsData) {
      return null;
    }
    const combinedData = getInitialAccountsDataWithCounters(accountsData);
    const combinedCostCenterAccountsData = getCostCenterAccountsData(accountsData, costCenterAccountsData, false);

    return { ...combinedData, accountsData: combinedCostCenterAccountsData };
  }, [accountsData, costCenterAccountsData]);

  return (
    <div className={styles.costCenterAccountsDataContainer}>
      {isAccountsDataLoading || isCostCenterAccountsDataLoading ? (
        <div className="position-relative">
          <Spinner />
        </div>
      ) : (
        <div className={styles.costCenterAccountsData}>
          <LinkedAccountsComponentWrapper
            entity="costCenter"
            allAccountsData={dataToDisplay}
            onRemoveLinkedAccount={() => {}}
            linkedAccountsColumns={Object.values(COST_CENTER_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
            payerAccountsColumns={Object.values(COST_CENTER_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
            additionalData={{ roleId: row?.id }}
          />
        </div>
      )}
    </div>
  );
};

CostCenterAccountsData.propTypes = {
  row: PropTypes.object,
};

export default CostCenterAccountsData;
