import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Checkbox from 'shared/components/andtComponents/Checkbox.jsx';

const RowContext = React.createContext(null);

export const RowContextProvider = ({ row, children }) => {
  return <RowContext.Provider value={row}>{children}</RowContext.Provider>;
};

RowContextProvider.propTypes = {
  row: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export const CustomTreeCheckboxCell = (props) => {
  const row = useContext(RowContext);

  const onClick = (e) => {
    e.stopPropagation();
  };

  const onChange = (selected) => {
    props.onToggle({ costCenterId: row.id, newSelected: selected });
  };

  return (
    <div onClick={onClick}>
      <Checkbox
        isChecked={row.isAllowed || row.effectiveIsAllowed}
        isDisabled={row.effectiveIsAllowed || (row.isAllowed && !row.isDirectlyAssigned)}
        onChange={onChange}
        noPaddings
        primary
      />
    </div>
  );
};

CustomTreeCheckboxCell.propTypes = {
  selected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hasChildren: PropTypes.bool,
  }),
};
