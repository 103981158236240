import React, { useEffect, useMemo, useState } from 'react';
import { useSpAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import Button from 'shared/components/andtComponents/Button';
import {
  ACTUAL_CHART,
  ACTUAL_CHART_LEGEND,
  ACTUAL_TP_MAP_DATA, CURRENT_PROPERTIES, ON_DEMAND_CHART,
  ON_DEMAND_CHART_LEGEND,
  ON_DEMAND_TP_MAP_DATA, SIMULATION_PROPERTIES,
} from 'commitment/containers/spAnalyzerNew/utils/consts';
import DiscountTable from './DiscountTable';
import RecommendedPlanDetails from './RecommendedPlanDetails';
import styles from './recommendedPlanData.module.scss';
import RecommendedChart from './recommendedCharts/RecommendedChart';
import {GenerateIcon, ICONS} from "@pileus-cloud/anodot-frontend-common";
import SelectedProperties from "commitment/containers/spAnalyzerNew/components/SelectedProperties.jsx";

const RecommendedPlanData = () => {
  const { recommendationPlanData: recPlanData, planDataByKey } = useSpAnalyzerContext();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [recPerPlanData, setRecPerPlanData] = useState();
  const [isSimulateData, setIsSimulateData] = useState(true);

  useEffect(() => {
    if (selectedPlan) {
      setRecPerPlanData(planDataByKey?.[selectedPlan] || recPlanData?.recommendationPerPlan?.[selectedPlan]);
    }
  }, [planDataByKey, recPlanData, selectedPlan]);

  const onDemandChartData = useMemo(() => {
    if (!selectedPlan || !recPerPlanData) {
      return null;
    }
    const currentSpChartDaily = recPlanData?.currentSpCoverage?.daily;
    const onDemandChartDaily = recPlanData?.grossCosts?.daily;
    const recommendedSpChartDaily = recPerPlanData?.recommendedSpCoverage?.daily;
    const simulateSpChartDaily = recPlanData?.simulatedCurrentSpCoverage?.daily || {};

    const currentSpChartHourly = recPlanData?.currentSpCoverage?.hourly;
    const onDemandCharHourly = recPlanData?.grossCosts?.hourly;
    const recommendedSpChartHourly = recPerPlanData?.recommendedSpCoverage?.hourly;
    const simulateSpChartHourly = recPlanData?.simulatedCurrentSpCoverage?.hourly || {};


    const dailyData = Object.entries(onDemandChartDaily || {}).map(([date, onDemand], index) => ({
      date,
      onDemand,
      key: `Day ${index+1}`,
      currentSP: isSimulateData ? simulateSpChartDaily?.[date] : currentSpChartDaily?.[date],
      recommendedSP: recommendedSpChartDaily[date],
    }));
    let dayIndex = 0;
    let previousDate = '';

    const HourlyData = Object.entries(onDemandCharHourly || {}).map(([date, onDemand]) => {
      const currentDate = date.split(' ')[0];
      if (currentDate !== previousDate) {
        previousDate = currentDate;
        dayIndex++;
      }
      return ({
        date,
        key: `Day ${dayIndex} - ${date.split(' ')[1]}`,
        onDemand,
        currentSP: isSimulateData ? simulateSpChartHourly[date] : currentSpChartHourly[date],
        recommendedSP: recommendedSpChartHourly[date],
      })
    });

    return {
      currentData: {
        daily: dailyData,
        hourly: HourlyData,
      },
      simulateData: {
        daily: dailyData.map((d) => (
            { date: d.key, onDemand: d.onDemand, currentSP: d.currentSP, recommendedSP: d.recommendedSP }
        )),// change the recommendedSP with new simulate data
        hourly: HourlyData.map((d) => ({ date: d.key, onDemand: d.onDemand, currentSP: d.currentSP, recommendedSP: d.recommendedSP })),
      }

    };
  }, [recPlanData, recPerPlanData, selectedPlan, isSimulateData]);

  const actualChartData = useMemo(() => {
    if (!selectedPlan || !recPerPlanData) {
      return null;
    }
    const hourlyCommitmentDaily = recPlanData?.currentCommitments?.daily;
    const netCostDataDaily = recPlanData?.currentNetCosts?.daily;
    const hourlyCommitmentHourly = recPlanData?.currentCommitments?.hourly;
    const netCostDataHourly = recPlanData?.currentNetCosts?.hourly;

    const simulateHourlyCommitmentDaily = recPlanData?.simulatedCurrentCommitments?.daily;
    const simulateNetCostDataDaily = recPlanData?.simulatedCurrentNetCosts?.daily;
    const simulateHourlyCommitmentHourly = recPlanData?.simulatedCurrentCommitments?.hourly;
    const simulateNetCostDataHourly = recPlanData?.simulatedCurrentNetCosts?.hourly;

    const recommHourlyCommitmentDaily = recPerPlanData?.recommendedCommitments?.daily;
    const recommNetCostDaily = recPerPlanData?.recommendedNetCosts?.daily;
    const recommHourlyCommitmentHourly = recPerPlanData?.recommendedCommitments?.hourly;
    const recommNetCostHourly = recPerPlanData?.recommendedNetCosts?.hourly;

    const unUsedCommitmentDaily = recPerPlanData?.unusedCommitments?.daily;
    const unUsedCommitmentHourly = recPerPlanData?.unusedCommitments?.hourly;

    const dailyData = Object.entries((isSimulateData ? simulateHourlyCommitmentDaily : hourlyCommitmentDaily )|| {}).map(([date, hourlyCommitment], index) => ({
      date,
      hourlyCommitment,
      key: `Day ${index+1}`,
      actualOnDemand: (isSimulateData ? simulateNetCostDataDaily[date] : netCostDataDaily[date]) - hourlyCommitment,
      netCost: isSimulateData ? simulateNetCostDataDaily[date] : netCostDataDaily[date],
      accHourlyCommitment: recommHourlyCommitmentDaily[date],
      actualAccOnDemand: recommNetCostDaily[date] - recommHourlyCommitmentDaily[date],
      accNetCost: recommNetCostDaily[date],
      unUsedCommitment: unUsedCommitmentDaily?.[date],
    }));
    let dayIndex = 0;
    let previousDate = '';
    const hourlyData = Object.entries((isSimulateData ? simulateHourlyCommitmentHourly : hourlyCommitmentHourly) || {})
          .map(([date, hourlyCommitment]) => {
            const currentDate = date.split(' ')[0];
            if (currentDate !== previousDate) {
              previousDate = currentDate;
              dayIndex++;
            }
            return({
              date,
              hourlyCommitment,
              key: `Day ${dayIndex} - ${date.split(' ')[1]}`,
              actualOnDemand: (isSimulateData ? simulateNetCostDataHourly[date] : netCostDataHourly[date]) - hourlyCommitment,
              netCost: isSimulateData ? simulateNetCostDataHourly[date] : netCostDataHourly[date],
              accHourlyCommitment: recommHourlyCommitmentHourly[date],
              accNetCost: recommNetCostHourly[date],
              actualAccOnDemand: recommNetCostHourly[date] - recommHourlyCommitmentHourly[date],
              recommendedOnDemandValue: (recommNetCostHourly[date] - recommHourlyCommitmentHourly[date]) > 0
                  ? recommNetCostHourly[date] : recommHourlyCommitmentHourly[date] - unUsedCommitmentHourly?.[date],
              unUsedCommitment: unUsedCommitmentHourly?.[date],
            })
          });

    return {
      currentData: {
        daily: dailyData,
        hourly: hourlyData,
      },
      simulateData: {
        daily: dailyData.map((d) => ({
          ...d,
          date: d.key,
        })),
        hourly: hourlyData.map((d) => ({
          ...d,
          date: d.key,
        })),
      }
    }
  }, [recPerPlanData, recPlanData, isSimulateData, selectedPlan]);

  const shareProperties = {
    type: recPlanData?.savingsPlanType,
    currentMonthlyOdCost: recPlanData?.currentMonthlyOdCost,
    currentMonthlyNetCost: recPlanData?.currentMonthlyNetCost,
    simulatedCurrentMonthlyNetCost: recPlanData?.simulatedCurrentMonthlyNetCost,
    simulatedCurrentMonthlyOdCost: recPlanData?.simulatedCurrentMonthlyOdCost,
  };

  return (
    <div className={styles.planDataWrapper}>
      <RecommendedPlanDetails selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} shareProperties={shareProperties} />
      {recPlanData && recPerPlanData && (
          <>
            <div className={styles.swaggerWrapper}>
              <Button isSecondary={!isSimulateData}
                      overrideStyles={isSimulateData && {height: '24px', borderRadius: '4px'}} text="Simulation"
                      onClick={() => setIsSimulateData(true)}
                      icon={() => <GenerateIcon iconName={ICONS.chartBar.name}/>}/>
              <Button isSecondary={isSimulateData}
                      overrideStyles={!isSimulateData && {height: '24px', borderRadius: '4px'}} text="Current"
                      onClick={() => setIsSimulateData(false)}
                      icon={() => <GenerateIcon iconName={ICONS.presentationScreen.name}/>}/>
            </div>
            <div>
              <SelectedProperties
                  selectedProperties={isSimulateData ? SIMULATION_PROPERTIES : CURRENT_PROPERTIES}
                  shareProperties={shareProperties}
                  plan={recPlanData?.recommendationPerPlan?.[selectedPlan] || {}} />
            </div>
          </>
      )}
      <div className={styles.container} automation-id="onDemandChart">
        {recPlanData && recPerPlanData && (
          <RecommendedChart
            legend={isSimulateData ? ON_DEMAND_CHART_LEGEND.simulate : ON_DEMAND_CHART_LEGEND.current}
            data={isSimulateData ? onDemandChartData.simulateData : onDemandChartData.currentData}
            isAnimationActive
            info={ON_DEMAND_CHART[isSimulateData ? 'simulate': 'current'].info}
            header={ON_DEMAND_CHART[isSimulateData ? 'simulate': 'current'].header}
            tooltipMapData={isSimulateData ? ON_DEMAND_TP_MAP_DATA.simulate : ON_DEMAND_TP_MAP_DATA.current}
            automationId="onDemandChart"
            chartType="monotone"
            isCurrentTab={!isSimulateData}
            expirationEvents={!isSimulateData ? recPlanData.expirationEvents : {}}
            purchaseEvents={!isSimulateData ? recPlanData.purchaseEvents : {}}
          />
        )}
      </div>
      <div className={styles.container} automation-id="actualChart">
        {recPlanData && recPerPlanData && (
          <RecommendedChart
            legend={isSimulateData ? ACTUAL_CHART_LEGEND.simulate : ACTUAL_CHART_LEGEND.current}
            data={isSimulateData ? actualChartData.simulateData : actualChartData.currentData}
            includeEstimatedOption={isSimulateData}
            isCurrentTab={!isSimulateData}
            info={ACTUAL_CHART[isSimulateData ? 'simulate': 'current'].info}
            header={ACTUAL_CHART[isSimulateData ? 'simulate': 'current'].header}
            tooltipMapData={isSimulateData ? ACTUAL_TP_MAP_DATA.simulate : ACTUAL_TP_MAP_DATA.current}
            automationId="actualChart"
            chartType="monotone"
            isAnimationActive={false}
            isCurrentData={!isSimulateData}
            expirationEvents={!isSimulateData ? recPlanData.expirationEvents : {}}
            purchaseEvents={!isSimulateData ? recPlanData.purchaseEvents : {}}
          />
        )}
      </div>
      <div className={styles.container}>
        {selectedPlan && !isSimulateData && <DiscountTable discountData={recPlanData?.discountTables?.[selectedPlan] || []} />}
      </div>
    </div>
  );
};

export default RecommendedPlanData;
