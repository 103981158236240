import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { useSpAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import AnalyzerFilters from './AnalyzerFilters';
import RecommendedPlanData from './RecommendedPlanData';
import SpSpecialMode from './SpSpecialMode';
import styles from './recommendedPlanData.module.scss';
import { SpLoader } from './SpLoader';
const RecommendedPlan = () => {
  const { appStore, usersStore } = useRootStore();
  const [isShowLoader, setIsShowLoader] = useState(false);
  const {
    setHourlyCommitment,
    recommendationPlanData,
    isLoading,
    refetch,
    isFetching,
    setPlanDataByKey,
    setModifiedPlanKeyHourlyCommitment,
  } = useSpAnalyzerContext();
  const isPlanDataEmpty = () => {
    if (!recommendationPlanData || Object.keys(recommendationPlanData).length === 0) {
      return true;
    }
    return !!(recommendationPlanData.recommendationPerPlan && Object.values(recommendationPlanData.recommendationPerPlan).every((value) => value === null));
  };
  const componentToRender = () => {
    if(usersStore.isCurrentUserReseller && appStore.isCustomerPricingMode) {
      return (
          <SpSpecialMode
              isMsp
              specialText={
                <>
                  <span className={styles.mspTextBold}>Savings Plan Simulator is not available in Customer Pricing mode.</span>
                  <span className={styles.mspText}>For accurate results, switch to Partner Pricing mode or select a specific customer scope.</span>
                </>
              }
              isLongText
              type="mspState"
          />
      );
    }
    if (isLoading || isFetching || isShowLoader) {
      return (
        <div className={styles.loaderWrapper}>
          <SpLoader dataReceived={!isFetching && !isLoading} setIsLoading={setIsShowLoader} />
        </div>
      );
    }
    if(!recommendationPlanData){
        return null;
    }
    if (isPlanDataEmpty()) {
      return (
        <SpSpecialMode
          isEmpty
          specialText={
            <>
              <span>Based on the selected filters, your expenses are already well-covered by savings plans.</span>
              <span>Therefore, we don’t have any new plans to recommend.</span>
            </>
          }
          isLongText
          type="emptyState"
        />
      );
    }
    return <RecommendedPlanData />;
  };
  return (
    <div>
      <AnalyzerFilters
        analyze={() => {
          setHourlyCommitment(null);
          setPlanDataByKey({});
          setModifiedPlanKeyHourlyCommitment({});
          refetch();
        }}
        disabled={isLoading || isFetching || (usersStore.isCurrentUserReseller && appStore.isCustomerPricingMode)}
      />
      {componentToRender()}
    </div>
  );
};

export default observer(RecommendedPlan);
