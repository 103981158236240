import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import {
  transformFiltersContextToApiFormat,
  transformSelectDataToClientFormat,
  transformSingleOptionToApiFormat,
} from 'recommendationsNew/hooks/react-query/apiUtils';
import {
  BILLINGS_API_NAME,
  RECOMMENDATIONS_HEATMAP_ROOT,
  SORT_BY_OPTIONS,
  STALE_TIME,
} from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';

export async function fetchHeatMapGroup({ groupBy, filters, sortBy, lastItemOfPrevPage, externalFilters, pageSize = 50 }) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const transformedExternalFilters = transformFiltersContextToApiFormat(externalFilters);
  const data = await API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/group`, {
    body: {
      group_by: groupBy,
      filters: transformedFilters || {},
      sort_by: sortBy || SORT_BY_OPTIONS.items.SAVINGS.property,
      tag_name: null,
      pagination_token: lastItemOfPrevPage ? transformSingleOptionToApiFormat(lastItemOfPrevPage) : null,
      second_level_filters: transformedExternalFilters || {},
      page_size: pageSize,
    },
  });
  // Transform data to match the format of react-select
  const pageData = transformSelectDataToClientFormat(data?.page);
  return data?.page ? { ...data, page: pageData } : data;
}

export default function useGroupByOption(isEnabled) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_GROUP_BY_OPTION,
    userAccountKey,
  ];

  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries(
        queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.sortBy]
          : queryKey,
      ),
    reset: (queryKeyToInvalidate) =>
      queryClient.resetQueries(
        queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.sortBy]
          : queryKey,
      ),
    fetchGroupByOption: ({ groupBy, filters, sortBy, lastItemOfPrevPage, pageSize, externalFilters }) =>
      useQuery(
        [...queryKey, groupBy, filters, sortBy, lastItemOfPrevPage, pageSize, externalFilters],
        () => fetchHeatMapGroup({ groupBy, filters, sortBy, lastItemOfPrevPage, externalFilters, pageSize }),
        {
          retry: false,
          staleTime: STALE_TIME,
          enabled: isEnabled,
          keepPreviousData: true,
        },
      ),
  };
}
