import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { PAGINATION_DIRECTION } from '../../../consts';
import AddRoles from '../../../components/AddRoles/AddRoles';
import RolesNoData from 'users/containers/Organization/components/EmptyStates/RolesNoData.jsx';

import styles from './AddUsersModal.module.scss';

const AddRolesStep = ({
  rolesToAssign,
  setRolesToAssign,
  isShowingResumeEditing,
  validationErrors,
  setValidationErrors,
  saveClicked,
}) => {
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);

  const { fetchRoles } = useRoles();
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  const handleRolesAddedRemoved = ({ addedRoles, removedRoles }) => {
    if (validationErrors?.roles?.length > 0) {
      setValidationErrors(undefined);
    }
    setRolesToAssign((prev) => {
      const newSelectedRoles = prev.filter((role) => !removedRoles.find((r) => r.id === role.id));
      return Array.from(new Map([...newSelectedRoles, ...addedRoles].map((item) => [item.id, item])).values());
    });
  };

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  return isShowingResumeEditing ? (
    <div className={styles.noRolesAssignedContainer}>
      <RolesNoData
        isSmall={true}
        mainText={'You have not assigned any role to these users.'}
        subText={'Anodot’s default role will be assigned to them. You can update their roles later.'}
      />
    </div>
  ) : (
    <AddRoles
      isLoading={isLoading}
      isDisabled={saveClicked}
      onRolesAddedRemoved={handleRolesAddedRemoved}
      pageChanged={pageChanged}
      roles={data?.roles}
      search={search}
      setSearch={setSearch}
      selectedRoles={rolesToAssign}
    />
  );
};

AddRolesStep.propTypes = {
  isShowingResumeEditing: PropTypes.bool,
  rolesToAssign: PropTypes.array,
  saveClicked: PropTypes.bool,
  setRolesToAssign: PropTypes.func.isRequired,
  setValidationErrors: PropTypes.func.isRequired,
  validationErrors: PropTypes.object,
};

export default AddRolesStep;
