import { Container } from 'reactstrap';
import React from 'react';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import PageHeader from 'shared/components/PageHeader';
import { Routes } from 'shared/constants/routes';
import { PageNames } from 'shared/constants/appConstants';
import { SP_TABS } from 'commitment/containers/spAnalyzerNew/utils/consts';
import RecommendedPlan from './components/RecommendedPlan';
import { SpAnalyzerProvider } from './contexts/spAnalyzerContext';
import SpSpecialMode from './components/SpSpecialMode';
import InfoPopover from "shared/components/andtComponents/InfoPopover/index.jsx";
import styles from './spAnalyzer.module.scss';
const SpAnalyzerNew = () => (
  <Container>
    <PageHeader showDate title={PageNames.SAVINGS_PLANS_ANALYZER} hasInfo>
      <div className={styles.infoIcon}>
          <InfoPopover isSimple mode="outline" >
              <ul className={styles.list}>
                  <li>Relevant plans: Compute Savings plan</li>
                  <li>The simulation is based on all the computing costs: EC2, Fargate, and Lambda (for reseller customers based on EC2 only).</li>
                  <li>The recommendations use simulated data based on &quot;days-to-check&quot; and current commitments.</li>
                  <li>The default values are set based on your preferences from the
                      <a className={styles.link} href={`${Routes.RECOMMENDATION_PREFERENCES}`} target="_blank">Recommendations Preferences page</a>
                  </li>
              </ul>
          </InfoPopover>
      </div>
    </PageHeader>
    <div>
        <SpAnalyzerProvider>
            <ColoredTabs arrOfNavItems={SP_TABS}
                         defaultAccountIndex={SP_TABS[0].id}
                         automation-id="sp-tabs"
                         staticInfo={
                           <a className={styles.link}
                              rel="noopener noreferrer"
                              target="_blank"
                              href={Routes.SAVINGS_PLANS_UTILIZATION}
                              automation-id="preferncesLink">
                             <span>Explore existing Saving Plans</span>
                           </a>}>
                <RecommendedPlan/>
                <SpSpecialMode isComingSoon specialText="Coming Soon!" type="comingSoon"/>
            </ColoredTabs>
        </SpAnalyzerProvider>
    </div>
  </Container>
);

export default SpAnalyzerNew;
