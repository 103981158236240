import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { RolesContext } from './contexts/rolesContext';
import RolesHeader from './RolesHeader';
import RolesTable from './RolesTable';
import { GET_USERS_QUERY_PARAMS, PAGINATION_DIRECTION } from '../consts';

import styles from './Roles.module.scss';

const Roles = () => {
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const { NewTableWrapper } = useTable();

  const { fetchRoles } = useRoles();
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_USERS_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setSearch(searchParam);
    }
  }, []);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  return (
    <div className={styles.roles}>
      <RolesContext.Provider
        value={{
          isAllExpanded,
          paginationToken,
          search,
          selectedRows,
          setIsAllExpanded,
          setSearch,
          setSelectedRows,
          setPaginationToken,
        }}
      >
        <NewTableWrapper>
          <RolesHeader total={data?.total} pageChanged={pageChanged} />
          <RolesTable isLoading={isLoading} roles={data?.roles} />
        </NewTableWrapper>
      </RolesContext.Provider>
    </div>
  );
};

export default Roles;
