import React from 'react';
import Button from 'shared/components/andtComponents/Button.jsx';
import { palette } from 'shared/constants/colorsConstants.js';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext.jsx';
import { COST_CONFIGURATION, FILTERS } from 'recommendationsNew/consts.js';
import styles from './pricingMode.module.scss';
import Tooltip from 'shared/components/andtComponents/Tooltip';

const getSwaggerStyles = (onDemandMode) => ({
    selected: {
      height: '25px',
      padding: '5px 8px',
      color: onDemandMode ? palette.blue[500] : palette.purple[500],
      borderRadius: '4px',
      background: 'white',
      display: 'flex',
      opacity: 1,
      boxShadow: '-2px 1px 2px 0 rgba(0, 0, 0, 0.25)',
    },
    unSelected: {
      background: 'transparent',
    }
});
const CostMode = () => {
  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } = useRecommendationsNewContext();
  const swaggerStyles = getSwaggerStyles( filtersContext[FILTERS.COST_MODE.id] === COST_CONFIGURATION.UNBLENDED);

  return (
    <Tooltip title="View the savings using On-demand and public costs. Or choose the new capability and view the Net Amortized cost" >
      <div className={`${styles.swaggerWrapper} ${filtersContext[FILTERS.COST_MODE.id] === COST_CONFIGURATION.NET_AMORTIZED && styles.amortizeWrapper}`}>
        <Button isSecondary={filtersContext[FILTERS.COST_MODE.id] === COST_CONFIGURATION.UNBLENDED}
          overrideStyles={filtersContext[FILTERS.COST_MODE.id] === COST_CONFIGURATION.NET_AMORTIZED
           ? swaggerStyles.selected : swaggerStyles.unSelected } text="Net Amortize"
          onClick={() => setFiltersContext({
            ...filtersContext,
            [FILTERS.COST_MODE.id]:COST_CONFIGURATION.NET_AMORTIZED
          })} />

        <Button isSecondary={filtersContext[FILTERS.COST_MODE.id] === COST_CONFIGURATION.NET_AMORTIZED}
           overrideStyles={filtersContext[FILTERS.COST_MODE.id] === COST_CONFIGURATION.UNBLENDED
               ? swaggerStyles.selected : swaggerStyles.unSelected } text="On-Demand"
           onClick={() => setFiltersContext({
               ...filtersContext,
               [FILTERS.COST_MODE.id]:COST_CONFIGURATION.UNBLENDED
        })} />

      </div>
    </Tooltip>
  );
};

export default CostMode;
