import { getDataWithCustomApikey } from 'shared/utils/apiUtil';
import CustomDashboardPanel from './customDashboardPanel';

export default class CustomDashboardActivePanel extends CustomDashboardPanel {
  constructor(
    panelParams,
    activePanelParams,
    activePanelDisplaySettings,
    defaultPanelIndex,
    rootStore,
    dashboardId,
    fromTemplate,
    filters,
  ) {
    super(panelParams);
    this.dashboardId = dashboardId;
    this.fromTemplate = fromTemplate;
    this.fetchFunc = activePanelParams.fetchFunc;
    this.fetchParamsPrepFunc = activePanelParams.fetchParamsPrepFunc;
    this.dataPrepFunc = activePanelParams.dataPrepFunc;
    this.chartType = activePanelParams.chartType;
    this.startDate = activePanelParams.startDate;
    this.endDate = activePanelParams.endDate;
    this.granularity = activePanelParams.granularity;
    this.displaySettings = this.setDisplaySettings(
      activePanelDisplaySettings,
      defaultPanelIndex,
      panelParams.uuid,
      panelParams.type,
    );
    this.rootStore = rootStore;
    this.wasteCostAllocationChecked = (panelParams.state || {}).wasteCostAllocationChecked;
    this.userKey = panelParams.userKey || this.rootStore.usersStore.currentDisplayedUserKey;
    this.accountKey = panelParams.accountKey || this.rootStore.usersStore.getCurrDisplayedAccount.accountKey;
    this.divisionId = panelParams.divisionId || this.rootStore.usersStore.getCurrDisplayedAccount.divisionId;
    this.accountId =
      !fromTemplate && panelParams.accountId
        ? panelParams.accountId
        : this.rootStore.usersStore.getCurrDisplayedAccount.accountId;
    this.cloudType = panelParams.cloudTypeId;
    this.panelData = [];
    this.filters = filters;
  }

  fetchData = async () => {
    try {
      const { routeParams, rootStore, accountId, fetchFunc, state } = this;
      const userAccounts = rootStore.usersStore.getAllCurrDisplayedUserAccounts();
      const userAccId = rootStore.usersStore.getCurrDisplayedAccountId();
      const panelAccount = userAccounts.find((account) => account.accountId === accountId);
      const panelLastProcessDate = panelAccount && panelAccount.getLastProcessTime();
      routeParams.granularity = this.granularity;
      routeParams.startDate = this.startDate;
      routeParams.endDate = this.endDate;
      // fix for old panels without isPublicCost in route params
      // https://anodot.atlassian.net/browse/PIL-6490
      routeParams.isPublicCost =
        routeParams.isPublicCost === undefined ? state?.isPublicCost : routeParams.isPublicCost;
      routeParams.wasteCostAllocationChecked = this.wasteCostAllocationChecked;
      routeParams.usageType = (state && state.SelectedUsageType) || 'Usage';
      const prepArrParams = this.fetchParamsPrepFunc
        ? this.fetchParamsPrepFunc(routeParams, panelLastProcessDate, this.uuid, this.dashboardId, state, this.filters)
        : Object.values(routeParams);
      let data;
      // template panel should show current user`s account data
      if (accountId !== userAccId && !this.fromTemplate) {
        const userDataFromPanelAccount = await rootStore.usersStore.getUserDataByAccount(this.accountId);
        data = await getDataWithCustomApikey(rootStore, userDataFromPanelAccount, fetchFunc, prepArrParams);
        this.panelAccountParams = userDataFromPanelAccount;
      } else {
        data = Array.isArray(prepArrParams) ? await this.fetchFunc(...prepArrParams) : await this.fetchFunc({ ...prepArrParams });
      }
      if (data) {
        const prepareData = this.dataPrepFunc ? this.dataPrepFunc(data, routeParams) : data;
        this.panelData = prepareData;
        return prepareData;
      }
      return [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return [];
    }
  };
  setDisplaySettings = (activePanelDisplaySettings, defaultPanelIndex, uuid, panelType) =>
    activePanelDisplaySettings || {
      uuid,
      span: panelType.includes('table') ? 2 : 1,
      order: defaultPanelIndex,
    };

  get panelOrder() {
    return this.displaySettings.order;
  }

  get panelSpan() {
    return this.displaySettings.span;
  }
}
