import React, { useMemo } from 'react';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import { useMspCustomers } from 'users/new-user-management/hooks/reactQuery/useMspCustomers.js';
import {
  useCustomersContext,
} from 'users/containers/Organization/Customers/contexts/customersContext.jsx';
import CustomersHeader from './CustomersHeader';
import CustomersTable from './CustomersTable';
import styles from './Customers.module.scss';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts.js';

const Customers = () => {
  const { NewTableWrapper } = useTable();
  const { filters } = useCustomersContext();
  const { fetchMspCustomersData } = useMspCustomers();
  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts();
  const { data, isLoading } = fetchMspCustomersData({}, filters.linkedAccounts);
  const { data: payerAccounts } = fetchDataAccessPayerAccounts();

  const accounts = useMemo(() => {
    const accounts = new Map();
    payerAccounts?.forEach(({ payerAccount: payer }) => {
      const customers = data?.filter(c => c.payers.some(p => p.id === payer.id));
      const acc = {
        ...payer,
        customers: customers,
      };
      accounts.set(payer.id, acc);
    });
    return [...accounts.values()];
  }, [data, payerAccounts]);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.container}>
      <NewTableWrapper>
        <CustomersHeader
          total={data?.length}
        />
        <CustomersTable
          isLoading={isLoading}
          customers={data}
          accounts={accounts}
        />
      </NewTableWrapper>
    </div>
  );
};

export default Customers;
