import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Switch.module.scss';

const Switch = ({ options, onChange, isDisabled, isLoading, color, className }) => {

  return (
    options?.length > 0 && (
      <div className={classNames(styles.container, { [styles.inProgress]: isLoading })}>
        <div className={styles.switchOptionsContainer}>
          {options.map((option) => (
            <div
              key={option.label}
              style={option.isSelected ? { backgroundColor: color } : {}}
              className={classNames(styles.option, className, {
                [styles.selected]: option.isSelected,
                [styles.viewOnly]: option.isSelected && isDisabled,
                [styles.notSelected]: !option.isSelected,
                [styles.disabled]: isDisabled,
              })}
              onClick={(e) => onChange(e, option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    isSelected: PropTypes.bool,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Switch.defaultProps = {
  className: undefined,
  isDisabled: false,
  isLoading: false,
};

export default Switch;
