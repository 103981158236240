import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import AddUsersModal from './AddUsersModal.jsx';

const AddUsers = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        text="Add Users"
        onClick={() => {
          setIsOpen(true);
        }}
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        automationId="newUser"
      />
      <AddUsersModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default AddUsers;
