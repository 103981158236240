import React, { useCallback, useMemo, useState } from 'react';
import { Grid, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import classNames from 'classnames';
import { DataTypeProvider, IntegratedSelection, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import { CustomSelectionCell } from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import useTable from 'shared/hooks/customHooks/useTable';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import { getColumns, LINKED_ACCOUNTS_BY_CLOUD_TYPE_COLUMNS, ENTITIES } from '../../consts';
import PayerAccounts from './PayerAccounts';
import RowIconNameAndText from './RowIconNameAndText';
import { countSelectedLinkedAccounts, countSelectedPayerAccounts } from './dataAccessHelperFunctions';

import styles from './LinkedAccountsComponent.module.scss';

const CLOUD_PROVIDERS = {
  AWS: {
    id: CLOUD_TYPE_IDS.AWS,
    title: 'AWS',
    firstLevel: 'Payer Accounts',
    secondLevel: prepareLinkAccountLabel(CLOUD_TYPE_IDS.AWS, false),
  },
  AZURE: {
    id: CLOUD_TYPE_IDS.AZURE,
    title: 'Azure',
    firstLevel: 'Billing Accounts',
    secondLevel: prepareLinkAccountLabel(CLOUD_TYPE_IDS.AZURE, false),
  },
  GCP: {
    id: CLOUD_TYPE_IDS.GCP,
    title: 'GCP',
    firstLevel: 'Billing Accounts',
    secondLevel: prepareLinkAccountLabel(CLOUD_TYPE_IDS.GCP, false),
  },
};

const LinkedAccountsComponentTable = ({
  additionalData,
  allAccountsData,
  isCreateEditMode,
  linkedAccountsColumns,
  modifiedSelectionById,
  onSelectionChange,
  onRemoveLinkedAccount,
  payerAccountsColumns,
  entity,
}) => {
  const cloudProviders = Object.values(CLOUD_PROVIDERS);
  const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);
  if (entity === ENTITIES.CUSTOMER.id) {
    // customer should not have cloud level selection
    cloudProviders.forEach((c) => {
      c.isNotAvailable = true;
    });
  }
  const [cloudProvidersExpandedRows, setCloudProvidersExpandedRows] = useState([]);
  const [payerAccountsExpandedRows, setPayerAccountsExpandedRows] = useState({});

  const { NewTableRow, CustomToggleCell } = useTable();

  const expandedRowsIndexes = useMemo(
    () =>
      Object.keys(allAccountsData?.accountsData)
        ?.map((key) => +key)
        ?.map((cloudProviderId, index) => (cloudProvidersExpandedRows?.includes(cloudProviderId) ? index : -1))
        .filter((index) => index !== -1),
    [cloudProvidersExpandedRows],
  );

  const handleSelectedRowsChanged = (selectedIndexes) => {
    setSelectedRowIndexes(selectedIndexes);
    const selectedCloudProviderIds = cloudProviders
      .filter((cp, index) => selectedIndexes.includes(index))
      .map((cp) => cp.id);

    const allSelectedPayerIds = Object.entries(allAccountsData?.accountsData)
      .filter(([key]) => selectedCloudProviderIds.includes(+key))
      .flatMap(([, value]) => value)
      .map((account) => account.payerAccount)
      .filter((pa) => pa.isSelectionEnabled)
      .map((pa) => pa.id);

    onSelectionChange({ type: 'payerAccount', allSelectedIds: allSelectedPayerIds });
  };

  const handleCloudProvidersExpandedChange = (expandedRowsIndexes) => {
    const expandedIds = cloudProviders.filter((cp, index) => expandedRowsIndexes.includes(index)).map((cp) => cp.id);
    setCloudProvidersExpandedRows(expandedIds);
  };

  const handlePayerAccountsExpandedRowsChange = (cloudProviderRowKey, expandedRowsIds) => {
    setPayerAccountsExpandedRows((prevMap) => ({
      ...prevMap,
      [cloudProviderRowKey]: expandedRowsIds,
    }));
  };

  const renderRowIconNameAndText = useCallback(
    ({ row }) => {
      const rowData = allAccountsData?.accountsData?.[row.id];
      if (rowData) {
        const cloudProvider = cloudProviders.find((o) => o.id === row.id);
        const selectedPayerAccountsCounter = isCreateEditMode
          ? countSelectedPayerAccounts(allAccountsData.accountsData[row.id], modifiedSelectionById)
          : rowData.length;
        const selectedLinkedAccountsCounter = isCreateEditMode
          ? countSelectedLinkedAccounts(allAccountsData.accountsData[row.id], modifiedSelectionById)
          : rowData.flatMap((pa) => pa.linkedAccounts).length;
        const subTitle = `${selectedPayerAccountsCounter}/${
          allAccountsData?.totalPayerAccountsByCloudProvider?.[row.id] || 0
        } ${cloudProvider.firstLevel} | Total ${cloudProvider.secondLevel}: ${selectedLinkedAccountsCounter}/${
          allAccountsData?.totalLinkedAccountsByCloudProvider?.[row.id] || 0
        }`;
        return <RowIconNameAndText cloudType={row.id} title={row.title} subTitle={subTitle} isMain />;
      }
      return null;
    },
    [
      allAccountsData.accountsData,
      allAccountsData.totalLinkedAccountsByCloudProvider,
      allAccountsData.totalPayerAccountsByCloudProvider,
      cloudProviders,
      isCreateEditMode,
    ],
  );

  const renderCloudProviderRow = useCallback(
    (props) => (
      <NewTableRow
        expandedRowIds={expandedRowsIndexes}
        setExpandedRowIds={handleCloudProvidersExpandedChange}
        {...props}
      />
    ),
    [expandedRowsIndexes],
  );

  const renderCloudProviderPayerAccounts = useCallback(
    ({ row }) => (
      <PayerAccounts
        entity={entity}
        accountsData={allAccountsData?.accountsData[row.id]}
        additionalData={additionalData}
        cloudProviderId={row.id}
        expandedRows={payerAccountsExpandedRows[row.id] || []}
        isCreateEditMode={isCreateEditMode}
        linkedAccountsColumns={linkedAccountsColumns}
        modifiedSelectionById={modifiedSelectionById}
        onExpandedRowsChange={handlePayerAccountsExpandedRowsChange}
        onRemoveLinkedAccount={onRemoveLinkedAccount}
        onSelectionChange={onSelectionChange}
        payerAccountsColumns={payerAccountsColumns}
      />
    ),
    [
      allAccountsData?.accountsData,
      isCreateEditMode,
      linkedAccountsColumns,
      modifiedSelectionById,
      onRemoveLinkedAccount,
      onSelectionChange,
      payerAccountsExpandedRows,
      payerAccountsColumns,
    ],
  );

  return (
    <div className={classNames(styles.linkedAccountsComponentContainer, { [styles.createEdit]: isCreateEditMode })}>
      <Grid
        rows={cloudProviders}
        columns={getColumns(LINKED_ACCOUNTS_BY_CLOUD_TYPE_COLUMNS)}
        automationId="role-accounts-data-access"
      >
        <SelectionState selection={selectedRowIndexes} onSelectionChange={handleSelectedRowsChanged} />
        <IntegratedSelection />
        <RowDetailState
          expandedRowIds={expandedRowsIndexes}
          onExpandedRowIdsChange={handleCloudProvidersExpandedChange}
        />
        <DataTypeProvider
          for={[LINKED_ACCOUNTS_BY_CLOUD_TYPE_COLUMNS.CLOUD_TYPE.columnName]}
          formatterComponent={renderRowIconNameAndText}
        />
        <TableWrapper virtual rowComponent={renderCloudProviderRow} height="auto" />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} selectionColumnWidth={20} />
        <TableRowDetail contentComponent={renderCloudProviderPayerAccounts} toggleCellComponent={CustomToggleCell} />
      </Grid>
    </div>
  );
};

export default LinkedAccountsComponentTable;
