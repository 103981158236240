import { palette } from 'shared/constants/colorsConstants';

export const PAYMENT_OPTIONS = ['all upfront', 'partial upfront', 'no upfront'];

export const TERMS = ['1-year', '3-year'];

export const SP_TABS = [
  { id: '0', name: 'Recommended Plan', title: '', icon: 'memoPad' },
  { id: '1', name: `Forecasted Plans`, title: '', icon: 'binoculars' },
];

export const SIMULATION_PROPERTIES = {
  newCost: {
    header: 'New Commitment Costs',
    properties: [
      {name: 'Total Commitment', key: 'totalYearCommitment', isCurrency: true},
      {name: 'Upfront Cost', key: 'recommendedUpfrontPayment', isCurrency: true},
      {name: 'Monthly Recurring Charges', key: 'monthlyRecurringCharges', isCurrency: true},
    ],
  },
  totalCost: {
    header:'Total Costs',
    properties: [  {
      name: 'Current Monthly Compute Cost',
      key: 'simulatedCurrentMonthlyNetCost',
      isCurrency: true,
      isShareProperty: true,
    },
      {
        name: 'Estimated Monthly Compute Cost',
        key: 'recommendedMonthlyNetCost',
        isCurrency: true,
        estimatedProp: true,
        percentOf: { currentKey: { key: 'simulatedCurrentMonthlyNetCost', isShareProperty: true}, estimatedKey: {key: 'recommendedMonthlyNetCost' }}
      },
      {
        name: 'Current Monthly On-Demand Cost',
        key: 'simulatedCurrentMonthlyOdCost',
        isShareProperty: true,
        splitLine:true,
        isCurrency: true,
      },
      {
        name: 'Estimated Monthly On-Demand Cost',
        key: 'recommendedMonthlyOdCost',
        isCurrency: true,
        estimatedProp: true,
        percentOf: { currentKey: { key: 'simulatedCurrentMonthlyOdCost', isShareProperty: true}, estimatedKey: {key: 'recommendedMonthlyOdCost' }}

      }],
    info: 'Cost figures are based on days-to-check and normalized to a month'
  }
}
export const CURRENT_PROPERTIES = {
  totalCost: {
    header: 'Total Costs',
    properties: [
      {
        name: 'Current Monthly Compute Costs',
        key: 'currentMonthlyNetCost',
        isShareProperty: true,
        isCurrency: true,
      },
      {
        name: 'Current On-Demand Cost',
        key: 'currentMonthlyOdCost',
        isShareProperty: true,

        isCurrency: true,
      }],
    info: 'Cost figures are based on days-to-check and normalized to a month'
  }
}
export const RECOMMENDATION_PLAN_PROPERTIES = [
  { name: 'Hourly Commitment', key: 'recommendedHourlyCommitment', isCurrency: true, isEditable: true },
  {
    name: 'Estimated Monthly Savings',
    key: 'totalSaving',
    calculatedFields: [{key: 'simulatedCurrentMonthlyNetCost', isShareProperty: true}, { key:'recommendedMonthlyNetCost', isShareProperty: false}],
    isCurrency: true,
  },
];
export const EXPORT_COLUMNS = [
  { label: 'Date', key: 'date' },
  { label: 'Current Coverage', key: 'currentSP' },
  { label: 'Estimated Coverage', key: 'recommendedSP' },
  { label: 'On-Demand Equivalent', key: 'onDemand' },
  { label: 'Current On-Demand', currentLabel: 'Current On-Demand', key: 'actualOnDemand', isSimulateColumn: false },
  { label: 'Estimated On-Demand', key: 'actualAccOnDemand', isEstimateColumn: true },
  { label: 'Current Savings Plan', currentLabel: 'Current Savings Plan', key: 'hourlyCommitment' , isSimulateColumn: false },
  { label: 'Estimated Savings Plan', key: 'accHourlyCommitment', isEstimateColumn: true },
  { label: 'expected unused commitments', key: 'unUsedCommitment', isEstimateColumn: true, display: true},


];
export const CHART_TYPES = {
  onDemand: 'onDemand',
  actual: 'actual',
};

export const ON_DEMAND_CHART_LEGEND = {
  simulate: [
    {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', opacity: 0.2, isSimulate: false, isDash: false },
    {key: 'recommendedSP', name: 'Estimated Coverage', color: palette.blue[500], opacity: 0.2, isSimulate: false, isDash: false },
    {key: 'onDemand', name: 'On-Demand Equivalent', color: '#CD84E7', opacity: 0.2, isSimulate: false, isDash: false },
  ],
  current: [ {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', opacity: 0.2, isSimulate: false, isDash: false },
    {key: 'onDemand', name: 'On-Demand Equivalent', color: '#CD84E7', opacity: 0.2, isSimulate: false, isDash: false },]
};
export const ACTUAL_CHART_LEGEND = {
  simulate: [
    {
      key: 'hourlyCommitment',
      name: 'Current Savings Plan ',
      color: '#00B7F1',
      isEstimated: false,
      opacity: 0.4,
      estimateOpacity: 0.2,
      strokeWidth: 2,
      isDash: false
    },
    { key: 'accHourlyCommitment', name: 'Estimated Savings Plan', color: '#0295C5', isEstimated: true, opacity: 0.1, isDash: false},
    {
      estimateKey: 'netCost',
      key: 'actualOnDemand',
      exportKey: 'actualOnDemand',
      name: 'Current On-Demand',
      color: '#D083E8',
      isGradientFill: true,
      isEstimated: false,
      opacity: 0.4,
      estimateOpacity: 0.4,
      strokeWidth: 2,
      isDash: false
    },
    {
      key: 'accNetCost',
      hourlyKey: 'recommendedOnDemandValue',
      exportKey: 'actualAccOnDemand',
      name: 'Estimated On-Demand',
      color: '#8947CD',
      isEstimated: true,
      opacity: 0.1,
      isDash: false
    }],
  current: [
    {
      key: 'hourlyCommitment',
      name: 'Current Savings Plan ',
      color: '#00BBD4',
      isEstimated: false,
      opacity: 0.4,
      estimateOpacity: 0.2,
      isDash: false
    },
    {
      estimateKey: 'netCost',
      key: 'actualOnDemand',
      exportKey: 'actualOnDemand',
      name: 'Current On-Demand',
      color: '#CD84E7',
      isEstimated: false,
      opacity: 0.4,
      estimateOpacity: 0.2,
      isDash: false
    },
  ]};
export const ON_DEMAND_TP_MAP_DATA = {
  simulate: {
    regularMode: {
      fields: [
        {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', percentOf: 'onDemand'},
        {key: 'recommendedSP', name: 'Estimated Coverage',  color: palette.blue[500], percentOf: 'onDemand', isBold: true},
        {key: 'onDemand', name: 'On-Demand Equivalent', color: '#CD84E7'},
      ]
    },
    tpType: 'onDemand',
  },
  current: {
    regularMode: {
      fields: [
        {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', percentOf: 'onDemand'},
        {key: 'onDemand', name: 'On-Demand Equivalent', color: '#CD84E7'},
      ]
    },
    tpType: 'onDemand',
  }
};
export const ACTUAL_TP_MAP_DATA = {
  simulate: {
    regularMode: {
      fields: [
        {name: 'Current On-Demand', key: 'actualOnDemand', color: '#CD84E7'},
        {name: 'Current Savings Plan', key: 'hourlyCommitment', color: '#00BBD4'},
      ],
      summary: [{name: 'Total Current', displayField: 'netCost'}],
    },
    estimatedMode: {
      fields: [
        {
          header: 'On-Demand',
          subFields: [
            {name: 'Current', key: 'actualOnDemand', color: '#D083E8'},
            {name: 'Estimated', key: 'actualAccOnDemand', color: '#8947CD',
              extraDetails: {name: 'Unused Commitments', key: 'unUsedCommitment', fontColor: '#8995A0' }},
          ],
        },
        {
          header: 'Savings Plan',
          subFields: [
            {name: 'Estimated', key: 'accHourlyCommitment', color: '#0295C5'},
            {name: 'Current', key: 'hourlyCommitment', color: '#00B7F1'},
          ],
        },
      ],
      summary: [
        {name: 'Current', key: 'netCost'},
        {name: 'Estimated', key: 'accNetCost'},
        {
          name: 'Savings',
          minusFields: ['netCost', 'accNetCost'],
        },
      ],
    },
    simulateEstimatedMode: {
      fields: [
        {name: 'Estimated Savings Plan', key: 'accHourlyCommitment', color: '#00BBD4'},
        {name: 'Estimated On-Demand', key: 'actualAccOnDemand', color: '#CD84E7'},
      ],
      summary: [
        {name: 'Estimated Cost', displayField: 'accNetCost'},
        {
          name: 'Estimated Savings',
          minusFields: ['simulateNetCost', 'accNetCost'],
        },
        {name: 'Simulate Cost', displayField: 'simulateNetCost'},
      ],
    },
    tpType: 'actual',
  },
  current: {
    regularMode: {
      fields: [
        {name: 'Current On-Demand', key: 'actualOnDemand', color: '#CD84E7'},
        {name: 'Current Savings Plan', key: 'hourlyCommitment', color: '#00BBD4'},
      ],
      summary: [{name: 'Total Current', displayField: 'netCost'}],
    },
    tpType: 'actual',
  }

};

export const ON_DEMAND_CHART = {
  simulate: {
    header: 'Simulated On-Demand Equivalent (Coverage)',
    info: (
        <>
          In this chart:
          <ul style={{paddingLeft: '20px'}}>
            <li>All usage is assumed on-demand.</li>
            <li>Simulate your current and recommended coverage relative to the on-demand equivalent.</li>
            <li>The lines are independent</li>
          </ul>
        </>
    ),
    // 'Simulate your costs based on your current commitments.
    //This chart displays your costs as if all usage were on-demand (the on-demand equivalent), along with your current and recommended covered costs.'
  },
  current : {
    header: 'Current On-Demand Equivalent (Coverage)',
    info: 'This chart displays your costs as if all usage were on-demand (the on-demand equivalent), along with your current covered costs.'
  }
};
export const ACTUAL_CHART = {
  simulate: {
    header: 'Simulated Compute Cost',
    info: (
        <>
          In this chart:
          <ul style={{paddingLeft: '20px'}}>
            <li>Existing SPs are included</li>
            <li>The presented cost is cumulative</li>
          </ul>
        </>
    ),
    // Simulate your costs based on your current commitments.
    // This chart displays your costs as if all usage were on-demand (the on-demand equivalent), along with your current and recommended covered costs.'
  }, current: {
    header: 'Current Compute Cost',
    info: `This chart displays your current savings plans and on-demand costs for the chosen period,
           determined by the 'days-to-check'. Note: These costs are accumulated.`
  },
};
export const PLAN_KEYS_SORT_ORDER = ['1YearNoUpfront', '1YearPartialUpfront', '1YearAllUpfront', '3YearNoUpfront', '3YearPartialUpfront', '3YearAllUpfront'];
