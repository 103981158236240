import React from 'react';
import PropTypes from 'prop-types';
import CurrVsPreviousMonthCost from './CurrVsPreviousMonthCost';
import MonthlyYtdCostBarChart from 'shared/components/charts/SimpleBarChart';
import TopServicesCostChart from './TopServicesCostChart';
import YesterdayTopServicesCostChart from './YesterdayTopServicesCostChart';
import RegionActivityMap from 'shared/components/RegionActivityMap';
import MainDbConstants from 'app/constants/dbConstants';
import { getMonthNameFromNumber } from 'shared/utils/dateUtil';
import { REPORT_TYPES } from 'usage/constants/usageConstants';
import { Row } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import PanelWithActionButton from 'shared/components/panels/PanelWithActionButton';
import ReportIcon from 'mdi-react/FileDocumentBoxIcon';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants.js';

const Charts = ({
  mainDashBoardData,
  currMonth,
  currYear,
  prevMonth,
  prevYear,
  isShowCurrVsPreviousMonthCost,
  isShowYtdCostPerMonth,
  topServicesColumns,
  topServicesColumnWidth,
  handleGoToCostExplorerReport,
  actionButtonStyle,
  setTopServicesColumnWidth,
}) => {
  return (
    <>
      <Row className="scale">
        {isShowCurrVsPreviousMonthCost && (
          <CurrVsPreviousMonthCost
            data={mainDashBoardData.preparedCostPerDay}
            height={350}
            title={`${getMonthNameFromNumber(currMonth)} ${currMonth === 1 ? currYear : ''} vs ${getMonthNameFromNumber(
              prevMonth,
            )} ${prevYear}`}
            xLabel={MainDbConstants.CURR_VS_PREV_X_LABEL}
            yLabel={MainDbConstants.CURR_VS_PREV_Y_LABEL}
            handleGoToCostExplorerReport={handleGoToCostExplorerReport}
            actionButtonStyle={actionButtonStyle}
            xl={isShowYtdCostPerMonth ? 6 : 12}
            lg={isShowYtdCostPerMonth ? 6 : 12}
            md={12}
            sm={12}
            xs={12}
          />
        )}
        {isShowYtdCostPerMonth && (
          <PanelWithActionButton
            divider
            link
            title="Cost History (Month over Month) "
            panelClass="panel--narrow"
            ActionButtonComp={LinkIconButton}
            ButtonIcon={ReportIcon}
            handler={handleGoToCostExplorerReport}
            id={REPORT_TYPES.MONTHLY_COST_YTD}
            actionButtonStyle={actionButtonStyle}
            xl={isShowCurrVsPreviousMonthCost ? 6 : 12}
            lg={isShowCurrVsPreviousMonthCost ? 6 : 12}
            md={12}
            sm={12}
            xs={12}
          >
            <MonthlyYtdCostBarChart
              data={mainDashBoardData.preparedCostPerMonth}
              displayedMetric={DisplayMetricTypes.COST}
              height={350}
              dataKey="usageDate"
              yLabel="Cost"
            />
          </PanelWithActionButton>
        )}
      </Row>
      <Row className="scale">
        {topServicesColumns && topServicesColumnWidth ? (
          <TopServicesCostChart
            data={mainDashBoardData.preparedTopServices}
            handleGoToCostExplorerReport={handleGoToCostExplorerReport}
            actionButtonStyle={actionButtonStyle}
            changeColumnWidths={setTopServicesColumnWidth}
            columnWidths={topServicesColumnWidth}
            columns={topServicesColumns}
          />
        ) : null}
        <YesterdayTopServicesCostChart
          data={mainDashBoardData.yesterdayTopServices}
          handleGoToCostExplorerReport={handleGoToCostExplorerReport}
          actionButtonStyle={actionButtonStyle}
        />
      </Row>
      <Row>
        {mainDashBoardData.preparedCostPerRegion?.length > 1 && (
          <RegionActivityMap
            title={MainDbConstants.REGION_ACTIVITY_MAP_TITLE}
            data={mainDashBoardData.preparedCostPerRegion}
            handleGoToCostExplorerReport={handleGoToCostExplorerReport}
            actionButtonStyle={actionButtonStyle}
            xl={12}
          />
        )}
      </Row>
    </>
  );
};

Charts.propTypes = {
  mainDashBoardData: PropTypes.shape({
    preparedCostPerDay: PropTypes.array.isRequired,
    preparedCostPerMonth: PropTypes.array.isRequired,
    preparedTopServices: PropTypes.array.isRequired,
    yesterdayTopServices: PropTypes.array.isRequired,
    preparedCostPerRegion: PropTypes.array.isRequired,
  }).isRequired,
  currMonth: PropTypes.number.isRequired,
  currYear: PropTypes.number.isRequired,
  prevMonth: PropTypes.number.isRequired,
  prevYear: PropTypes.number.isRequired,
  isShowCurrVsPreviousMonthCost: PropTypes.bool.isRequired,
  isShowYtdCostPerMonth: PropTypes.bool.isRequired,
  topServicesColumns: PropTypes.array,
  topServicesColumnWidth: PropTypes.array,
  handleGoToCostExplorerReport: PropTypes.func.isRequired,
  actionButtonStyle: PropTypes.object.isRequired,
  setTopServicesColumnWidth: PropTypes.func.isRequired,
};

export default Charts;
