import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { v1 as uuidv1 } from 'uuid';
import moment from 'moment';
import { generateCloneName } from 'shared/utils/strUtil';
import customDashboardProvider from 'usage/containers/CustomDashboard/hooks/react-query/customDashboardProvider';
import toast from 'shared/components/andtComponents/Toast';
import CustomDashboard from '../helperClasses/customDashboard';
import CustomDashboardPanel from '../helperClasses/customDashboardPanel';
import { mapPanelTypeToChartType, mapPanelTypeToRoute, PREDEFINED_PANELS } from './customDashboardConstants';

export default class CustomDashboardModel {
  dashboards = [];
  dashboardTemplates = [];
  panels = [];
  modelIsLoading = true;

  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.rootStore = null;
    this.provider = customDashboardProvider();

    makeObservable(this, {
      dashboards: observable,
      dashboardTemplates: observable,
      panels: observable,
      modelIsLoading: observable,
      fetchData: action,
      makeDefaultDashboard: action,
      getDashboardPanelIdsByDbId: action,
      createOrAddNewDashboardPanel: action,
      removeDashboard: action,
      updateDashboard: action,
      createPanelFromTemplate: action,
      updateDashboardTemplate: action,
      deleteDashboardTemplate: action,
      createTemplateFromDashboard: action,
      cloneTemplateToDashboards: action,
      removePanel: action,
      existingDashboardsNamesAndIds: computed,
      displayPanels: computed,
    });
  }

  get existingDashboardsNamesAndIds() {
    return this.dashboards.map(({ name, uuid }) => ({ name, uuid }));
  }

  get existingPanelsNames() {
    return this.panels.map(({ name }) => name);
  }

  get displayPanels() {
    return this.panels.filter(
      (currPanel) => !this.isPanelClonedPredefinedWithDiffName(currPanel) && currPanel.displayStatus,
    );
  }

  isPanelClonedPredefinedWithDiffName(panel) {
    return (
      panel.type === 'kpis' &&
      !!panel.createdBy &&
      this.panels.some((p) => p.name === panel.name && p.route === panel.route && !p.createdBy)
    );
  }

  setRootStore(rootStore) {
    this.rootStore = rootStore;
  }

  fetchData = async () => {
    try {
      this.modelIsLoading = true;
      await Promise.all([this.fetchCustomDashboards(), this.fetchCustomDbrdPanels(), this.fetchTemplates()]);
    } catch {
      // handle error
    } finally {
      runInAction(() => {
        this.modelIsLoading = false;
      });
    }
  };

  fetchCustomDashboards = async () => {
    try {
      const userKey = this.rootStore.usersStore.currentDisplayedUserKey;
      const rawDashboards = await this.provider.fetchCustomDashboards(userKey);
      const dashboards = rawDashboards.map((rawDb) => new CustomDashboard(rawDb));
      runInAction(() => {
        this.dashboards = [...dashboards];
      });
    } catch {
      // handle error
    }
  };

  getPanelById = async (id, accountId) => this.apiGateway.fetchPanelById(id, accountId);

  fetchCustomDbrdPanels = async () => {
    try {
      const userKey = this.rootStore.usersStore.currentDisplayedUserKey;
      const rawPanels = await this.provider.fetchCustomDashboardPanels(userKey);
      const panels = rawPanels?.map((rawPanel) => new CustomDashboardPanel(rawPanel));
      runInAction(() => {
        this.panels = [...panels];
      });
    } catch {
      // handle error
    }
    return this.panels;
  };

  getDashboardPanelIdsByDbId = (dbId, isTemplate) => {
    const activeDashboard = (isTemplate ? this.dashboardTemplates : this.dashboards).find((db) => db.uuid === dbId);
    if (!activeDashboard) {
      return {
        panelsIds: [],
        panelsSettings: new Map(),
      };
    }
    return {
      panelsIds: activeDashboard.panelsIds,
      panelsSettings: activeDashboard.panelsDisplaySettings,
    };
  };

  isCurrentUserOwner = (id) => {
    const dashboard = this.getExistingDashboardByUuid(id);
    const { currDispUserAccountKey } = this.rootStore.usersStore;
    if (dashboard) {
      return String(dashboard.accountKey) === String(currDispUserAccountKey);
    }
    return true;
  };

  getPanelByUuid = (id) => this.panels.find((panel) => panel.uuid === id);

  getExistingDashboardByUuid = (uuid, isTemplate) => {
    if (isTemplate) {
      return this.dashboardTemplates.find((db) => db.uuid === uuid) || null;
    }
    return this.dashboards.find((db) => db.uuid === uuid) || null;
  };

  dashboardsListWithReplacedInstance = (uuid, newDashboard) => {
    const index = this.dashboards.findIndex((db) => db.uuid === uuid);
    if (index > -1) {
      this.dashboards.splice(index, 1, newDashboard);
    }
    return [...this.dashboards];
  };

  getPanelsByIds = (panelsIds, dbAccountId) => Promise.all(panelsIds.map((id) => this.getPanelById(id, dbAccountId)));

  prepareDashboardPanelsAsTableData = (panelsIds, panelsDisplaySettings, panels) =>
    panelsIds.map((uuid) => ({
      uuid,
      name: (panels.find((p) => p?.uuid === uuid) || {}).name,
      order: (panelsDisplaySettings.get(uuid) || {}).order,
      span: (panelsDisplaySettings.get(uuid) || {}).span,
    }));

  updateCustomDashboardPanel = async (panel, panelNewParams) => {
    try {
      const updatedPanel = new CustomDashboardPanel(panel);
      updatedPanel.setName(panelNewParams.panelName);
      updatedPanel.setGranularity(panelNewParams.granularity);
      updatedPanel.setPeriod(panelNewParams.relativeDatesPeriod);
      updatedPanel.setNumberOfItems(panelNewParams.numberOfItems);
      updatedPanel.setIsDefaultOthers(panelNewParams.isDefaultOthers);
      const res = await this.apiGateway.updateCustomDashboardPanel(updatedPanel);
      if (res) {
        const panels = this.panels.map((p) => (p.uuid === updatedPanel.uuid ? updatedPanel : p));
        runInAction(() => {
          this.panels = [...panels];
        });
      }
    } catch {
      // handle error
    }
  };

  overwriteExistingCustomDashboardPanel = async (panelNewParams) => {
    try {
      const panel = await this.getPanelById(panelNewParams.uuid);
      if (!panel) {
        return;
      }
      const createdBy = this.rootStore.usersStore.currentDisplayedUserName;
      const creationDate = moment().format('YYYY-MM-DD');
      panelNewParams.periodParams = { ...panel.periodParams };
      const updatedPanel = this.handleCreateNewOrExistingPanel(panelNewParams, createdBy, creationDate);
      this.modelIsLoading = true;
      const res = await this.apiGateway.updateCustomDashboardPanel(updatedPanel);
      if (res) {
        runInAction(() => {
          this.panels = this.panels.map((p) => (p.uuid === panelNewParams.uuid ? updatedPanel : p));
          this.modelIsLoading = false;
        });
      }
    } catch {
      this.modelIsLoading = false;
    }
  };

  fetchTemplates = async () => {
    const userKey = this.rootStore.usersStore.currentDisplayedUserKey;
    const rawTemplates = await this.provider.fetchCustomDashboardTemplates(userKey);
    const templates = rawTemplates.map((rawDb) => new CustomDashboard(rawDb));
    runInAction(() => {
      this.dashboardTemplates = [...templates];
    });
  };

  createTemplateFromDashboard = async (dashboardId) => {
    await this.apiGateway.createCustomDashboardTemplate(dashboardId);
    await this.fetchTemplates();
  };

  updateDashboardTemplate = async (template, data) => {
    const newTemplate = { ...template };
    if (data) {
      newTemplate.name = data.name;
      newTemplate.panelsIds = data.panelsIds;
      newTemplate.panelsDisplaySettings = new Map(data.panelsDisplaySettings);
    }
    newTemplate.reArrangePanelsAccordingToOrder();
    newTemplate.panelsDisplaySettings = [...newTemplate.panelsDisplaySettings.entries()];
    await this.apiGateway.updateCustomDashboardTemplate(newTemplate);
    await this.fetchTemplates();
  };

  deleteDashboardTemplate = async (templateId) => {
    await this.apiGateway.deleteCustomDashboardTemplate(templateId);
    await this.fetchTemplates();
  };

  clonePanels = async (panelsIds, clonedPanelSuffix, dbAccountId) => {
    const panelsIdsToClonedIds = {};
    const cloneIdsToCloneNames = {};
    if (!panelsIds || !panelsIds.length) {
      return null;
    }
    const clonedPanels = (
      await Promise.all(
        panelsIds.map(async (panelId) => {
          const tempPanel = { ...((await this.getPanelById(panelId, dbAccountId)) || {}) };
          if (tempPanel) {
            if (tempPanel.type !== PREDEFINED_PANELS) {
              tempPanel.uuid = uuidv1();
              tempPanel.name = clonedPanelSuffix
                ? `${tempPanel.name} ${clonedPanelSuffix}`
                : generateCloneName(tempPanel.name, this.existingPanelsNames);
              panelsIdsToClonedIds[panelId] = tempPanel.uuid;
              cloneIdsToCloneNames[tempPanel.uuid] = tempPanel.name;
              return tempPanel;
            }
            return false;
          }
          return false;
        }),
      )
    ).filter(Boolean);
    return { clonedPanels, panelsIdsToClonedIds, cloneIdsToCloneNames };
  };

  cloneDashboard = async (dashboard, clonedPanelSuffix) => {
    const { clonedPanels, panelsIdsToClonedIds, cloneIdsToCloneNames } = await this.clonePanels(
      dashboard.panelsIds,
      clonedPanelSuffix,
    );
    const newPanelIds = dashboard.panelsIds.map((uuid) => panelsIdsToClonedIds[uuid] || uuid);
    const newPanelsDisplaySettings = Array.from(dashboard.panelsDisplaySettings).map(([key, value]) => [
      panelsIdsToClonedIds[key] || key,
      value,
    ]);
    const preparedDashboard = {
      uuid: uuidv1(),
      name: dashboard.name,
      userKey: dashboard.userKey,
      accountId: dashboard.accountId,
      accountKey: dashboard.accountKey,
      creationDate: moment().format('YYYY-MM-DD'),
      createdBy: dashboard.createdBy,
      panelsIds: newPanelIds,
      panelsDisplaySettings: new Map(newPanelsDisplaySettings),
    };
    await this.apiGateway.cloneCustomDashboard(preparedDashboard, panelsIdsToClonedIds, cloneIdsToCloneNames);

    runInAction(() => {
      this.dashboards = [new CustomDashboard(preparedDashboard), ...this.dashboards];
      this.panels = [...clonedPanels, ...this.panels];
    });
  };

  cloneTemplateToDashboards = async (dashboard, clonedPanelSuffix, dbAccountId, isFromTemplate) => {
    const { panelsIdsToClonedIds, cloneIdsToCloneNames } = await this.clonePanels(
      dashboard.panelsIds,
      clonedPanelSuffix,
      dbAccountId,
    );
    const newPanelIds = dashboard.panelsIds.map((uuid) => panelsIdsToClonedIds[uuid] || uuid);
    const newPanelsDisplaySettings = Array.from(dashboard.panelsDisplaySettings).map(([key, value]) => [
      panelsIdsToClonedIds[key] || key,
      value,
    ]);
    const { usersStore } = this.rootStore;
    const preparedDashboard = {
      uuid: uuidv1(),
      name: dashboard.name,
      userKey: usersStore.currentDisplayedUserKey,
      accountId: usersStore.getCurrDisplayedAccountId(),
      accountKey: usersStore.currDispUserAccountKey,
      creationDate: moment().format('YYYY-MM-DD'),
      createdBy: usersStore.currentDisplayedUserName,
      panelsIds: newPanelIds,
      panelsDisplaySettings: new Map(newPanelsDisplaySettings),
    };
    await this.apiGateway.cloneCustomDashboard(
      preparedDashboard,
      panelsIdsToClonedIds,
      cloneIdsToCloneNames,
      dashboard.accountId,
      isFromTemplate,
    );

    this.fetchCustomDashboards();
    this.fetchCustomDbrdPanels();
  };

  createPanelFromTemplate = async (predefinedPanelId, customDashboardPanelName) => {
    const panel = await this.apiGateway.createPanelFromTemplate(predefinedPanelId, customDashboardPanelName);
    const panelObj = new CustomDashboardPanel(panel);
    runInAction(() => {
      this.panels = [...this.panels, panelObj];
    });
    return panelObj;
  };

  createOrAddNewDashboardPanel = async (dashboardAndPanelParams, selectedPanelsUuids) => {
    let res = false;
    try {
      const { newDashboardName, existingDashboardUuid, ...rest } = dashboardAndPanelParams;
      const { currentDisplayedUserName: createdBy, currentDisplayedUserKey } = this.rootStore.usersStore;
      const creationDate = moment().format('YYYY-MM-DD');
      const isNewDashboard = !existingDashboardUuid;
      const isExistingPanel = !!selectedPanelsUuids;
      const newPanel = isExistingPanel
        ? this.panels.find((panel) => panel.uuid === selectedPanelsUuids[0])
        : this.handleCreateNewOrExistingPanel(rest, createdBy, creationDate);
      let newDashboard;
      const panelsIds = selectedPanelsUuids ? [...selectedPanelsUuids] : [newPanel.uuid];
      if (isNewDashboard) {
        newDashboard = new CustomDashboard({
          uuid: uuidv1(6),
          userKey: currentDisplayedUserKey,
          accountId: this.rootStore.usersStore.getCurrDisplayedAccountId(),
          accountKey: this.rootStore.usersStore.currDispUserAccountKey,
          name: newDashboardName,
          panelsIds,
          createdBy,
          creationDate,
          panelTypes: { [newPanel.uuid]: newPanel.type },
        });
        const preparedCustDbForSave = { ...newDashboard };
        preparedCustDbForSave.panelsDisplaySettings = [...newDashboard.panelsDisplaySettings.entries()];
        res = await this.apiGateway.createNewCustomDashboard(preparedCustDbForSave, isExistingPanel ? null : newPanel);
      } else {
        newDashboard = new CustomDashboard(this.getExistingDashboardByUuid(existingDashboardUuid));
        if (newDashboard) {
          newDashboard.addPanel(newPanel.uuid, newPanel.type);
          const preparedCustDbForSave = { ...newDashboard };
          preparedCustDbForSave.panelsDisplaySettings = [...newDashboard.panelsDisplaySettings.entries()];
          res = await this.apiGateway.updateExistingCustomDashboard(
            preparedCustDbForSave,
            isExistingPanel ? null : newPanel,
          );
        }
      }
      if (res) {
        let updatedDashbaords = [...this.dashboards];
        const updatedPanels = [...this.panels];
        if (!isNewDashboard) {
          updatedDashbaords = this.dashboardsListWithReplacedInstance(existingDashboardUuid, newDashboard);
        } else {
          updatedDashbaords.push(newDashboard);
        }
        if (!isExistingPanel) {
          updatedPanels.push(newPanel);
        }
        runInAction(() => {
          this.panels = [...updatedPanels];
          this.dashboards = [...updatedDashbaords];
        });
        toast.success('Created Successfully');
      }
    } catch {
      runInAction(() => {
        this.panels = [...this.panels];
        this.dashbaords = [...this.dashbaords];
      });
      toast.error('Failed to Create Dashboard');
    }
    return res;
  };

  removeDashboard = async (dashboardId) => {
    await this.apiGateway.removeCustomDashboard(dashboardId);
    const updatedDashboards = this.dashboards.filter((currDashboard) => currDashboard.uuid !== dashboardId);
    runInAction(() => {
      this.dashboards = [...updatedDashboards];
    });
  };

  setDashboardSetting = (dashboardId, setting) => {
    this.dashboards = this.dashboards.map((d) => {
      if (d.uuid !== dashboardId) {
        return d;
      }
      return {
        ...d,
        setting,
      };
    });
  };

  makeDefaultDashboard = async (dashboard, isDefault) => {
    if (!dashboard) {
      return;
    }
    const preparedDashboard = {
      ...dashboard,
      isDefault: !isDefault,
      panelsDisplaySettings: [...dashboard.panelsDisplaySettings.entries()],
    };
    const res = this.apiGateway.updateCustomDashboard(preparedDashboard);
    if (res) {
      runInAction(() => {
        this.dashboards = this.dashboards.map((d) => ({
          ...d,
          isDefault: d.uuid === dashboard.uuid ? !isDefault : false,
        }));
      });
    }
  };

  updateDashboard = async (dashboard, data) => {
    try {
      if (data) {
        dashboard.name = data.name;
        dashboard.panelsIds = data.panelsIds;
        dashboard.panelsDisplaySettings = new Map(data.panelsDisplaySettings);
      }
      const preparedCustDbForSave = {
        ...dashboard,
      };
      preparedCustDbForSave.reArrangePanelsAccordingToOrder();
      preparedCustDbForSave.panelsDisplaySettings = [...dashboard.panelsDisplaySettings.entries()];
      const res = await this.apiGateway.updateCustomDashboard(preparedCustDbForSave);
      if (res) {
        const updatedDashboardIdx = this.dashboards.findIndex((currDashboard) => currDashboard.uuid === dashboard.uuid);
        const { dashboards } = this;
        dashboards.splice(updatedDashboardIdx, 1, new CustomDashboard(dashboard));
        runInAction(() => {
          this.dashboards = [...dashboards];
        });
      }
    } catch {
      // handle error
    }
  };

  hidePanel = async (panel) => {
    try {
      panel = await this.getPanelById(panel.uuid);
      panel.displayStatus = 0;
      const res = await this.apiGateway.updateCustomDashboardPanel(panel);
      if (res) {
        const panelForUpdateIdx = this.panels.findIndex((currPanel) => currPanel.uuid === panel.uuid);
        this.panels.splice(panelForUpdateIdx, 1, panel);
        runInAction(() => {
          this.panels = [...this.panels];
        });
      }
      return res;
    } catch {
      return false;
    }
  };

  removePanel = async (panelId, dashboard) => {
    try {
      dashboard.removePanel(panelId);
      await this.updateDashboard(dashboard);
      return true;
    } catch {
      return false;
    }
  };

  handleCreateNewOrExistingPanel = (params, createdBy, creationDate) => {
    const { usersStore } = this.rootStore;
    const prepPanelParams = {
      name: params.panelName,
      uuid: params.uuid || uuidv1(6),
      createdBy,
      creationDate,
      type: params.type,
      cloudType: params.cloudType,
      chartType: mapPanelTypeToChartType.get(params.type),
      route: mapPanelTypeToRoute.get(params.type),
      periodParams: params.periodParams,
      routeParams: params.routeParams,
      displayStatus: 1,
      state: params.state,
      arrBreadCrumbs: params.arrBreadCrumbs,
      accountId: usersStore.getCurrentDisplayedAccountId(usersStore.currDispUserAccountKey),
      accountKey: usersStore.currDispUserAccountKey,
      divisionId: usersStore.currDispUserDivisionId,
    };
    return new CustomDashboardPanel(prepPanelParams);
  };

  isPanelInDashboard = (dashboardId, panelId) => {
    const dashboard = this.dashboards.find((dbrd) => dbrd.uuid === dashboardId);
    if (dashboard) {
      const panelIndex = dashboard.getPanelIndexById(panelId);
      return panelIndex > -1;
    }
    return false;
  };

  removeDashboardsWherePanelAlreadyExists = (existingDashboardsNamesAndIds, panelId) => {
    let filteredArr = [...existingDashboardsNamesAndIds];
    if (existingDashboardsNamesAndIds && existingDashboardsNamesAndIds.length) {
      filteredArr = existingDashboardsNamesAndIds.filter((db) => !this.isPanelInDashboard(db.uuid, panelId));
    }
    return filteredArr;
  };

  createCustomFilter = async (dashboardId, data) => {
    const filter = await this.apiGateway.createCustomFilter(dashboardId, data);
    runInAction(() => {
      this.dashboards = this.dashboards.map((db) => {
        if (db.uuid !== dashboardId) {
          return db;
        }
        return {
          ...db,
          filters: [filter],
        };
      });
    });
  };

  updateCustomFilter = async (dashboardId, filter) => {
    const result = await this.apiGateway.updateCustomFilter(dashboardId, filter);
    runInAction(() => {
      this.dashboards = this.dashboards.map((db) => {
        if (db.uuid !== dashboardId) {
          return db;
        }
        return {
          ...db,
          filters: [result],
        };
      });
    });
  };

  deleteCustomFilter = async (dashboardId, filterId) => {
    await this.apiGateway.deleteCustomFilter(filterId);
    runInAction(() => {
      this.dashboards = this.dashboards.map((db) => {
        if (db.uuid !== dashboardId) {
          return db;
        }
        return {
          ...db,
          filters: null,
        };
      });
    });
  };

  invalidateData = () => {
    this.dashboards = [];
    this.panels = [];
    this.modelIsLoading = true;
  };
}
