import React from 'react';
import PropType from 'prop-types';
import { ReactComponent as NoRoles } from 'users/containers/Organization/Roles/img/no-roles.svg';
import classNames from 'classnames';

import styles from './RolesNoData.module.scss';

const RolesNoData = ({ colSpan, mainText, subText, isSmall }) => {
  return (
    <td colSpan={colSpan} className="border-bottom-0 border-start-0 border-end-0">
      <div className={classNames(styles.rolesEmptyState, { [styles.small]: isSmall })}>
        <NoRoles className={styles.svgImage} />
        <div className={styles.emptyStateText}>
          <span className={styles.mainText}>{mainText || 'No Roles found'}</span>
          <span className={styles.text}>{subText || 'Refine your filters to find the role you are looking for'}</span>
        </div>
      </div>
    </td>
  );
};

RolesNoData.propTypes = {
  colSpan: PropType.number,
  isSmall: PropType.bool,
  mainText: PropType.string,
  subText: PropType.string,
};

export default RolesNoData;
