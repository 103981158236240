import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import toast from 'shared/components/andtComponents/Toast';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { ROLE_ACTIONS } from '../../consts';

const DeleteRolesModal = ({ isOpen, setIsOpen, roles, onSuccess }) => {
  const [saveClicked] = useState(false);

  const { deleteRoles } = useRoles();

  const onDelete = async () => {
    try {
      await deleteRoles.mutateAsync({ rolesIds: roles.map((role) => role?.id) });
      setIsOpen(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while deleting role');
      }
    }
  };

  return (
    isOpen && (
      <CustomModal
        onClose={() => {
          setIsOpen(false);
        }}
        onCloseClick={() => {
          setIsOpen(false);
        }}
        headerMode={MODAL_MODES.SIMPLE}
        footerDisabled={saveClicked}
        onSave={onDelete}
        open={isOpen}
        automationId="delete-role-modal"
        saveTitle="Delete"
        saveWarning
        saveDisabled={false}
        title={ROLE_ACTIONS.DELETE.label}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            You are about to delete {roles?.length > 1 ? 'the roles' : 'this role'}. This action cannot be undone.
          </div>
          <div> Are you sure?</div>
        </div>
      </CustomModal>
    )
  );
};

DeleteRolesModal.propTypes = {
  isOpen: PropTypes.bool,
  onSuccess: PropTypes.func,
  setIsOpen: PropTypes.func,
  roles: PropTypes.array,
};

export default DeleteRolesModal;
