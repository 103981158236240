import React, { useMemo, useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import {
  ACCOUNT_TYPES,
  COST_CENTER_LINKED_ACCOUNTS_COLUMNS,
  ENTITIES,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
  ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS,
} from '../../consts';
import RemoveButton from '../RemoveButton';
import LinkedAccountInfoIcon from './LinkedAccountInfoIcon';
import AssignedBy from './AssignedBy';
import {
  getAccountRemoveOrInfo,
  getIsAccountSelected,
  getLinkedAccountIsSelectionEnabled,
  getLinkedAccountsIsSelectionHidden,
  STATES,
} from './dataAccessHelperFunctions';
import NoAccounts from 'users/containers/Organization/components/LinkedAccounts/NoAccounts.jsx';

const LinkedAccountsTable = ({
  accountsData,
  cloudProviderId,
  isCreateEditMode,
  payerAccount,
  linkedAccountsColumns,
  onRemoveLinkedAccount,
  onSelectionChange,
  entity,
  modifiedSelectionById,
}) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS).map((c) => ({
      columnName: c.columnName,
      width: c.width,
    })),
  );

  const isLinkedAccountsSelectionHidden = payerAccount?.id
    ? getLinkedAccountsIsSelectionHidden(payerAccount, modifiedSelectionById[payerAccount?.id], entity)
    : undefined;

  const selectedRowIndexes = useMemo(
    () =>
      accountsData
        ?.map((la, index) => (getIsAccountSelected(la, modifiedSelectionById) ? index : -1))
        .filter((index) => index !== -1),
    [accountsData, modifiedSelectionById],
  );

  const handleSelectedRowsChanged = (selectedRowIndexes) => {
    const selectedLinkedAccountsIds = accountsData
      .filter((la, index) => selectedRowIndexes.includes(index))
      .map((la) => la.id);

    onSelectionChange({
      type: ACCOUNT_TYPES.LINKED_ACCOUNT,
      allSelectedIds: selectedLinkedAccountsIds,
      parentPayerAccount: payerAccount,
    });
  };

  const renderRemoveOrInfoButton = ({ row }) => {
    const column = linkedAccountsColumns.find(
      (c) =>
        c === ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName ||
        c === COST_CENTER_LINKED_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName,
    );
    if (column) {
      if (row) {
        const removeOrInfo = getAccountRemoveOrInfo(row, isCreateEditMode, entity);
        if (removeOrInfo === STATES.REMOVE) {
          return (
            <RemoveButton
              removeClicked={() => onRemoveLinkedAccount?.(row, ACCOUNT_TYPES.LINKED_ACCOUNT, payerAccount)}
            />
          );
        }
        if (removeOrInfo === STATES.INFO) {
          return <LinkedAccountInfoIcon row={row} />;
        }
      }
    }
    return null;
  };

  const renderLinkedAccountName = (props) => (
    <CustomFormatterWithTooltipOnlyIfLongText overrideStyles={{ fontSize: 12 }} {...props} />
  );

  const renderAssignedBy = ({ row }) => {
    if (row?.isNotAvailable) {
      // used in customer creation modal to disable already selected linked accounts
      return <span>Assigned to another customer</span>;
    }
    if (row && entity !== ENTITIES.CUSTOMER.id) {
      return (
        <AssignedBy
          entity={entity}
          isCreateEditMode={isCreateEditMode}
          linkedAccount={row}
          modifiedSelectionById={modifiedSelectionById}
          payerAccount={payerAccount}
        />
      );
    }
    return null;
  };

  const renderCustomSelectionCell = (props) => {
    const { row } = props;
    const isSelected = getIsAccountSelected(row, modifiedSelectionById);
    const isEnabled = getLinkedAccountIsSelectionEnabled(row, entity);
    const newProps = { ...props, selected: isSelected };

    return <CustomSelectionCell {...newProps} isDisabled={!isEnabled} isNotAvailable={row?.isNotAvailable} />;
  };

  return (
    <Grid
      rows={accountsData}
      columns={getColumns(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS)}
      automationId="role-linked-accounts-data-access"
    >
      <SortingState
        columnExtensions={getSortingColumns(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS)}
        defaultSorting={getDefaultSorting(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS)}
      />
      <DataTypeProvider
        for={[ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.LINKED_ACCOUNT.columnName]}
        formatterComponent={renderLinkedAccountName}
      />
      {linkedAccountsColumns.find((c) => c === ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.ASSIGNED_BY.columnName) && (
        <DataTypeProvider
          for={[ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.ASSIGNED_BY.columnName]}
          formatterComponent={renderAssignedBy}
        />
      )}
      <DataTypeProvider
        for={[
          ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName,
          COST_CENTER_LINKED_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName,
        ]}
        formatterComponent={renderRemoveOrInfoButton}
      />
      <TableWrapper
        virtual
        noDataCellComponent={({ colSpan }) =>
          NoAccounts({ colSpan, type: ACCOUNT_TYPES.LINKED_ACCOUNT, cloudProviderId, entity })
        }
        height="fit-content"
      />
      <SelectionState selection={selectedRowIndexes} onSelectionChange={handleSelectedRowsChanged} />
      <IntegratedSelection />
      <TableSelection
        showSelectAll={false}
        showSelectionColumn={!isLinkedAccountsSelectionHidden}
        cellComponent={renderCustomSelectionCell}
        selectionColumnWidth={20}
      />
      <TableColumnResizing
        resizingMode="nextColumn"
        columnWidths={localColumnsWidth}
        onColumnWidthsChange={setLocalColumnsWidth}
      />
      {accountsData.length > 0 && <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />}
    </Grid>
  );
};

LinkedAccountsTable.propTypes = {
  accountsData: PropTypes.array,
  cloudProviderId: PropTypes.string,
  isCreateEditMode: PropTypes.bool,
  payerAccount: PropTypes.object,
  linkedAccountsColumns: PropTypes.array,
  onRemoveLinkedAccount: PropTypes.func,
  onSelectionChange: PropTypes.func,
  entity: PropTypes.string,
  modifiedSelectionById: PropTypes.object,
};

export default LinkedAccountsTable;
