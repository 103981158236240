import React from 'react';
import PropTypes from 'prop-types';
import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './Checkbox.module.scss';
import { palette } from '../../constants/colorsConstants';

const propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isHalfChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  primary: PropTypes.bool,
  children: PropTypes.object,
  automationId: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  noPaddings: PropTypes.bool,
  color: PropTypes.string
};

let checkboxIndex = 0;

const Checkbox = ({
  className = "",
  isChecked,
  children = null,
  isHalfChecked = false,
  primary = false,
  isDisabled = false,
  onChange,
  text = "",
  automationId = 'checkbox',
  size = 'small',
  noPaddings,
  color,
}) => {
  const index = checkboxIndex++;
  const handleChange = (event) => {
    if (isDisabled) {
      return;
    }
    onChange(event.target.checked, event);
  };
  const isReducedSize = size === 'small' || undefined;

  const checkBox = (
    <MaterialCheckbox
      checked={!!isChecked}
      disabled={isDisabled}
      onClick={handleChange}
      indeterminate={isHalfChecked ? isChecked : undefined}
      size={size}
      sx={{
        '.MuiSvgIcon-fontSizeSmall': isReducedSize && {
          width: 15,
          transform: 'translateX(1px)',
          color: primary ? undefined : color || palette.gray['500'],
        },
        '&.MuiCheckbox-sizeSmall': isReducedSize && {
          padding: noPaddings ? 0 : undefined
        }
      }}
      id={`${index}-checkbox`}
    />
  );
  const content = (
    <FormControlLabel
      label={
        <label htmlFor={`${index}-checkbox`} className={styles.labelText}>
          {text}
          {children}
        </label>
      }
      control={checkBox}
      sx={{
        '&.MuiFormControlLabel-root': {
          margin: 0,
          paddingLeft: 0,
        },
        '.MuiFormControlLabel-label': {
          marginLeft: isReducedSize && noPaddings ? '6px' : '-5px',
        },
      }}
    />
  )
  return (
    <div className={className} data-automation-id={automationId}>
      {content}
    </div>
  );
};

Checkbox.propTypes = propTypes;

export default Checkbox;
