import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import classnames from 'classnames';

import styles from './CostCenterAssignedBy.module.scss';

const DIRECT = [{ title: 'DIRECT', icon: ICONS.right.name }];

const CostCenterAssignedBy = ({ costCenter }) => {
  const assignedByList = useMemo(() => {
    if (costCenter?.isDirectlyAssigned && !costCenter?.effectiveIsAllowed) {
      return DIRECT;
    }
    if (costCenter?.assignedByRoles?.length > 0) {
      return [{ title: 'ROLE', icon: ICONS.userGearSolid.name, data: costCenter?.assignedByRoles }];
    }
    return null;
  }, [costCenter]);

  const getTooltipContent = (items) =>
    !costCenter.isDirectlyAssigned && items?.length ? (
      <div className="d-flex flex-column">
        {items.map((item) => (
          <div className="small" key={item.id}>
            {item.displayName}
          </div>
        ))}
      </div>
    ) : null;

  const onClick = (e) => {
    e.stopPropagation();
  };

  return assignedByList?.length ? (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.title}>Assigned By</div>
      {assignedByList.map((item) => (
        <div className={styles.assignedByContainer} key={item.title}>
          <Tooltip disabled={item.data?.length === 0} title={getTooltipContent(item?.data)} placement="top">
            <div
              className={classnames(styles.assignedBy, {
                [styles.isDisabled]: !costCenter?.isDirectlyAssigned,
              })}
            >
              <GenerateIcon iconName={item.icon} className={styles.assignedByIcon} />
              {item.title}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  ) : (
    <div />
  );
};

CostCenterAssignedBy.propTypes = {
  costCenter: PropTypes.shape({
    assignedByRoles: PropTypes.array,
    effectiveIsAllowed: PropTypes.bool,
    isDirectlyAssigned: PropTypes.bool,
  }).isRequired,
};

export default CostCenterAssignedBy;
