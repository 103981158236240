import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Button from 'shared/components/andtComponents/Button';
import { palette } from 'shared/constants/colorsConstants';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import styles from './recommendedChart.module.scss';

const DownloadChartMenu = ({ exportToCsv, exportToPng }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleMenu = (e, closeMenu = false) => {
    e.stopPropagation();
    setAnchorEl(closeMenu ? null : e.currentTarget);
  };

  return (
    <>
      <Button
        overrideStyles={{ color: palette.gray[500] }}
        isSecondary
        icon={() => <GenerateIcon iconName={ICONS.download.name} />}
        onClick={(e) => {
          toggleMenu(e);
        }}
        automationId="sp-chart-export"
      />
      <Menu
        classes={{ list: styles.menu }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => toggleMenu(e, true)}
      >
        <MenuItem classes={{ root: styles.menuItem }} onClick={() => setAnchorEl(null)}>
          <CustomCSVDownload
            fetchData={exportToCsv}
            automationId="export-sp-chart"
            showDownloadIcon
            isLoading={false}
            filesNumber={1}
            isSecondary
            hideText
            style={{
              background: 'none',
              padding: '0',
            }}
            automation-id="bulk-rec-export"
          >
            CSV
          </CustomCSVDownload>
        </MenuItem>
        <MenuItem classes={{ root: styles.menuItem }} onClick={() => setAnchorEl(null)}>
          <div onClick={exportToPng} className={styles.itemRow}>
            <GenerateIcon iconName={ICONS.download.name} className={styles.downloadIcon} />
            <span className={styles.type}>PNG</span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

DownloadChartMenu.propTypes = {
  exportToCsv: PropTypes.func.isRequired,
  exportToPng: PropTypes.func.isRequired,
};
export default DownloadChartMenu;
