import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import toast from 'shared/components/andtComponents/Toast';
import { createTimeZoneAgnosticDate } from 'shared/utils/dateUtil';
import { getEmailFromRecipients, prepareNewRecipients } from 'shared/utils/sharedUtils';

const root = '/api/v1/budgets/v2/i/';

export const mapBudgetParams = (body) => {
  const alerts = body.alerts?.map((alert) => ({
    ...alert,
    alertGranularity: alert.alertGranularity.map((a) => a.value),
  }));
  const budget = {
    budgetName: body.budgetName,
    budgetAmount: body.budgetAmount,
    budgetAmounts: body.budgetAmounts.map((amount) => ({
      amount: amount.amount,
      date: amount.date,
    })),
    endDate: typeof body.endDate === 'string' ? body.endDate : createTimeZoneAgnosticDate(body.endDate),
    startDate: typeof body.startDate === 'string' ? body.startDate : createTimeZoneAgnosticDate(body.startDate),
    isRelativeAlerts: body.isRelativeAlerts || true,
    alerts: alerts || [],
    costType: body.costType,
    budgetId: body.budgetId,
    description: body.description,
    isFlexible: body.isFlexible,
    excludeFilters: {},
    includeFilters: {},
    likeFilters: [],
    budgetType: body.budgetType?.value ? body.budgetType?.value : body.budgetType,
    budgetAmountType: body.budgetType?.value === 'expiring' || body.budgetType === 'expiring' ? 'planned' : 'fixed',
    period: body.period || 0,
    userKey: body.userKey,
  };
  if (body.budgetType === 'expiringFixed' || body.budgetType?.value === 'expiringFixed') {
    budget.budgetType = 'expiring';
  }
  Object.keys(body.includeFilters)?.forEach((key) => {
    const filterValues = body.includeFilters[key].map((filter) => filter.value);
    if (filterValues?.length > 0) {
      budget.includeFilters[key] = filterValues;
    }
  });
  Object.keys(body.likeFilters)?.forEach((key) => {
    const filterValues = body.likeFilters[key].map((filter) => filter.value);
    if (filterValues?.length > 0) {
      budget.likeFilters = [...budget.likeFilters, { field: key, values: `("${filterValues.join()}")` }];
    }
  });
  Object.keys(body.excludeFilters)?.forEach((key) => {
    const filterValues = body.excludeFilters[key].map((filter) => filter.value);
    if (filterValues?.length > 0) {
      budget.excludeFilters[key] = filterValues;
    }
  });
  return budget;
};
const getUserBudget = () => API.get('billings', `${root}?only_metadata=true`);
export const getBudgetById = (budgetId) => API.get('billings', `${root}${budgetId}`);
const createUserBudgetApi = (body) => API.post('billings', '/api/v1/budgets/i', { body });
const editUserBudgetApi = (body) => API.put('billings', '/api/v1/budgets/i', { body });
const deleteUserBudgetApi = (budgetId) => API.del('billings', `/api/v1/budgets?budgetId=${budgetId}`);

export function useGetUserBudget(options= {}) {
    return useQuery(
        ['fetchBudgets', apiConstants.QUERY_KEYS.BUDGET],
        async () => {
            const budgets = await getUserBudget();
            return (
                budgets?.map((b) => ({
                    ...b,
                    isExpired: b.endDate && new Date(b.endDate) < new Date(),
                    alerts: b.alerts.map((alert) => ({
                        ...alert,
                        alertEmail: getEmailFromRecipients(alert.channelRecipients),
                    })),
                })) || []
            );
        },
        {
            ...options,
            onError: (error) => {
                toast.error(error.response?.data?.clientMessage || 'Error get budgets');
            },
        },
    );
}

export function  useGetBudgetById (budgetId, options = {}) {
    return useQuery(['fetchBudgetId', apiConstants.QUERY_KEYS.BUDGET, budgetId], () => getBudgetById(budgetId),
        {
            ...options,
            enabled: !!budgetId,
            onError: (error) => {
                toast.error(error.response.data.clientMessage || `Error get budget: ${budgetId}`);
            },
        });
}

export function useCreateUserBudget (success) {
 const queryClient = useQueryClient();
 return useMutation(
    (budget) =>
        createUserBudgetApi({
            ...budget,
            alerts: budget.alerts?.map((alert) => ({
                ...alert,
                channelRecipients: prepareNewRecipients({
                    email: alert.alertEmail,
                    recipients: alert.channelRecipients || [],
                }),
            })),
        }),
    {
        onSuccess: (res) => {
            success(res);
            return queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUDGET);
        },
        onError: (error) => {
            toast.error(
                error.response?.data?.clientMessage || error.response?.data?.message || 'Error when create budget',
            );
        },
    },
);
}
export function useEditBudget (success) {
  const queryClient = useQueryClient();
  return useMutation(
      (budget) =>
         editUserBudgetApi({
            ...budget,
            alerts: budget.alerts.map((alert) => ({
                  ...alert,
                  channelRecipients: prepareNewRecipients({ email: alert.alertEmail, recipients: alert.channelRecipients }),
            })),
         }),
      {
                onSuccess: (res) => {
                    success(res);
                    return queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUDGET);
                },
                onError: (error) => {
                    toast.error(error.response.data.clientMessage || error.response.data.message || 'Error edit budget');
                },
            },
        );
}
export function useDeleteBudget(success) {
  const queryClient = useQueryClient();
  return  useMutation(deleteUserBudgetApi, {
    onSuccess: () => {
        success();
        return queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUDGET);
    },
  });
}
export default function useBudgets() {
  return {
    getUserBudget: useGetUserBudget,
    getBudgetById: useGetBudgetById,
    createUserBudget: useCreateUserBudget,
    editBudget: useEditBudget,
    deleteBudget: useDeleteBudget
  };
}
