import React, { useState } from 'react';
import { v4 } from 'uuid';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from 'app/contexts/RootStoreContext';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import * as custDabrdHelpers from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import { palette } from 'shared/constants/colorsConstants';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRecommendationsNewContext } from '../../../contexts/recommendationsNewContext';
import { useHeatMapContext } from '../contexts/heatMapContext';
import classes from './heatMapActions.module.scss';

const dashboardIcon = () => <GenerateIcon iconName={ICONS.dashboard.name} />;
const SaveAsDashboardPanel = () => {
  const [dashboardModalIsOpen, setDashboardModalIsOpen] = useState(false);
  const { usageStore, usersStore } = useRootStore();
  const cloudType = usersStore?.currDispUserCloudAccountType;
  const { existingDashboardsNamesAndIds } = usageStore.customDbSubStore.customDashboardModel;
  const [customPanelType, setCustomPanelType] = useState('heat-map');
  const { recommendationFilters: filtersContext } = useRecommendationsNewContext();
  const { heatMapSortByOption: sortByContext, heatMapGroupByOptions, isWasteChart } = useHeatMapContext();
  const getParamsForCustomDashboard = () => ({ id: v4().substring(0, 6).toLowerCase() });
  const savePanel = (panelType) => {
    setDashboardModalIsOpen(true);
    setCustomPanelType(panelType);
  };
  return (
    <>
      {!isWasteChart ? (
        <div
          className={classes.actionsPanelButton}
          onClick={() => {
            savePanel('heat-map');
          }}
        >
          <Tooltip title="Save as Dashboard Panel" classes={{ tooltip: classes.customTooltip }}>
            <div className={classes.actionsPanelButton} automation-id="heatmap-create-dashboard">
              <GenerateIcon iconName={ICONS.dashboard.name} />
            </div>
          </Tooltip>
        </div>
      ) : (
        <ButtonDropdown
          text=""
          isTextButton
          overrideButtonStyles={{ background: palette.blue[500], color: '#ffffff', width: '36px', fontSize: '16px' }}
          className={classes.actionsPanelButton}
          icon={dashboardIcon}
          automationId="saveDasboard"
        >
          <li onClick={() => savePanel('saving-opportunities')} id="saving-opportunities">
            <span>Savings Opportunities</span>
          </li>
          <li onClick={() => savePanel('cloud-waste')} id="cloud-waste">
            <span>Cloud Waste</span>
          </li>
        </ButtonDropdown>
      )}

      <NewCustomDashboardPanelModal
        modalIsOpen={dashboardModalIsOpen}
        getCurrentCauParams={getParamsForCustomDashboard}
        onClose={() => {
          setDashboardModalIsOpen(false);
          setCustomPanelType(null);
        }}
        customDashboardStore={usageStore.customDbSubStore}
        existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
        helpers={custDabrdHelpers}
        type={customPanelType}
        state={{
          filterContext: filtersContext,
          groupBy: heatMapGroupByOptions,
          sortBy: sortByContext,
          isWasteChart,
        }}
        isSelectTimePeriodEnabled={false}
        usageStore={usageStore}
        cloudType={cloudType}
      />
    </>
  );
};

export default SaveAsDashboardPanel;
