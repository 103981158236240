import React, { useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { ROLE_ACTIONS } from '../consts';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper.jsx';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles.js';
import PropTypes from 'prop-types';
import toast from 'shared/components/andtComponents/Toast.jsx';
import { cloneDeep } from 'lodash';
import DeleteRolesModal from 'users/containers/Organization/Roles/roleModals/DeleteRolesModal.jsx';

import styles from './RoleActions.module.scss';

const RoleActions = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { fetchRoleById, createRole } = useRoles(isOpen);
  const { data, isLoading } = fetchRoleById(row?.id);

  // const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  // const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  const duplicateRole = async () => {
    if (!isLoading && data) {
      try {
        const roleToDuplicate = cloneDeep(data);
        delete roleToDuplicate.id;
        roleToDuplicate.displayName = `${roleToDuplicate.displayName} (Copy)`;
        await createRole.mutateAsync({ role: roleToDuplicate });
      } catch (error) {
        if (error.response.status === 500) {
          toast.error('Error while creating a role');
        }
      }
    }
  };

  return (
    <div className={styles.roleActionsContainer} onClick={(e) => toggleOpen(e)}>
      <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger as="button" type="button">
          <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.actionsIcon} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={-10} side="left" align="start" alignOffset={20} className={styles.popoverContent}>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.View}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.VIEW.label}
                automationId={ROLE_ACTIONS.VIEW.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.VIEW.icon} className={styles.itemIcon} />}
                // onClick={() => setIsEditRoleModalOpen(true)}
              />
            </ReadOnlyDisplayWrapper>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.EDIT.label}
                automationId={ROLE_ACTIONS.EDIT.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.EDIT.icon} className={styles.itemIcon} />}
                // onClick={() => setIsEditRoleModalOpen(true)}
              />
            </ReadOnlyDisplayWrapper>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.DUPLICATE.label}
                automationId={ROLE_ACTIONS.DUPLICATE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.DUPLICATE.icon} className={styles.itemIcon} />}
                onClick={duplicateRole}
              />
            </ReadOnlyDisplayWrapper>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.DELETE.label}
                automationId={ROLE_ACTIONS.DELETE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.DELETE.icon} className={styles.itemIcon} />}
                onClick={() => setIsDeleteUserModalOpen(true)}
              />
            </ReadOnlyDisplayWrapper>
          </PopoverContent>
        </PopoverPortal>
      </Popover>

      {/* <AssignRolesModal setIsOpen={setIsAssignRolesModalOpen} isOpen={isAssignRolesModalOpen} users={[row]} /> */}
      {/* <EditUserModal setIsOpen={setIsEditRoleModalOpen} isOpen={isEditRoleModalOpen} user={row} /> */}
      <DeleteRolesModal setIsOpen={setIsDeleteUserModalOpen} isOpen={isDeleteUserModalOpen} roles={[row]} />
    </div>
  );
};

RoleActions.propTypes = {
  row: PropTypes.object,
};

export default RoleActions;
