import React from 'react';
import PropTypes from 'prop-types';
import loaderImg from '../img/sp-analyzer-loader.gif';
import comingSoonImg from '../img/coming-soon.gif';
import { ReactComponent as EmptyStateImage } from '../img/sp-empty-state.svg';
import { ReactComponent as MspStateImage } from '../img/sp-msp-state.svg';
import styles from './spSpecialMode.module.scss';

const SpSpecialMode = ({ isLoading, isEmpty, isComingSoon, isMsp, specialText, isLongText }) => (
  <div className={styles.bigIconWrapper}>
    {isLoading && <img className={styles.image} src={loaderImg} alt="loader" />}
    {isEmpty && <EmptyStateImage className={styles.image} />}
    {isMsp && <MspStateImage />}
    {isComingSoon && <img className={styles.image} src={comingSoonImg} alt="comingSoon" />}
    <span className={`${styles.specialText} ${isLongText && styles.longText}`}>{specialText}</span>
  </div>
);

SpSpecialMode.propTypes = {
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isComingSoon: PropTypes.bool,
  isMsp: PropTypes.bool,
  specialText: PropTypes.object,
  isLongText: PropTypes.bool,
};
export default SpSpecialMode;
