import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  FILTER_NUMBER_OR_DATE_OPERATIONS,
  FILTER_OPERATIONS,
} from 'shared/components/andtComponents/TableComponents/const';
import {
  CustomFilterCell,
  CustomFilterIcon,
  CustomFilterRow,
  CustomFormatterWithTooltipAndComponent,
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import { getColumns, ROLES_COLUMNS } from '../consts';
import styles from './RolesTable.module.scss';
import RolesIcon from './RolesIcon';
import RoleIdCopy from './rolesTableComponents/RoleIdCopy';
import AddUsersToRole from './rolesTableComponents/AddUsersToRole';
import RoleActions from './RoleActions';
import RoleExpandedData from './rolesExpandedRowComponents/RoleExpandedData';
import EditRoleLinkedAccounts from './rolesTableComponents/EditRoleLinkedAccounts';
import RolesBulkOperations from './RolesBulkOperations';
import { useRolesContext } from './contexts/rolesContext';
import RolesNoData from 'users/containers/Organization/components/EmptyStates/RolesNoData';
import Spinner from 'shared/components/andtComponents/Spinner';

const RolesTable = ({ roles, isLoading }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const { NewTableRow, NewTableSelectionBar, CustomToggleCell } = useTable();

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const { selectedRows, setSelectedRows, isAllExpanded, setIsAllExpanded } = useRolesContext();

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(roles?.map((row, index) => index));
    }
  }, [isAllExpanded]);

  useEffect(() => {
    if (expandedRowIds?.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds]);

  return isLoading ? (
    <Spinner />
  ) : (
    roles && (
      <Grid rows={roles} columns={getColumns(ROLES_COLUMNS)} automationId="roles-list">
        <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
        <IntegratedSelection />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ROLE_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
              <RolesIcon />
            </CustomFormatterWithTooltipAndComponent>
          )}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ROLE_ID.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent={false}>
              <RoleIdCopy roleId={props?.row?.id} />
            </CustomFormatterWithTooltipAndComponent>
          )}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.USERS_COUNT.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltipOnlyIfLongText {...props} leftPadding />}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.createdAt ? moment(props?.row?.createdAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.CREATED_BY.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.UPDATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.updatedAt ? moment(props?.row?.updatedAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.UPDATED_BY.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ROLE_DESCRIPTION.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText {...props} className={styles.roleDescription} />
          )}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ADD_LINKED_ACCOUNTS.columnName]}
          formatterComponent={EditRoleLinkedAccounts}
        />
        <DataTypeProvider for={[ROLES_COLUMNS.ADD_USERS.columnName]} formatterComponent={AddUsersToRole} />
        <DataTypeProvider for={[ROLES_COLUMNS.ACTIONS.columnName]} formatterComponent={RoleActions} />
        <TableWrapper
          virtual
          noDataCellComponent={RolesNoData}
          rowComponent={(props) => (
            <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
          )}
          height="auto"
        />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
        <TableColumnResizing
          resizingMode="nextColumn"
          defaultColumnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableRowDetail contentComponent={RoleExpandedData} toggleCellComponent={CustomToggleCell} />
        <TableHeaderRow cellComponent={CustomHeaderCell} />
        <NewTableSelectionBar selectedRows={selectedRows} counterClass={styles.counterClass}>
          <RolesBulkOperations roles={roles} />
        </NewTableSelectionBar>
      </Grid>
    )
  );
};

RolesTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
};

export default RolesTable;
