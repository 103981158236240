import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import toast from 'shared/components/andtComponents/Toast';
import { COST_CENTER_ACTIONS } from '../../consts';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';

const DeleteCostCenterModal = ({ isOpen, setIsOpen, costCenter }) => {
  const [saveClicked] = useState(false);

  const { deleteDataAccessCostCenter } = useDataAccessAccounts();

  const onDelete = async () => {
    try {
      await deleteDataAccessCostCenter.mutateAsync(costCenter?.id);
      setIsOpen(false);
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while deleting cost center');
      }
    }
  };

  return (
    <CustomModal
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.SIMPLE}
      footerDisabled={saveClicked}
      onSave={onDelete}
      open={isOpen}
      automationId="delete-cost-center-modal"
      saveTitle="Delete"
      saveWarning
      saveDisabled={false}
      title={COST_CENTER_ACTIONS.DELETE.label}
    >
      <div className="d-flex flex-column gap-3">
        <div>You are about to delete {costCenter.name}. This action cannot be undone.</div>
        <div> Are you sure?</div>
      </div>
    </CustomModal>
  );
};

DeleteCostCenterModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  costCenter: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default DeleteCostCenterModal;
