import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const CustomersContext = createContext();

export const CustomersProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    mode: 'customers',
    linkedAccounts: [],
  });

  const [tableFilters, setTableFilters] = useState();

  const [showFilters, setShowFilters] = useState(false);

  const [isAllExpanded, setIsAllExpanded] = useState(false);

  return (
    <CustomersContext.Provider
      value={{
        isAllExpanded,
        setIsAllExpanded,
        showFilters,
        setShowFilters,
        setTableFilters,
        tableFilters,
        filters,
        setFilters,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};

CustomersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCustomersContext = () => useContext(CustomersContext);
