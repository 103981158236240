import React from 'react';
import PropTypes from 'prop-types';
import {
  RowDetailState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
  ColumnChooser,
  TableFilterRow,
  TableColumnResizing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { Col, Row } from 'reactstrap';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import PencilIcon from 'shared/img/icons/PencilIcon';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import getDisplayNameByDivisionName from 'divisions/divisionsHelpers';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { isEmptyArray } from '../../utils/arrayUtils';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

class AwsResellerPricingTable extends React.Component {
  static propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    deleteHandler: PropTypes.object.isRequired,
    editHandler: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      columnWidths: props.columnWidths,
      tableColumnExtensions: props.tableColumnExtensions,
      hiddenColumnNames: props.defaultHiddenColumnNames,
      filteringStateColumnExtensions: [{ columnName: 'userAction', filteringEnabled: false }],
    };
  }

  userActionsFormatter = (data) => (
    <i>
      <Row>
        <Col>
          <ReadOnlyDisplayWrapper
            isHide={false}
            category={OrganizationEntityCategory.ResellerCustomers}
            action={Action.Update}
          >
            <LinkIconButton containerStyle={buttonContainerStyle}>
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={(e) => this.props.deleteHandler(e, data.row.id, data.row.name, data.row.accountId)}
              />
              <Delete />
            </LinkIconButton>
          </ReadOnlyDisplayWrapper>
        </Col>
        <Col>
          <LinkIconButton containerStyle={buttonContainerStyle}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.editHandler(data.row);
              }}
            />
            <PencilIcon />
          </LinkIconButton>
        </Col>
      </Row>
    </i>
  );
  RowDetail = ({ row }) => {
    const {
      services,
      regions,
      scopes,
      marginType,
      includeOrExcludeServices,
      excludeService,
      customService,
      isLinkedAccountsChecked,
      cloudFrontRegions,
      dedicatedParams,
      operations,
      costTypes,
    } = row;
    const getStringValues = (values) => {
      let text = '';
      if (typeof values === 'string') {
        const lower = values.toLowerCase();
        const firstChar = values.charAt(0).toUpperCase();
        text = `${firstChar}${lower.slice(1, values.length)}`;
      }
      if (Array.isArray(values)) {
        text = values.join(', ');
      }
      return text;
    };
    const getCustomerStringValues = (scopeVal) => {
      let text = '';
      if (typeof scopeVal === 'string') {
        const lower = scopeVal.toLowerCase();
        const firstChar = scopeVal.charAt(0).toUpperCase();
        text = `${firstChar}${lower.slice(1, scopeVal.length)}`;
      }
      if (Array.isArray(scopeVal)) {
        const arryOftextValues = scopeVal.map((sv) => {
          const name = getDisplayNameByDivisionName(sv.customer) || sv.customer;
          const ids =
            sv.linkedAccountIds && Array.isArray(sv.linkedAccountIds) ? `(${sv.linkedAccountIds.join(', ')})` : '';
          const textVal = `${name} ${ids}`;
          return textVal;
        });
        text = arryOftextValues.join(', ');
      }
      return text;
    };
    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Row>
          <p style={{ padding: '3px', margin: '2px 0px' }}>
            <b>{isLinkedAccountsChecked ? 'Linked Accounts: ' : 'Customers: '}</b>
            <span>{getCustomerStringValues(scopes)}</span>
          </p>
        </Row>
        <Row>
          <p style={{ padding: '3px', margin: '2px 0px' }}>
            <b>Services: </b>
            <span>{getStringValues(customService || services)}</span>
          </p>
        </Row>
        {marginType === 'custom-service-calc-cost' && (
          <Row>
            <p style={{ padding: '3px', margin: '2px 0px' }}>
              <b>{excludeService ? 'Excluded services: ' : 'Included services: '}</b>
              <span>{getStringValues(includeOrExcludeServices)}</span>
            </p>
          </Row>
        )}
        <Row>
          <p style={{ padding: '3px', margin: '2px 0px' }}>
            <b>{regions.length ? 'Regions: ' : 'Cloudfront Regions: '}</b>
            <span>{getStringValues(regions.length ? regions : cloudFrontRegions)}</span>
          </p>
        </Row>
        <Row>
          <p style={{ padding: '3px', margin: '2px 0px' }}>
            <b>AWS Marketplace Excluded: </b>
            <span>{dedicatedParams.isExcludeAwsMarketplace ? 'Yes' : 'No'}</span>
          </p>
        </Row>
        {operations && (
          <Row>
            <p style={{ padding: '3px', margin: '2px 0px' }}>
              <b>Operations: </b>
              <span>{operations.join(', ')}</span>
            </p>
          </Row>
        )}
        {costTypes && (
          <Row>
            <p style={{ padding: '3px', margin: '2px 0px' }}>
              <b>Cost Types: </b>
              <span>{costTypes.join(', ')}</span>
            </p>
          </Row>
        )}
      </Col>
    );
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  preparePricingTableData = (data) => {
    if (!data || !data.length) {
      return [];
    }
    const preparedData = data.map((item) => {
      const { service } = item;
      const prepService = typeof service === 'object' ? service.label : service;
      return { ...item, service: prepService };
    });
    return preparedData;
  };
  render() {
    const { rows, defaultSorting } = this.props;
    const { columns, columnWidths, tableColumnExtensions, hiddenColumnNames, filteringStateColumnExtensions } =
      this.state;
    const prepRows = this.preparePricingTableData(rows);
    return (
      <div style={{ width: '100%' }} ref={this.refCallback}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <div className="page-wrapper">
            <Grid rows={prepRows} columns={columns}>
              <RowDetailState />
              <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
              <IntegratedFiltering />
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting />
              <DataTypeProvider for={['userAction']} formatterComponent={this.userActionsFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableRowDetail contentComponent={this.RowDetail} />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </div>
        </Col>
      </div>
    );
  }
}

export default AwsResellerPricingTable;
