import React from 'react';
import PropType from 'prop-types';
import { ReactComponent as NoUsers } from 'users/containers/Organization/Users/img/no-users.svg';
import classNames from 'classnames';

import styles from './UsersNoData.module.scss';

const UsersNoData = ({ colSpan, rowSpan, isSmall }) => {
  return (
    <td colSpan={colSpan} rowSpan={rowSpan} className="border-bottom-0 border-start-0 border-end-0">
      <div className={classNames(styles.usersEmptyState, { [styles.small]: isSmall })}>
        <NoUsers className={styles.svgImage} />
        <div className={styles.emptyStateText}>
          <span className={styles.mainText}>No users found</span>
          <span className={styles.text}>Refine your filters to find the user you are looking for</span>
        </div>
      </div>
    </td>
  );
};

UsersNoData.propTypes = {
  colSpan: PropType.number,
  isSmall: PropType.bool,
  rowSpan: PropType.number,
};

export default UsersNoData;
