import React from 'react';
import PropTypes from 'prop-types';
import { Y_AXIS_UNIT } from 'recommendationsNew/consts';
import { AZURE_PROPERTIES, BASE_PROPERTIES, COST_PROPERTIES } from '../../recommendationPropertyUtils';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';

const AZURE_VM_IDLE_PROPERTIES = {
  MODEL: {
    label: 'Model',
    getterFunction: (recommendation) => recommendation?.recData?.instance_type_model,
  },
};
const AzureVmIdle = ({ recommendation }) => {
  const properties = {
    ...BASE_PROPERTIES,
    ...COST_PROPERTIES,
    ...AZURE_PROPERTIES,
    ...AZURE_VM_IDLE_PROPERTIES,
  };

  const chartsData = [
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'Network Performance',
      yAxisLabel: 'Network I/O (MB)',
      yAxisUnit: Y_AXIS_UNIT.BYTE,
      linesList: [
        {
          id: 'networkOut',
          label: 'Network Out',
          data: recommendation.recData?.network_out_statistics_usage,
        },
        {
          id: 'networkIn',
          label: 'Network In',
          data: recommendation.recData?.network_in_statistics_usage,
        },
      ],
      referenceLines: [
        {
          yAxisValue: recommendation?.recData?.max_network,
          label: `Low Network Performance Benchmark ${recommendation?.recData?.max_network} MB`,
        },
      ],
    },
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'CPU Performance',
      yAxisLabel: 'CPU',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      linesList: [
        {
          id: 'cpu',
          label: 'CPU',
          data: recommendation.recData?.cpu_util_statistics_usage,
        },
      ],
      referenceLines: [
        {
          yAxisValue: recommendation?.recData?.max_cpu_util,
          label: `Max CPU Utilization Benchmark ${recommendation?.recData?.max_cpu_util}%`,
        },
      ],
    },
  ];

  const chartsComponent = <RecommendationChartData chartsData={chartsData} />;

  const commandsList = [
    {
      type: COMMAND_TYPES.POWERSHELL,
      instructions: [
        {
          actionText: '',
          actionCommand: `Remove-AzureRmResource -ResourceId "${recommendation?.resourceId}"`,
        },
      ],
    },
  ];

  const recommendationCommandComponent = <RecommendationCommand commandsList={commandsList} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const description =
    'This VM instance has a low CPU, the daily average CPU is less than 1% and network in/out is lower than 10MB.';
  const descriptionComponent = <RecommendationInsideLook description={description} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      details={detailsComponent}
      preferences={preferencesComponent}
      insideLook={descriptionComponent}
      charts={chartsComponent}
      command={recommendationCommandComponent}
    />
  );
};

AzureVmIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureVmIdle;
