import React from 'react';
import PropTypes from 'prop-types';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { PieChart, Pie, Label } from 'recharts';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './spPieChart.module.scss';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import { ReactComponent as Bolt } from '../img/bolt.svg';
const getEsrTooltip = () => (
  <div className={styles.tooltipWrapper}>
    <h2 className={styles.title}>ESR</h2>
    <span className={styles.description}>
      Effective Savings Rate (ESR) is a metric that measures the actual ROI of your cloud discount instruments.
    </span>
    <div className={styles.calculateFormula}>
      <span className={styles.effectiveTxt}>Effective Saving Rate (ESR) = </span>
      <span className={styles.parentheses}>
        [ <span className={styles.num}> 1 - </span> ({' '}
      </span>
      <div className={styles.flexColumn}>
        <span className={styles.actualTxt}>Actual Spend with Discounts</span>
        <span className={styles.omDemandTxt}>On-Demand Equivalent (ODE) Spend</span>
      </div>
      <span className={styles.parentheses}>
        )] <span className={styles.num}> * 100</span>
      </span>
    </div>
  </div>
);

const SpPieChart = ({ percentValue }) => {
  const data = [
    { value: percentValue * 100 > 0 ? Number(percentValue) : 0, fill: '#B9E3CD' },
    {
      value: 50 - Number(percentValue),
      fill: '#E9E9F0',
    },
  ];
  return (
    <div className={styles.chartWrapper}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>ESR</span>
        <Tooltip
          placement="bottom"
          title={getEsrTooltip()}
          arrow
          classes={{
            tooltip: `${tooltipStyles.whiteTooltip} ${styles.tooltip}`,
            arrow: tooltipStyles.whiteArrow,
          }}
        >
          <div>
            <GenerateIcon iconName={ICONS.circleQuestionRegular.name} className={styles.grayIcon} />
          </div>
        </Tooltip>
      </div>

      <PieChart height={60} width={125}>

        <Pie
          data={data}
          cx={55}
          cy={55}
          startAngle={180}
          endAngle={0}
          innerRadius={50}
          outerRadius={60}
          stroke="none"
          isAnimationActive={false}
          dataKey="value"
        >
          {percentValue && percentValue >= 0 &&  (<Label
              content={<Bolt x={50} y={22} className={styles.bolt} />}
              position="center"
          />)}
        </Pie>
        {percentValue >= 0 ? (
          <text x={60} y={55} textAnchor="middle" dominantBaseline="middle" className={styles.percentValue}>
            {percentValue} %
          </text>
        ) : (
          <text
            x={60}
            y={35}
            textAnchor="middle"
            dominantBaseline="middle"
            className={`${styles.percentValue} ${styles.emptyValue}`}
          >
            <>
              <tspan x={60} dy="0">
                Not
              </tspan>
              <tspan x={60} dy="15">
                Profitable
              </tspan>
            </>
          </text>
        )}
      </PieChart>
    </div>
  );
};

SpPieChart.propTypes = {
    percentValue: PropTypes.number.isRequired,
};
export default SpPieChart;
