import React from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import TriggerRebilling from 'divisions/containers/ManageCustomers/components/TriggerRebilling/TriggerRebilling.jsx';
import { PageNames } from 'shared/constants/appConstants';
import {
  useMspCustomers,
} from 'users/new-user-management/hooks/reactQuery/useMspCustomers';

const TriggeredRebilling = () => {
  const { fetchCurrAccountCustomersData } = useMspCustomers();
  const { data: customersData = [] } = fetchCurrAccountCustomersData();
  return (
    <Container>
      <PageHeader title={PageNames.TRIGGER_REBILLING} />
      <TriggerRebilling
        customers={customersData?.map(c => ({
          name: c.name,
          id: c.id,
          createdDate: c.dbCreationTime,
        }))}
      />
    </Container>
  );
};

TriggeredRebilling.propTypes = {};

export default TriggeredRebilling;
