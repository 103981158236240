import { TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import React, { useRef, useState } from 'react';
import { ReactComponent as NotContains } from 'shared/img/icons/not-contain.svg';
import { ReactComponent as Contains } from 'shared/img/icons/contains.svg';
import { ReactComponent as Equal } from 'shared/img/icons/equal.svg';
import { ReactComponent as NotEqual } from 'shared/img/icons/not-equal.svg';
import { ReactComponent as GreaterThan } from 'shared/img/icons/greater-than.svg';
import { ReactComponent as LessThan } from 'shared/img/icons/less-than.svg';
import { ReactComponent as GreaterThanOrEqual } from 'shared/img/icons/greater-than-or-equal.svg';
import classNames from 'classnames';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { FILTER_OPERATIONS } from './const';

import styles from './TableComponents.module.scss';

export const CustomFilterIcon = ({ type }) => {
  switch (type) {
    case FILTER_OPERATIONS.CONTAINS:
      return <Contains />;
    case FILTER_OPERATIONS.NOT_CONTAINS:
      return <NotContains />;
    case FILTER_OPERATIONS.EQUAL:
      return <Equal />;
    case FILTER_OPERATIONS.NOT_EQUAL:
      return <NotEqual />;
    case FILTER_OPERATIONS.GREATER_THAN:
      return <GreaterThan />;
    case FILTER_OPERATIONS.LESS_THAN:
      return <LessThan />;
    case FILTER_OPERATIONS.GREATER_THAN_OR_EQUAL:
      return <GreaterThanOrEqual />;
    default:
      return <Contains />;
  }
};

export const CustomHeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;

  return (
    <TableHeaderRow.Cell {...restProps} column={column}>
      <Tooltip title={column.title} disabled={!column?.title}>
        <span className={styles.headerList}>{column?.title ? children : null}</span>
      </Tooltip>
    </TableHeaderRow.Cell>
  );
};

export const CustomFilterRow = ({ children, ...restProps }) => (
  <TableFilterRow.Row {...restProps} className={`filterRow ${styles.filterRow}`}>
    {children}
  </TableFilterRow.Row>
);

export const CustomFormatterWithTooltip = ({ value, leftPadding, isSmallText = false, overrideStyles, className }) => (
  <div className={classNames('overflow-x-hidden', { 'ps-1': leftPadding })}>
    <Tooltip title={value}>
      <div
        className={classNames({ [styles.smallerColumnTitle]: isSmallText }, 'text-truncate', className)}
        style={{ ...overrideStyles }}
      >
        {value}
      </div>
    </Tooltip>
  </div>
);

export const CustomFormatterWithTooltipOnlyIfLongText = ({
  value,
  leftPadding,
  isSmallText = false,
  overrideStyles,
  className,
}) => {
  const textRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (textRef.current.scrollWidth > textRef.current.clientWidth) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={classNames('overflow-x-hidden', { 'ps-1': leftPadding })}>
      <Tooltip open={showTooltip} title={value}>
        <div
          className={classNames({ [styles.smallerColumnTitle]: isSmallText }, 'text-truncate', className)}
          style={{ ...overrideStyles }}
          ref={textRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {value}
        </div>
      </Tooltip>
    </div>
  );
};

export const CustomClickableFormatter = ({ value, onClick }) => (
  <div onClick={onClick}>
    <CustomFormatterWithTooltipOnlyIfLongText value={value} className={styles.clickableValue} />
  </div>
);

export const CustomFormatterWithTooltipAndComponent = ({ children, ...props }) => (
  <div
    className={classNames(
      'd-flex align-items-center',
      { 'gap-2': props.isLeftComponent },
      { 'justify-content-between': !props.isLeftComponent },
      { 'pe-4': !props.isLeftComponent },
    )}
  >
    {props.isLeftComponent && children ? <>{children}</> : null}
    <CustomFormatterWithTooltipOnlyIfLongText {...props} />
    {!props.isLeftComponent && children ? <>{children}</> : null}
  </div>
);

export const CustomSelectionCell = (props) => {
  const { row, isNotAvailable, isDisabled, ...restProps } = props;

  const onToggle = () => {
    if (!isDisabled) {
      restProps.onToggle();
    }
  };

  const updatedRestProps = { ...restProps, onToggle };

  return (
    <TableSelection.Cell
      {...updatedRestProps}
      automation-id="select-rec-column"
      className={classNames(
        { [styles.selectionBoxHidden]: isNotAvailable || row?.isNotAvailable },
        { [styles.selectionBoxDisabled]: isDisabled },
        { [styles.selectedAndDisabled]: restProps?.selected },
      )}
      row={row}
    />
  );
};

export const CustomFilterCell = ({ column, actionColumns, ...restProps }) => {
  if ([...actionColumns].includes(column.name)) {
    return <TableFilterRow.Cell {...restProps}> </TableFilterRow.Cell>;
  }
  return <TableFilterRow.Cell {...restProps} className="filterPlaceHolder" automation-id="input-filter" />;
};
