import React, { useMemo, useState } from 'react';
import CustomModal from 'shared/components/andtComponents/Modal';
import { cloneDeep, groupBy } from 'lodash';
import toast from 'shared/components/andtComponents/Toast';

import styles from './EditCustomerModal.module.scss';
import { useMspCustomers } from 'users/new-user-management/hooks/reactQuery/useMspCustomers.js';
import Input from 'shared/components/andtComponents/Input.jsx';
import {
  CUSTOMER_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS,
  CUSTOMER_ADD_REMOVE_PAYER_ACCOUNTS_COLUMNS,
} from 'users/containers/Organization/consts.js';
import LinkedAccountsComponentWrapper
  from 'users/containers/Organization/components/LinkedAccounts/LinkedAccountsComponentWrapper.jsx';
import {
  getInitialAccountsDataWithCounters, provideAccountsData,
} from 'users/containers/Organization/components/LinkedAccounts/dataAccessHelperFunctions.js';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts.js';
import Spinner from 'shared/components/andtComponents/Spinner.jsx';
import { getLinkedAccountName } from 'shared/utils/cloudUtils.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';

const EditCustomerModal = ({ isOpen, setIsOpen, customer }) => {
  const [data, setData] = useState(cloneDeep(customer) || {});
  const editMode = !!customer;
  const { usersStore } = useRootStore();
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const { updateCustomer, createCustomer } = useMspCustomers();
  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts(!isOpen);
  const { fetchCustomerAccounts, fetchAvailableLinkedAccounts } = useMspCustomers();
  const { data: accountsData, isLoading } = fetchDataAccessPayerAccounts();
  const { data: accounts, isLoading: customerLoading } = fetchCustomerAccounts(customer);
  const { data: availableLinkedAccounts, isLoading: availableLinkedAccountsLoading } = fetchAvailableLinkedAccounts();
  const accountsToDisplay = useMemo(() => {
    if (!accountsData || availableLinkedAccountsLoading || customerLoading) {
      return null;
    }
    const accountsDataCopy = cloneDeep(accountsData);
    const combinedData = getInitialAccountsDataWithCounters(accountsDataCopy);
    const combinedPayerAccountsData = provideAccountsData({
      accountsData: accountsDataCopy,
      roleDataAccess: [],
      currentRoleId: null,
      isCreateEditMode: true,
      entity: 'customer',
    });
    combinedPayerAccountsData.forEach(payer => {
      const availableLns = (availableLinkedAccounts.find(a => a.payerAccountId === payer.payerAccount.id)?.linkedAccountIds || []).filter(Boolean);
      const dedicated = accounts?.fullyDedicatedPayerAccounts?.some(a => a.payerAccountId === payer.payerAccount.id);
      if (dedicated) {
        payer.isSelectedInitial = true;
      }
      payer.payerAccount.isNotAvailable = true;
      payer.linkedAccounts.forEach(ln => {
        const linkedAccount = accounts?.linked_accounts?.some(l => l.linked_account.id === ln.id);
        if (!availableLns.includes(ln.id) && !linkedAccount) {
          ln.isNotAvailable = true;
        }
        if (linkedAccount) {
          ln.isSelectedInitial = true;
        }
      });
    });
    return {
      ...combinedData,
      accountsData: combinedPayerAccountsData,
    };
  }, [accountsData, data?.accounts, availableLinkedAccounts, customerLoading]);
  const onSave = async () => {
    if (!data.name) {
      toast.error('Please enter customer name');
      return;
    }
    data.accounts = linkedAccounts;
    try {
      if (editMode) {
        await updateCustomer.mutateAsync(data);
      } else {
        await createCustomer.mutateAsync(data);
      }
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) {
        toast.error('Error while saving customer');
      }
    }
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      headerMode={editMode ? 'edit' : 'add'}
      onSave={onSave}
      overrideStyles={{ width: 1200 }}
      open={isOpen}
      automationId={`${editMode ? 'edit' : 'create'}-role-modal`}
      saveDisabled={updateCustomer.isLoading || createCustomer.isLoading}
      saveTitle="Save changes"
      title={`${editMode ? 'Edit' : 'Create'} Customer`}
    >
      {isLoading || customerLoading || availableLinkedAccountsLoading ? (
        <div style={{ height: 400 }}><Spinner /></div>
      ) : (
        <div className={styles.container}>
          <div className={styles.properties}>
            <div className={styles.propertyContainer}>
              <span className={styles.label}>Customer Name</span>
              <Input
                value={data?.name}
                placeholder="Enter Name"
                onChange={(e) => {
                  setData({ ...data, name: e?.target?.value });
                }}
              />
            </div>
            <div className={styles.propertyContainer}>
            <span className={styles.label}>
              Customer Code
            </span>
              <Input
                value={data?.code}
                placeholder="Enter Code"
                onChange={(e) => {
                  setData({ ...data, code: e?.target?.value });
                }}
              />
            </div>
          </div>
          <LinkedAccountsComponentWrapper
            additionalData={{ customerId: data?.id }}
            allAccountsData={accountsToDisplay}
            entity="customer"
            isCreateEditMode
            onSelectionChange={setLinkedAccounts}
            linkedAccountsColumns={[
              CUSTOMER_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS.LINKED_ACCOUNT.columnName,
              CUSTOMER_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS.ASSIGNED_BY.columnName,
            ]}
            onRemoveLinkedAccount={() => {
            }}
            payerAccountsColumns={[CUSTOMER_ADD_REMOVE_PAYER_ACCOUNTS_COLUMNS.PAYER_ACCOUNT.columnName]}
          />
        </div>
      )}
    </CustomModal>
  );
};

export default EditCustomerModal;
