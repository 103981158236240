import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import {
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  FilteringState,
  SortingState,
  SummaryState,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnVisibility,
  TableHeaderRow,
  TableFilterRow,
  TableFixedColumns,
  TableColumnResizing,
  Toolbar,
  ColumnChooser,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { getFormatterComponent, getNumToSizeFormatterComponent } from 'shared/utils/formatUtils';
import { AwsQuantityTypes, AWS_QUANTITY_TYPE_SELECT } from 'shared/constants/awsConstants';
import { useUserSettingsContext, withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { trendLinesRowsSort } from 'shared/utils/sortUtil';

const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;
FilterIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

const getCellColor = (value, currencySymbol) => {
  try {
    const valueAsNumber = value
      .split('(')[0]
      .replace(currencySymbol, '')
      .replace('K', '')
      .replace('M', '')
      .replace(' ', '')
      .split('\n')[0];
    let color = 'white';
    if (Number.isNaN(valueAsNumber)) {
      color = 'white';
    } else if (valueAsNumber > 0) {
      color = '#d99694';
    } else if (valueAsNumber <= 0) {
      color = '#c3d69b';
    }
    return color;
  } catch {
    return 'white';
  }
};
const TrendCell = ({ value, style, selectedUsageType, isUsageChecked, ...restProps }) => {
  const { currencySymbol, numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
  function getTrendDisplayValue(trendValue) {
    if (typeof trendValue === 'string') {
      return trendValue;
    }
    const { percent = '-' } = trendValue || {};
    return `${numStrAbriviaionByDisplayMetric(
      value.cost,
      value.cost,
      selectedUsageType === AWS_QUANTITY_TYPE_SELECT ? 'Cost' : selectedUsageType,
      null,
    )}
            ${typeof value.cost === 'string' ? value.cost.split(' ')[1] : ''}
             ${typeof percent === 'number' ? `(${percent.toFixed(2)}%)` : percent}`;
  }
  const displayValue = getTrendDisplayValue(value);
  return (
    <Table.Cell
      {...restProps}
      style={{
        backgroundColor: getCellColor(displayValue, currencySymbol),
        ...style,
      }}
    >
      <span>
        {displayValue?.split('\n')[0] === ' 0' || !isUsageChecked ? displayValue.replace('Bytes', ' ') : displayValue}
      </span>
    </Table.Cell>
  );
};
TrendCell.propTypes = {
  value: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  selectedUsageType: PropTypes.string.isRequired,
  isUsageChecked: PropTypes.bool.isRequired,
};

const Cell = (props) => {
  const { row, selectedUsageType, isUsageChecked } = props;
  try {
    if (row.groupBy && row.groupBy.includes('Trend')) {
      return <TrendCell {...props} selectedUsageType={selectedUsageType} isUsageChecked={isUsageChecked} />;
    }
    return <Table.Cell {...props} />;
  } catch {
    return <Table.Cell {...props} />;
  }
};

class FilterTable extends Component {
  messages = {
    sum: ' ',
  };
  static propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    renderedColumnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    displayedMetric: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    handleTableColumnWidthChange: PropTypes.object.isRequired,
    totalSummaryItems: PropTypes.array.isRequired,
    setSelectedPageSize: PropTypes.func.isRequired,
    selectedUsageType: PropTypes.string.isRequired,
    isUsageChecked: PropTypes.bool,
    isShowSummaryRow: PropTypes.bool,
    cloudTypeId: PropTypes.number.isRequired,
    isTableTrendRow: PropTypes.bool,
    isTableOnlyTrendRow: PropTypes.bool,
    tableId: PropTypes.string,
    isGranLevelWeekly: PropTypes.bool,
    isGroupByDate: PropTypes.bool,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isShowSummaryRow: true,
    isTableTrendRow: false,
    isTableOnlyTrendRow: false,
    isUsageChecked: false,
    tableId: null,
    isGranLevelWeekly: false,
    isGroupByDate: false,
  };

  constructor(props) {
    super(props);
    const { totalSummaryItems } = this.props;
    this.state = {
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      leftColumns: ['date'],
      totalSummaryItems,
    };
  }

  componentDidMount() {
    const { setSelectedPageSize } = this.props;
    if (setSelectedPageSize) {
      setSelectedPageSize(15);
    }
  }
  changeColumnWidths = (columnWidths) => {
    const { handleTableColumnWidthChange } = this.props;
    handleTableColumnWidthChange([...columnWidths]);
  };

  formatDates = (dateObj) => {
    const { name: date } = dateObj.props.column;
    const [, week, year] = date.split('-');
    if (!year) {
      return {};
    }
    const weekDateStart = moment()
      .day('Monday')
      .year(year)
      .isoWeek(+week)
      .format('MM-DD');
    const weekDateEnd = moment()
      .day('Sunday')
      .year(year)
      .isoWeek(+week)
      .format('MM-DD');
    return { weekDateStart, weekDateEnd };
  };

  tableHeaderContent = ({ column, children, ...restProps }) => {
    const { isGranLevelWeekly, isGroupByDate } = this.props;
    if (isGroupByDate && isGranLevelWeekly && column.name !== CostTrackingConstants.DATA_LEVEL_TYPE_GROUP_BY) {
      const { weekDateStart, weekDateEnd } = this.formatDates(children);
      return (
        <TableHeaderRow.Content column={column} {...restProps}>
          <div>
            <div>{children}</div>
            {weekDateStart || weekDateEnd ? (
              <p className="cue-table-weekly-date-header">{`${weekDateStart} - ${weekDateEnd}`}</p>
            ) : null}
          </div>
        </TableHeaderRow.Content>
      );
    }
    return (
      <TableHeaderRow.Content column={column} {...restProps}>
        {children}
      </TableHeaderRow.Content>
    );
  };

  sorting = this.props.columns.map((col) => {
    const extension = {
      columnName: col.name,
    };
    if (col.name !== 'groupBy') {
      extension.compare = trendLinesRowsSort;
    }
    return extension;
  });

  render() {
    const {
      rows,
      columns,
      renderedColumnWidths: columnWidths,
      tableColumnExtensions,
      defaultHiddenColumnNames: hiddenColumnNames,
      isTableTrendRow,
      isShowSummaryRow,
      isUsageChecked,
      selectedUsageType,
      cloudTypeId,
      tableId,
      displayedMetric,
      getCurrencyNumber,
    } = this.props;
    const arrOfColumnNames = columns
      .map((col) => col.name)
      .filter(
        (name) =>
          name !== 'groupBy' && name !== 'linkedAccountId' && name !== 'resourceName' && name !== 'instanceType',
      );
    const { leftColumns, numericFilterOperations, totalSummaryItems } = this.state;
    const isTableSummaryRow = isShowSummaryRow && !isTableTrendRow;
    return (
      <div style={{ width: '100%' }} ref={this.refCallback} id={tableId}>
        <Col xs={12} md={12} lg={12} xl={12} style={{ marginTop: '5px' }}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <FilteringState defaultFilters={[{}]} />
              <IntegratedFiltering />
              <SortingState
                defaultSorting={[
                  { columnName: 'totalCost', direction: 'desc' },
                  { columnName: 'totalUsage', direction: 'desc' },
                ]}
              />
              <IntegratedSorting columnExtensions={this.sorting} />

              {isTableSummaryRow ? <SummaryState totalItems={totalSummaryItems} /> : null}
              {isTableSummaryRow ? <IntegratedSummary /> : null}
              <DataTypeProvider
                for={arrOfColumnNames}
                formatterComponent={
                  selectedUsageType === AwsQuantityTypes.BYTES && isUsageChecked
                    ? (value) => getNumToSizeFormatterComponent(value, cloudTypeId)
                    : getFormatterComponent(isUsageChecked, selectedUsageType, displayedMetric, getCurrencyNumber)
                }
                availableFilterOperations={numericFilterOperations}
              />
              <TableWrapper
                cellComponent={(props) => Cell({ ...props, selectedUsageType, isUsageChecked })}
                columnExtensions={tableColumnExtensions}
                virtual
                height={540}
              />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              {isTableSummaryRow ? <TableSummaryRow messages={this.messages} /> : null}
              <TableHeaderRow contentComponent={this.tableHeaderContent} showSortingControls />
              <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
              <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
              <Toolbar />
              <ColumnChooser />
              <TableFixedColumns leftColumns={leftColumns} />
            </Grid>
          </Paper>
        </Col>
      </div>
    );
  }
}

const ObserverFilterTable = withUserSettingsConsumer(FilterTable);
export default ObserverFilterTable;
