import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import React from 'react';

import styles from './CostCenters.module.scss';

const CostCentersIcon = () => (
  <div className={styles.costCentersIcon}>
    <GenerateIcon iconName={ICONS.coins.name} className={styles.icon} />
  </div>
);

export default CostCentersIcon;
