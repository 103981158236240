import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import {
  awsCommonFieldToDisplayField,
  AwsCommonFields,
  mapRegionNamesToDescription,
} from 'shared/constants/awsConstants';
import { USER_TYPES_VALS } from 'users/constants/usersConstants';
import getDisplayNameByDivisionName from 'divisions/divisionsHelpers';
import { propComparator } from 'shared/utils/sortUtil';
import { validateRecipients } from 'shared/utils/sharedUtils';
import { ALERT_RULE_ERROR_MESSAGES, FILTER_PARAMS_KEYS } from './anomalyDetectionConstants';

export const initiateAlertRule = (selectedAlertRule, { divisions = [], services = [], linkedAccounts = [], accountTags= [] }) => {
  if (selectedAlertRule) {
    const {
      uuid,
      ruleName,
      direction,
      minAmountChange,
      minPercentChange,
      minAmountDailyChange,
      minPercentDailyChange,
      filters: { include, exclude },
      email,
      recipients,
      creationDate,
    } = selectedAlertRule;
    return {
      uuid,
      ruleName,
      direction,
      minAmountChange,
      minPercentChange,
      minAmountDailyChange,
      minPercentDailyChange,
      filters: {
        [AwsCommonFields.SERVICE]: {
          relation: exclude[AwsCommonFields.SERVICE].length
            ? { label: 'Exclude', value: 'exclude' }
            : { label: 'Include', value: 'include' },
          values: (exclude[AwsCommonFields.SERVICE].length
            ? exclude[AwsCommonFields.SERVICE]
            : include[AwsCommonFields.SERVICE] || []
          ).map((service) => services.find(({ value }) => service === value) || { label: service, value: service }),
        },
        [AwsCommonFields.DIVISION]: {
          relation: exclude[AwsCommonFields.DIVISION].length
            ? { label: 'Exclude', value: 'exclude' }
            : { label: 'Include', value: 'include' },
          values: (exclude[AwsCommonFields.DIVISION].length
            ? exclude[AwsCommonFields.DIVISION]
            : include[AwsCommonFields.DIVISION] || []
          ).map(
            (division) => divisions.find(({ value }) => division === value) || { label: division, value: division },
          ),
        },
        [AwsCommonFields.LINKED_ACCOUNT_ID]: {
          relation: exclude[AwsCommonFields.LINKED_ACCOUNT_ID].length
            ? { label: 'Exclude', value: 'exclude' }
            : { label: 'Include', value: 'include' },
          values: (exclude[AwsCommonFields.LINKED_ACCOUNT_ID].length
            ? exclude[AwsCommonFields.LINKED_ACCOUNT_ID]
            : include[AwsCommonFields.LINKED_ACCOUNT_ID] || []
          ).map(
            (linkedAccount) =>
              linkedAccounts.find(({ value }) => linkedAccount === value) || {
                label: linkedAccount,
                value: linkedAccount,
              },
          ),
        },
        [AwsCommonFields.ACCOUNT_TAGS]: {
          relation: exclude[AwsCommonFields.ACCOUNT_TAGS]?.length
            ? { label: 'Exclude', value: 'exclude' }
            : { label: 'Include', value: 'include' },
          values: (exclude[AwsCommonFields.ACCOUNT_TAGS]?.length
            ? exclude[AwsCommonFields.ACCOUNT_TAGS]
            : include[AwsCommonFields.ACCOUNT_TAGS] || []
          ).map(
            (tag) =>
              accountTags.find(({ value }) => tag === value) || {
                label: tag,
                value: tag,
              },
          ),
        },
      },
      email,
      recipients,
      creationDate: moment(creationDate).format('YYYY-MM-DD'),
    };
  }
  return {
    uuid: v4(),
    ruleName: '',
    direction: 'UP',
    minAmountChange: 50,
    minPercentChange: 0,
    filters: {
      [AwsCommonFields.SERVICE]: {
        relation: { label: 'Include', value: 'include' },
        values: [],
      },
      [AwsCommonFields.DIVISION]: {
        relation: { label: 'Include', value: 'include' },
        values: [],
      },
      [AwsCommonFields.LINKED_ACCOUNT_ID]: {
        relation: { label: 'Include', value: 'include' },
        values: [],
      },
      [AwsCommonFields.ACCOUNT_TAGS]: {
        relation: { label: 'Include', value: 'include' },
        values: [],
      },
    },
    email: '',
    creationDate: moment().format('YYYY-MM-DD'),
  };
};

export const validateAlertRule = (alertRuleParams, alertRuleNames = []) => {
  const {
    ruleName,
    direction,
    minPercentChange,
    minAmountChange,
    filters: {
      [AwsCommonFields.SERVICE]: service,
      [AwsCommonFields.LINKED_ACCOUNT_ID]: linkedaccid,
      [AwsCommonFields.DIVISION]: division,
      [AwsCommonFields.ACCOUNT_TAGS]: accounttags,
    } = {},
    email,
    recipients,
  } = alertRuleParams || {};
  const alertValidationErrors = {};
  if (!alertRuleParams || !ruleName) {
    alertValidationErrors.ruleName = ALERT_RULE_ERROR_MESSAGES.EMPTY_RULE_NAME;
  } else if (alertRuleNames.includes(ruleName)) {
    alertValidationErrors.ruleName = ALERT_RULE_ERROR_MESSAGES.RULE_NAME_EXISTS;
  } else if (typeof ruleName !== 'string') {
    alertValidationErrors.ruleName = ALERT_RULE_ERROR_MESSAGES.INVALID_RULE_NAME;
  }
  if (!alertRuleParams || (!+minPercentChange && !+minAmountChange)) {
    alertValidationErrors.minImpact = ALERT_RULE_ERROR_MESSAGES.NO_MIN_IMPACT;
  } else if (Number.isNaN(+minPercentChange) || Number.isNaN(+minAmountChange)) {
    alertValidationErrors.minImpact = ALERT_RULE_ERROR_MESSAGES.INVALID_MIN_IMPACT;
  } else if (+minPercentChange < 0 || +minAmountChange < 50) {
    alertValidationErrors.minImpact = ALERT_RULE_ERROR_MESSAGES.NEGATIVE_MIN_IMPACT;
  }
  if (!alertRuleParams || !direction) {
    alertValidationErrors.direction = ALERT_RULE_ERROR_MESSAGES.NO_DIRECTION;
  } else if (!['UP', 'DOWN'].includes(direction)) {
    alertValidationErrors.direction = ALERT_RULE_ERROR_MESSAGES.INVALID_DIRECTION;
  }
  if (!alertRuleParams || !service || !service.relation || !service.values) {
    alertValidationErrors[AwsCommonFields.SERVICE] = ALERT_RULE_ERROR_MESSAGES.INVALID_SERVICE_FILTER;
  }
  if (!alertRuleParams || !linkedaccid || !linkedaccid.relation || !linkedaccid.values) {
    alertValidationErrors[AwsCommonFields.LINKED_ACCOUNT_ID] = ALERT_RULE_ERROR_MESSAGES.INVALID_LINKED_ACCOUNT_FILTER;
  }
  if (!alertRuleParams || !division || !division.relation || !division.values) {
    alertValidationErrors[AwsCommonFields.DIVISION] = ALERT_RULE_ERROR_MESSAGES.INVALID_DIVISION_FILTER;
  }
  if (!alertRuleParams || !accounttags || !accounttags.relation || !accounttags.values) {
    alertValidationErrors[AwsCommonFields.ACCOUNT_TAGS] = ALERT_RULE_ERROR_MESSAGES.INVALID_ACCOUNT_TAGS_FILTER;
  }
  if (!validateRecipients(email, recipients)) {
    alertValidationErrors.email =
      email || recipients?.filter((r) => r.recipientData.type !== 'SENDGRID').length
        ? ALERT_RULE_ERROR_MESSAGES.INVALID_RECIPIENTS
        : ALERT_RULE_ERROR_MESSAGES.RECIPIENTS_MANDATORY;
  }
  return isEmpty(alertValidationErrors) ? null : alertValidationErrors;
};
const cleanReciepientsFromDOM = recipients => recipients?.map(({ label, ...recipient}) => ({...recipient, label: label && {key: label.key, props: label.props, ref: label.ref, _owner: label._owner ? null : undefined}}))
export const prepareAlertRule = (alertRuleParams) => {
  const {
    uuid,
    ruleName,
    direction,
    minAmountChange,
    minPercentChange,
    minAmountDailyChange,
    minPercentDailyChange,
    filters: {
      [AwsCommonFields.SERVICE]: service,
      [AwsCommonFields.LINKED_ACCOUNT_ID]: linkedaccid,
      [AwsCommonFields.DIVISION]: division,
      [AwsCommonFields.ACCOUNT_TAGS]: accounttags,
    },
    email,
    recipients,
    creationDate,
  } = alertRuleParams;
  return {
    uuid,
    ruleName,
    direction,
    minAmountChange,
    minPercentChange,
    minAmountDailyChange,
    minPercentDailyChange,
    email,
    recipients:  cleanReciepientsFromDOM(recipients),
    creationDate,
    filters: {
      include: {
        [AwsCommonFields.SERVICE]: service.relation.value === 'include' ? service.values.map(({ value }) => value) : [],
        [AwsCommonFields.DIVISION]:
          division.relation.value === 'include' ? division.values.map(({ value }) => value) : [],
        [AwsCommonFields.LINKED_ACCOUNT_ID]:
          linkedaccid.relation.value === 'include' ? linkedaccid.values.map(({ value }) => value) : [],
        [AwsCommonFields.ACCOUNT_TAGS]:
          accounttags.relation.value === 'include' ? accounttags.values.map(({ value }) => value) : [],
      },
      exclude: {
        [AwsCommonFields.SERVICE]: service.relation.value === 'exclude' ? service.values.map(({ value }) => value) : [],
        [AwsCommonFields.DIVISION]:
          division.relation.value === 'exclude' ? division.values.map(({ value }) => value) : [],
        [AwsCommonFields.LINKED_ACCOUNT_ID]:
          linkedaccid.relation.value === 'exclude' ? linkedaccid.values.map(({ value }) => value) : [],
        [AwsCommonFields.ACCOUNT_TAGS]:
          accounttags.relation.value === 'exclude' ? accounttags.values.map(({ value }) => value) : [],
      },
    },
  };
};

export const getAnomaliesQueryParams = (filterParams) => {
  const queryParams = [];
  if (filterParams && typeof filterParams === 'object') {
    Object.values(FILTER_PARAMS_KEYS).forEach((key) => {
      if (filterParams[key]) {
        if (key === FILTER_PARAMS_KEYS.EXCLUSIVE_START_KEY) {
          queryParams.push(`${key}=${JSON.stringify(filterParams[key])}`);
          return;
        }
        queryParams.push(`${key}=${filterParams[key]}`);
      }
    });
  }
  return queryParams.join('&');
};

export const getDivisionsForFilter = (divisionsData) => {
  if (!divisionsData || !divisionsData.divisionGroups) {
    return [];
  }
  const divisions = divisionsData.divisionGroups
    .map((div) => div.divisionName)
    .map((item) => ({
      value: item,
      label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item),
    }));
  if (divisions.length) {
    divisions.sort(propComparator('label'));
  }
  return divisions;
};

export const getAlertsFromAnomalies = (anomalies) => {
  if (!anomalies) {
    return [];
  }
  const alerts = [];
  anomalies.forEach(({ anomalyTriggeredItems, ...anomaly }) => {
    if (anomalyTriggeredItems) {
      Object.entries(anomalyTriggeredItems).forEach(([alertedAt, alertDetails]) => {
        const alertStart = moment(+alertedAt * 1000).format('YYYY-MM-DD');
        alerts.push({ ...anomaly, ...alertDetails, alertStart, anomalyTriggeredItems: { alertedAt: alertDetails } });
      });
    }
  });
  return alerts;
};

export const getTableColumns = (showAlerts, mapLinkedAccIdToDivisionName, userType) => {
  const columns = [
    {
      // This field value formmating has done in getAlertsFromAnomalies method
      name: 'alertStart',
      title: 'Alert Start',
    },
    {
      name: 'anomalyStart',
      title: 'Anomaly Start',
      getCellValue: (row) => moment(row.startTime * 1000).format('YYYY-MM-DD'),
    },
    {
      name: 'ruleName',
      title: 'Alert Name',
    },
    {
      name: 'division',
      title: LabelCoordinator.getFieldLabel(awsCommonFieldToDisplayField.get(AwsCommonFields.DIVISION)),
      getCellValue: (row) =>
        getDisplayNameByDivisionName(
          mapLinkedAccIdToDivisionName.get(row.linkedAccountId) ||
            `${userType === USER_TYPES_VALS.RESELLER ? 'Customer' : 'Cost Center'} wasn’t defined`,
        ),
    },
    {
      name: 'accountName',
      title: 'Account Name',
      getCellValue: ({ linkedAccountName, linkedAccountId }) =>
        linkedAccountName && linkedAccountName
          ? `${linkedAccountName} (${linkedAccountId})`
          : linkedAccountName || linkedAccountId,
    },
    { name: 'serviceName', title: 'Service' },
    {
      name: 'regionTagName',
      title: 'Region',
      getCellValue: ({ regionTagName }) => mapRegionNamesToDescription.get(regionTagName) || regionTagName,
    },
    { name: 'usageQuantityType', title: 'Usage Type' },
    {
      name: 'totalCostImpact',
      title: 'Cost Impact',
    },
    {
      name: 'lastDayImpact',
      title: 'Daily Cost Impact',
    },
    {
      name: 'percentChange',
      title: 'Cost Delta %',
    },
    {
      name: 'alerts',
      title: ' ',
      getCellValue: ({ anomalyTriggeredItems }) => Object.values(anomalyTriggeredItems || {}),
    },
    {
      name: 'feedbacks',
      title: ' ',
    },
    {
      name: 'comments',
      title: ' ',
    },
    {
      name: 'status',
      title: ' ',
      getCellValue: ({ isClosed }) => (isClosed ? 'closed' : 'open'),
    },
    {
      name: 'userStatuses',
      title: ' ',
    },
    {
      name: 'explore',
      title: ' ',
    },
  ];
  if (!showAlerts) {
    const alertStartIdx = columns.findIndex(({ name }) => name === 'alertStart');
    if (alertStartIdx !== -1) {
      columns.splice(alertStartIdx, 1);
    }
    const alertNameIdx = columns.findIndex(({ name }) => name === 'ruleName');
    if (alertNameIdx !== -1) {
      columns.splice(alertNameIdx, 1);
    }
  }
  if (![USER_TYPES_VALS.USER, USER_TYPES_VALS.RESELLER].includes(userType)) {
    const divisionIdx = columns.findIndex(({ name }) => name === 'division');
    if (divisionIdx !== -1) {
      columns.splice(divisionIdx, 1);
    }
  }
  return columns;
};

// For now it's relevant only for anomaly detection. This data should be stored in react query hook or in store.
export const getMapLinkedAccountIdToName = (linkedAccountsData) => {
  const mapLinkedAccountIdToName = new Map();
  linkedAccountsData.forEach(({ linkedAccountId, linkedAccountName }) => {
    mapLinkedAccountIdToName.set(linkedAccountId, linkedAccountName);
  });
  return mapLinkedAccountIdToName;
};

export const getAlertRuleRows = (alertRules, linkedAccountIdsToNames) =>
  alertRules.map(({ filters, ...rest }) => {
    const { include, exclude } = filters;
    const [formattedIncluded, formattedExcluded] = [include, exclude].map(
      ({ [AwsCommonFields.LINKED_ACCOUNT_ID]: linkedAccountIds, [AwsCommonFields.DIVISION]: divisions }) => ({
        [AwsCommonFields.LINKED_ACCOUNT_ID]: linkedAccountIds.map((linkedAccountId) => {
          const linkedAccountName = linkedAccountIdsToNames.get(linkedAccountId);
          if (linkedAccountName) {
            return `${linkedAccountName} (${linkedAccountId})`;
          }
          return linkedAccountId;
        }),
        [AwsCommonFields.DIVISION]: divisions.map((divisionName) =>
          LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', divisionName),
        ),
      }),
    );
    return {
      ...rest,
      filters: {
        ...filters,
        include: { ...include, ...formattedIncluded },
        exclude: { ...exclude, ...formattedExcluded },
      },
    };
  });
