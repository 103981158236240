import { buildTimeDiffDateFromBaseDate } from 'shared/utils/dateUtil';

export default class CustomDashboardPanel {
  constructor(panelParams) {
    this.uuid = panelParams.uuid;
    this.name = panelParams.name;
    this.hidden = panelParams.hidden;
    this.createdBy = panelParams.createdBy;
    this.creationDate = panelParams.creationDate || panelParams.updatedTime;
    this.type = panelParams.type; // cue or kpis
    this.chartType = panelParams.chartType; // cue or kpis
    this.route = panelParams.route; // cue or kpis
    this.periodParams = panelParams.periodParams;
    this.routeParams = panelParams.routeParams;
    this.displayStatus = panelParams.displayStatus;
    this.state = panelParams.state;
    this.arrBreadCrumbs = panelParams.arrBreadCrumbs;
    this.accountId = panelParams.accountId;
    this.accountKey = panelParams.accountKey;
    this.userKey = panelParams.userKey;
    this.divisionId = panelParams.divisionId;
    this.numberOfItems = panelParams.numberOfItems;
    this.cloudType = panelParams.cloudType;
  }

  setName = (newName) => {
    this.name = newName;
  };
  setIsDefaultOthers = (isDefaultOthers) => {
    if (!this.state) {
      this.state = {};
    }
    this.state.isShowOthers = isDefaultOthers;
  };

  setNumberOfItems = (numberOfItems) => {
    this.numberOfItems = numberOfItems;
  };

  setGranularity = (newGranularity) => {
    this.periodParams.granularity = newGranularity;
    this.routeParams.granularity = newGranularity;
    this.routeParams.periodGranLevel = newGranularity;
  };
  setPeriod = (relativeDatesPeriod) => {
    this.periodParams.relativeDatesDaysDiff = relativeDatesPeriod;
    const { endDate } = this.routeParams;
    const { startDate } = buildTimeDiffDateFromBaseDate(new Date(endDate), -relativeDatesPeriod, 'd');
    this.routeParams.startDate = startDate;
  };
}
