import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import React from 'react';

import styles from './Customers.module.scss';

const CustomersIcon = () => (
  <div className={styles.customersIcon}>
    <GenerateIcon iconName={ICONS.users.name} className={styles.icon} />
  </div>
);

export default CustomersIcon;
