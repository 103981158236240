import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import CreateEditCostCenterModal from './CreateEditCostCenterModal';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import styles from './CreateEditCostCenterModal.module.scss';

const EditCostCenterAccounts = ({ costCenter }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <Tooltip title={'Edit Cost Center'} placement={'top'}>
      <Button
        automationId="editCostCenterLinkedAccounts"
        className={{ button: styles.addLinkedAccounts }}
        disabled={false}
        icon={<GenerateIcon iconName={ICONS.addLinkedAccounts.name} className={styles.addLinkedAccountsIcon} />}
        onClick={onClick}
        text=""
      />
      {isOpen && <CreateEditCostCenterModal isOpen={isOpen} setIsOpen={setIsOpen} costCenter={costCenter} />}
    </Tooltip>
  );
};

EditCostCenterAccounts.propTypes = {
  costCenter: PropTypes.string.isRequired,
};

export default EditCostCenterAccounts;
