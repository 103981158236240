import React from 'react';
import PropTypes from 'prop-types';

import { RecommendationsNewProvider } from 'recommendationsNew/contexts/recommendationsNewContext';
import SavingsOpportunitiesTable from 'recommendationsNew/components/heatMap/cloudWaste/savingsOpportunitiesTable';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { HeatMapProvider } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import NewRecommendationContext from '../NewRecommendationDashboardContext';

const RecommendationSavingsOpportunities = (props) => {
  const { state, cloudType, data } = props;
  return (
    <RecommendationsNewProvider>
      <TableHeaderProvider>
        <HeatMapProvider>
          <NewRecommendationContext filterState={state} cloudType={cloudType} />
          <SavingsOpportunitiesTable externalData={data?.[0]} />
        </HeatMapProvider>
      </TableHeaderProvider>
    </RecommendationsNewProvider>
  );
};

RecommendationSavingsOpportunities.propTypes = {
    state: PropTypes.object.isRequired,
    cloudType: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
};
export default RecommendationSavingsOpportunities;
