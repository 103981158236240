import { useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { sanitizeQuery } from 'shared/utils/apiUtil';
import { queryClient } from 'queryClient';
import { prepareSPAnalyzerFilters } from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils';


const root = '/api/v1/commitment/sp-analyzer';

const getSavingsPlansAnalyzerData = async (
  selectedOptions,
  daysToCheck,
  filterStatusType,
  selectedPaymentOption,
  selectedTerm,
  coverage,
  hourlyCommitment,
  excludeExpiringSPsDays,
) => {
  const { includeFilterParams, excludeFilterParams } = prepareSPAnalyzerFilters(selectedOptions, filterStatusType);
  let apiQuery = `${root}?daysToCheck=${daysToCheck}&paymentOption=${selectedPaymentOption}&term=${selectedTerm}&coverage=${
    coverage / 100
  }${includeFilterParams}${excludeFilterParams}${excludeExpiringSPsDays? `&excludeExpiringSPsDays=${excludeExpiringSPsDays}` : ''}
  ${hourlyCommitment ? `&hourlyCommitment=${hourlyCommitment}` : ''}`;
  apiQuery = sanitizeQuery(apiQuery);
  return API.get('billings', apiQuery);
};

export function useFetchSavingsPlansAnalyzerData(
    {
        selectedOptions,
        daysToCheck = 30,
        filterStatusType,
        selectedPaymentOption,
        selectedTerm,
        coverage,
        excludeExpiringSPsDays,
        limitEnableApi,
    }
) {
    const queryKey = apiConstants.QUERY_KEYS.SP_ANALYZER_DATA;
    return useQuery(
        [queryKey],
        () =>
            getSavingsPlansAnalyzerData(
                selectedOptions,
                daysToCheck,
                filterStatusType,
                selectedPaymentOption,
                selectedTerm,
                coverage,
                null,
                excludeExpiringSPsDays,
            ),
        { retry: false,
            enabled: !limitEnableApi && daysToCheck != null && !isNaN(coverage) && selectedTerm != null && selectedPaymentOption != null
        },
    );
}

export function useFetchSingleOptionPlanAnalyzerData(
    {
        selectedOptions,
        daysToCheck,
        filterStatusType,
        selectedPaymentOption,
        selectedTerm,
        coverage,
        hourlyCommitment,
        excludeExpiringSPsDays,
    }
) {
    const queryKey = apiConstants.QUERY_KEYS.SP_ANALYZER_DATA;
    return useQuery(
        [queryKey, hourlyCommitment],
        () =>
            getSavingsPlansAnalyzerData(
                selectedOptions,
                daysToCheck,
                filterStatusType,
                selectedPaymentOption,
                selectedTerm,
                coverage,
                hourlyCommitment,
                excludeExpiringSPsDays,
            ),
        {
            retry: false,
            enabled:
                daysToCheck != null &&
                coverage != null &&
                selectedTerm != null &&
                selectedPaymentOption != null &&
                hourlyCommitment != null,
        },
    );
}

export default function useSpAnalyzer() {
    const queryKey = apiConstants.QUERY_KEYS.SP_ANALYZER_DATA;

    return {
        invalidate: () => queryClient.invalidateQueries(queryKey),
        fetchSavingsPlansAnalyzerData : useFetchSavingsPlansAnalyzerData,
        fetchSingleOptionPlanAnalyzerData: useFetchSingleOptionPlanAnalyzerData,
    };
}
