import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import { CostCentersContext } from './contexts/costCentersContext';
import CostCentersHeader from './CostCentersHeader';
import CostCentersTable from './CostCentersTable';
import { findFilteredData } from 'users/containers/Organization/CostCenters/costCentersHelperFunctions.js';

import styles from './CostCenters.module.scss';

const CostCenters = ({
  isRoleCostCentersDataAccessLoading,
  isSelectionEnabled = false,
  onRemoveCostCenters,
  onSelectionChange,
  roleCostCentersDataAccess,
  roleId,
}) => {
  const [search, setSearch] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const { NewTableWrapper } = useTable();

  const { fetchDataAccessCostCenters } = useDataAccessAccounts(!!roleCostCentersDataAccess);
  const { data: costCentersData, isLoading: isCostCentersDataAccessLoading } = fetchDataAccessCostCenters();

  const costCentersToDisplay = useMemo(() => {
    const data = roleCostCentersDataAccess || costCentersData; // If from DataAccessTab - data access of a role received

    let result = null;
    if (data) {
      result = data.map((cc) => {
        const transformedCostCenter = {
          ...{ ...cc?.costCenter, parentId: cc?.costCenter?.parentCcId },
          payerAccounts: cc.payerAccounts,
        };

        delete transformedCostCenter.parentCcId;

        return transformedCostCenter;
      });
      if (search) {
        result = findFilteredData(result, search, null);
      }
    }
    return result;
  }, [costCentersData, roleCostCentersDataAccess, search]);

  const totalCount = useMemo(() => {
    if (roleCostCentersDataAccess) {
      if (isSelectionEnabled) {
        return costCentersToDisplay?.filter((cc) => cc.isAllowed === true).length;
      }
      return roleCostCentersDataAccess.length;
    }

    return costCentersData?.length;
  }, [costCentersData, costCentersToDisplay, isSelectionEnabled, roleCostCentersDataAccess]);

  return (
    <div className={styles.costCenters}>
      <CostCentersContext.Provider
        value={{
          isAllExpanded,
          search,
          selectedRows,
          setIsAllExpanded,
          setSearch,
          setSelectedRows,
        }}
      >
        <NewTableWrapper>
          <CostCentersHeader
            isSelectionEnabled={isSelectionEnabled}
            isRoleDataAccessMode={!!roleCostCentersDataAccess || isRoleCostCentersDataAccessLoading}
            roleId={roleId}
            total={totalCount}
          />
          <CostCentersTable
            costCenters={costCentersToDisplay}
            isLoading={isCostCentersDataAccessLoading || isRoleCostCentersDataAccessLoading}
            isSelectionEnabled={isSelectionEnabled}
            isRoleDataAccessMode={!!roleCostCentersDataAccess || isRoleCostCentersDataAccessLoading}
            onRemoveCostCenter={onRemoveCostCenters}
            onSelectionChange={onSelectionChange}
          />
        </NewTableWrapper>
      </CostCentersContext.Provider>
    </div>
  );
};

CostCenters.propTypes = {
  isRoleCostCentersDataAccessLoading: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  onRemoveCostCenters: PropTypes.func,
  onSelectionChange: PropTypes.func,
  roleCostCentersDataAccess: PropTypes.object,
  roleId: PropTypes.string,
};

export default CostCenters;
