import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import AssignCostCentersToRoleModal from './AssignCostCentersToRoleModal';

const AssignCostCentersToRole = ({ roleId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        automationId="assignCostCentersToRoleButton"
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        onClick={onClick}
        text="Add Cost Centers"
      />
      {isOpen && <AssignCostCentersToRoleModal isOpen={isOpen} setIsOpen={setIsOpen} roleId={roleId} />}
    </>
  );
};

AssignCostCentersToRole.propTypes = {
  roleId: PropTypes.string.isRequired,
};

export default AssignCostCentersToRole;
