import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as MenuIcon } from 'shared/img/icons/three-dots-menu.svg';
import { ReactComponent as ModalHeaderAddIcon } from 'shared/img/icons/modal-header-add.svg';
import { ReactComponent as ModalHeaderEditIcon } from 'shared/img/icons/modal-header-edit.svg';
import styles from './Modal.module.scss';
import Button from './Button';
import ButtonDropdown from './ButtonDropdown';
import InfoPopover from './InfoPopover';

export const MODAL_MODES = { ADD: 'add', EDIT: 'edit', SIMPLE: 'simple', SIMPLE_NEW: 'simple_new' };

const CustomModal = ({
  open,
  onClose,
  onCloseClick,
  onSecondCancelClick,
  closeOnOutside,
  cancelTitle,
  cancelHidden,
  secondCancelTitle,
  title,
  onSave,
  className,
  children,
  saveDisabled,
  saveWarning,
  saveTitle,
  menuContent,
  closeOnSave,
  footerDisabled,
  footerMessage,
  overrideStyles,
  leftButton,
  saveHidden,
  automationId,
  secondPrimaryTitle,
  onSecondPrimaryClick,
  secondPrimaryDisabled,
  headerMode,
  headerIcon,
  comment,
  footerBorder,
  isSecondPrimaryError,
  isLoading,
}) => {
  const [cbLoading, setLoading] = useState(false);
  const onClick = async (e) => {
    try {
      setLoading(true);
      await onSave(e);
      setLoading(false);
      if (closeOnSave) {
        onClose(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  if (!open) {
    return null;
  }

  const handleClickOutside = (e) => {
    if (
      closeOnOutside &&
      typeof e.target.className?.includes === 'function' &&
      e.target.className?.includes('capture-click-outside')
    ) {
      onClose(false);
    }
  };

  const renderHeader = () => {
    const infoIcon = comment ? (
      <InfoPopover isSimple>
        <div className={styles.comment}>{comment}</div>
      </InfoPopover>
    ) : (
      <div />
    );

    switch (headerMode) {
      case MODAL_MODES.ADD:
        return (
          <div className={classnames(styles.header, className.modalHeader)}>
            {headerIcon || <ModalHeaderAddIcon />}
            <h3 className={classnames(styles.title, className.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.EDIT:
        return (
          <div className={classnames(styles.header, className.modalHeader)}>
            <ModalHeaderEditIcon />
            <h3 className={classnames(styles.title, className.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.SIMPLE_NEW:
        return (
          <div className={classnames(styles.header, className.modalHeader)}>
            <h3 className={classnames(styles.title, className.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      default:
        return title ? <h3 className={classnames(styles.title, className.title)}>{title}</h3> : null;
    }
  };

  return (
    <div
      className={`${classnames(styles.container, className.container)} capture-click-outside`}
      onMouseDown={handleClickOutside}
      automation-id={automationId}
    >
      <div
        className={classnames(styles.modal, className.modal || className)}
        style={overrideStyles}
        onClick={(e) => e.stopPropagation()}
      >
        {renderHeader()}
        {menuContent && (
          <div className={styles.menuIcon}>
            <ButtonDropdown
              text=""
              icon={MenuIcon}
              isTextButton
              isSecondary={false}
              automationId={`${automationId}-menuContent`}
            >
              {menuContent}
            </ButtonDropdown>
          </div>
        )}
        <div className={classnames(styles.content, className.content)}>{children}</div>
        {!footerDisabled && (
          <div className={classnames(styles.footer, className.footer, { [styles.footerBorder]: footerBorder })}>
            {footerMessage ? <div className={styles.footerMessage}>{footerMessage}</div> : <div />}
            <div className={styles.buttons}>
              {leftButton ? <div className={styles.leftMenu}>{leftButton}</div> : null}
              {secondCancelTitle && (
                <Button
                  onClick={() => {
                    onClose(false);
                    if (onSecondCancelClick) {
                      onSecondCancelClick();
                    }
                  }}
                  isSecondary
                  text={secondCancelTitle}
                  automationId={`${automationId}-${secondCancelTitle}`}
                />
              )}
              {!cancelHidden && (
                <Button
                  onClick={() => {
                    onClose(false);
                    if (onCloseClick) {
                      onCloseClick();
                    }
                  }}
                  isSecondary
                  text={cancelTitle}
                  automationId={`${automationId}-${cancelTitle}`}
                />
              )}
              {secondPrimaryTitle && (
                <Button
                  automationId={`${automationId}-${secondPrimaryTitle}`}
                  isLoading={isLoading || cbLoading}
                  disabled={secondPrimaryDisabled}
                  isError={isSecondPrimaryError}
                  onClick={onSecondPrimaryClick}
                  text={secondPrimaryTitle}
                />
              )}
              {!saveHidden && (
                <Button
                  automationId={`${automationId}-${saveTitle}`}
                  isLoading={isLoading || cbLoading}
                  disabled={saveDisabled}
                  isError={saveWarning}
                  onClick={onClick}
                  text={saveTitle}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CustomModal.propTypes = {
  overrideStyles: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  onSave: PropTypes.func,
  onCloseClick: PropTypes.func,
  onSecondCancelClick: PropTypes.func,
  leftButton: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  saveTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  secondCancelTitle: PropTypes.string,
  automationId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element.isRequired)])
    .isRequired,
  saveDisabled: PropTypes.bool,
  saveHidden: PropTypes.bool,
  saveWarning: PropTypes.bool,
  menuContent: PropTypes.element,
  closeOnSave: PropTypes.bool,
  footerDisabled: PropTypes.bool,
  closeOnOutside: PropTypes.bool,
  secondPrimaryTitle: PropTypes.string,
  isSecondPrimaryError: PropTypes.bool,
  onSecondPrimaryClick: PropTypes.func,
  secondPrimaryDisabled: PropTypes.bool,
  cancelHidden: PropTypes.bool,
  headerMode: PropTypes.oneOf(Object.values(MODAL_MODES)),
  comment: PropTypes.string,
  headerIcon: PropTypes.element,
  footerBorder: PropTypes.bool,
  footerMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

CustomModal.defaultProps = {
  closeOnSave: true,
  overrideStyles: null,
  className: '',
  automationId: 'modal',
  saveTitle: 'Save',
  cancelTitle: 'Cancel',
  cancelHidden: false,
  secondCancelTitle: '',
  onCloseClick: null,
  onSecondCancelClick: null,
  saveDisabled: false,
  saveWarning: false,
  saveHidden: false,
  leftButton: null,
  menuContent: null,
  onSave: () => {},
  footerDisabled: false,
  closeOnOutside: true,
  secondPrimaryTitle: null,
  isSecondPrimaryError: true,
  onSecondPrimaryClick: null,
  secondPrimaryDisabled: false,
  headerMode: 'simple',
  comment: null,
  headerIcon: null,
  footerBorder: false,
  title: null,
  isLoading: false,
};

export default CustomModal;
