import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {
    AZURE_PROPERTIES,
    BASE_PROPERTIES,
    COST_PROPERTIES,
    OPTIONS_PROPERTIES
} from '../../recommendationPropertyUtils';
import RecommendationInsideLook
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationInsideLook.jsx';
import OtherResourceIdRecommendations
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/otherResourceIdRecommendations.jsx';
import RecommendationDetails
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationDetails.jsx';
import RecommendationPreferences
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationPreferences.jsx';
import RecommendationDetailsLayout
    from 'recommendationsNew/components/detailedRecommendation/recommendationDetailsLayout.jsx';
import RecommendationOptionsPanel
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationOptionsPanel.jsx';
import RecommendationChartData, {
    CHART_TYPES
} from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationChart/recommendationChartData.jsx';
import {
    Y_AXIS_UNIT
} from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationChart/chartConsts.js';
import RecommendationCommand, {
    COMMAND_TYPES
} from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationCommand.jsx';
import RecommendationOptionsContext
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationOptionsContext.jsx';


const AZURE_SQL_RS_PROPERTIES = {
    PURCHASE_TYPE: {
        label: 'Purchase Type',
        getterFunction: (recommendation) => recommendation?.recData?.purchase_type,
    },
    CURRENT_SIZE: {
        label: 'Current Size',
        getterFunction: (recommendation) => recommendation?.recData?.current_model,
    },
    RECOMMENDED_SIZE: {
        label: 'Recommended Size',
        getterFunction: (recommendation, optionIndex) => {
            if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
                return recommendation.recData.alternatives[optionIndex]?.recommended_model;
            }
            return recommendation?.recData?.recommended_model;
        },
    },
    SERVICE_TIER: {
        label: 'Service Tier',
        getterFunction: (recommendation) => recommendation?.recData?.service_tier,
    },
    HARDWARE_TYPE: {
        label: 'Hardware Type',
        getterFunction: (recommendation) => recommendation?.recData?.hardware_type,
    },
    DB_NAME: {
        label: 'DB name',
        getterFunction: (recommendation) => recommendation?.recData?.db_name,
    },
    SERVER_NAME: {
        label: 'Server name',
        getterFunction: (recommendation) => recommendation?.recData?.server,
    },

}

const attentionComment = (
    <ul style={{ paddingLeft: '20px' }}>
        <li key={1}>
            For vCore purchase type, verify if the instance is covered by RI, this has been verified only for Amortized accounts.
        </li>
        <li key={2}>
            The potential savings and current costs are calculated based on the List Price (on-demand).
        </li>
    </ul>
);
const AzureSqlRightSizing = ({ recommendation }) => {
    const properties = {
        ...AZURE_PROPERTIES,
        ...AZURE_SQL_RS_PROPERTIES,
        ...COST_PROPERTIES,
        ...BASE_PROPERTIES,
    };

    const description = `As a result of low utilization, we recommend reducing the size of the DTU / vCore, which will reduce Azure SQL cost.`;

    const insideLookComponent = <RecommendationInsideLook description={description} />;

    const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

    const { selectedOptionIndex } = useContext(RecommendationOptionsContext);

    const recommendationWithAttentionComment = {
        ...recommendation,
        recData: {
            ...recommendation.recData,
            attention_comment: attentionComment,
        },
    };
    const detailsComponent = (
        <RecommendationDetails recommendationProperties={properties} recommendation={recommendationWithAttentionComment} />
    );

    const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

    const CURRENT_PROPERTIES = [
        properties.CURRENT_SIZE,
        properties.CURRENT_ANNUAL_COST,
    ];

    const ALTERNATIVE_PROPERTIES = [
        properties.RECOMMENDED_SIZE,
        properties.RECOMMENDED_ANNUAL_COST,
        OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
    ];

    const alternatives = recommendation?.recData?.alternatives;
    const optionsPanelComponent =
        Array.isArray(alternatives) && alternatives.length ? (
            <RecommendationOptionsPanel
                alternatives={alternatives}
                currentProperties={CURRENT_PROPERTIES}
                alternativeProperties={ALTERNATIVE_PROPERTIES}
                recommendation={recommendation}
                isRecommendedOption
            />
        ) : null;


    let chartsData = [
        {
            chartType: CHART_TYPES.SERIES_DATA,
            yAxisUnit: Y_AXIS_UNIT.PERCENT,
            chartTitle: 'vCore / DTU Utilization',
            yAxisLabel: 'utilization',
            maxDataProperty: recommendation.recData?.dtu_consumption_max_data || recommendation.recData?.cpu_max_data,
            avgDataProperty: recommendation.recData?.dtu_consumption_avg_data || recommendation.recData?.cpu_avg_data,
        },
        {
            chartType: CHART_TYPES.SERIES_DATA,
            chartTitle: 'Storage Usage',
            yAxisLabel: 'Storage',
            yAxisUnit: Y_AXIS_UNIT.BYTE,
            maxDataProperty: recommendation.recData?.storage_max_data,
            avgDataProperty: recommendation.recData?.storage_avg_data,
        },
    ];
    if(recommendation.recData?.io_max_data) {
        chartsData.splice(1, 0, {
            chartType: CHART_TYPES.SERIES_DATA,
            chartTitle: 'IO Performance',
            info: 'IO Performance chart is relevant for DTU purchase type only',
            yAxisLabel: 'IO',
            maxDataProperty: recommendation.recData?.io_max_data,
            avgDataProperty: recommendation.recData?.io_avg_data,
        });
    }
    const recommendationChartsComponent = <RecommendationChartData chartsData={chartsData} />;

    const actionCommand =
        selectedOptionIndex >= 0 && recommendation?.recData?.alternatives[selectedOptionIndex]?.command
            ? recommendation?.recData.alternatives[selectedOptionIndex].command
            : recommendation?.recData.command;

    const actionCommandComment =
        selectedOptionIndex >= 0 && recommendation?.recData?.alternatives[selectedOptionIndex]?.command_comment
            ? recommendation?.recData.alternatives[selectedOptionIndex].command_comment
            : recommendation?.recData.command_comment;

    const commandsList = [
        {
            type: COMMAND_TYPES.CLI,
            comment: actionCommandComment,
            instructions: [
                {
                    actionText: '',
                    actionCommand,
                },
            ],
        },
    ];

    const commandComponent = <RecommendationCommand commandsList={commandsList} />;

    return (
        <RecommendationDetailsLayout
            insideLook={insideLookComponent}
            otherResourceIdRecommendations={otherResourceIdRecommendations}
            details={detailsComponent}
            preferences={preferencesComponent}
            optionsPanel={optionsPanelComponent}
            charts={recommendationChartsComponent}
            command={commandComponent}
        />
    );
};

AzureSqlRightSizing.propTypes = {
    recommendation: PropTypes.object.isRequired,
};

export default AzureSqlRightSizing;
