import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import PropTypes from 'prop-types';
import { Search } from '../components/Search';
import { useCostCentersContext } from './contexts/costCentersContext';
import AssignCostCentersToRole from './costCentersModals/AssignCostCentersToRole/AssignCostCentersToRole';
import CreateEditCostCenter from '~/users/containers/Organization/CostCenters/costCentersModals/CreateEditCostCenter/CreateEditCostCenter.jsx';

import styles from './CostCentersHeader.module.scss';

const CostCentersHeader = ({ total, isRoleDataAccessMode, roleId, isSelectionEnabled }) => {
  const { isAllExpanded, setIsAllExpanded, search, setSearch } = useCostCentersContext();

  const [isCsvDataFetching] = useState(false);

  const fetchExportData = () => {};

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        {!isRoleDataAccessMode ? (
          <Button
            automationId="expand-rows"
            text=""
            isSecondary
            icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
            onClick={() => {
              setIsAllExpanded(!isAllExpanded);
            }}
          />
        ) : null}
        <div className={styles.header}>
          <div className={styles.title}>
            Cost Centers <span className={styles.count}>({total})</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        {!isRoleDataAccessMode && <CreateEditCostCenter />}
        {isRoleDataAccessMode && !isSelectionEnabled ? <AssignCostCentersToRole roleId={roleId} /> : null}
        <Search search={search} setSearch={setSearch} isDebounce />
        {!isRoleDataAccessMode ? (
          <Tooltip title="Export" arrow>
            <CustomCSVDownload
              fetchData={fetchExportData}
              automationId="export-cost-centers"
              showDownloadIcon
              isLoading={isCsvDataFetching}
              filesNumber={1}
              isSecondary
              isLightButton
              hideText
              style={{ height: 36 }}
            />
            <span />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

CostCentersHeader.propTypes = {
  isRoleDataAccessMode: PropTypes.object,
  isSelectionEnabled: PropTypes.bool,
  roleId: PropTypes.string,
  total: PropTypes.number.isRequired,
};

export default CostCentersHeader;
