import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import TextArea from 'shared/components/andtComponents/TextArea';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList.jsx';
import ErrorDetails from 'users/containers/Organization/Users/userModals/AddUsersModal/ErrorDetails.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import PreviewUserEmails from 'users/containers/Organization/Users/userModals/AddUsersModal/PreviewUserEmails.jsx';
import toast from 'shared/components/andtComponents/Toast.jsx';
import { ReactComponent as DragAndDropUsersFile } from '../../img/dragAndDrop.svg';

import styles from './AddUsersModal.module.scss';

const OPTIONS = {
  EMAIL: { value: 'email', label: 'Email', primary: true },
  FILE: { value: 'file', label: 'Upload CSV file', primary: true },
};

const CreateUsers = ({ setEmailsToSave, validationErrors, setValidationErrors, saveClicked }) => {
  const [userEmailErrors, setUserEmailErrors] = useState(null);
  const [type, setType] = useState(OPTIONS.EMAIL.value);
  const [acceptedFile, setAcceptedFile] = useState();
  const [emails, setEmails] = useState([]);
  const [emailsFromCsvFile, setEmailsFromCsvFile] = useState();

  useEffect(() => {
    const emailsValue = type === OPTIONS.EMAIL.value ? emails : emailsFromCsvFile;
    setEmailsToSave(emailsValue);
  }, [type, emails, emailsFromCsvFile]);

  const initializeErrors = () => {
    setValidationErrors(undefined);
    setUserEmailErrors(undefined);
  };

  const removeCsvFile = () => {
    if (type === OPTIONS.FILE.value) {
      setAcceptedFile(undefined);
      initializeErrors();
      setUserEmailErrors(undefined);
      setEmailsFromCsvFile(undefined);
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();
    if (file.size > 10485760) {
      toast.error('File is too big.');
      return;
    }
    try {
      reader.onabort = () => toast.error('Error reading the file');
      reader.onerror = () => toast.error('Error reading the file');
      reader.onload = () => {
        const content = reader.result;
        const uploadedUserEmails = content.split('\r\n');
        if (uploadedUserEmails?.[0] === 'email') {
          setAcceptedFile(file);
          initializeErrors();
          uploadedUserEmails.shift();

          setEmailsFromCsvFile(uploadedUserEmails);
        } else {
          setUserEmailErrors([{ email: '', error: 'Column header of the CSV file is not "email".' }]);
        }
      };
      reader.readAsText(file);
    } catch (error) {
      toast.error('Error reading the file: ', error);
    }
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.length) {
      toast.error('Wrong file format, please use CSV files only');
      return;
    }
    readFile(acceptedFiles[0]);
  };

  useEffect(() => {
    if (type === OPTIONS.FILE.value) {
      initializeErrors();
    }
  }, [type]);

  const onEmailsTextAreaChange = (e) => {
    initializeErrors();
    const emails = e?.target?.value ? e?.target?.value?.split(',') : undefined;
    setEmails(emails);
  };

  const onOptionChange = (val) => {
    if (!saveClicked) {
      setType(val);
      initializeErrors();
    }
  };

  return (
    <div className={classNames(styles.addUsersContainer, { [styles.isDisabled]: saveClicked })}>
      <div className={styles.title}>Choose one of the options:</div>
      <div className={styles.options}>
        <div className={styles.option}>
          <RadioButtonList options={[OPTIONS.EMAIL]} value={type} onChange={onOptionChange} />
          <div className={styles.optionContent}>
            <TextArea
              disabled={type !== OPTIONS.EMAIL.value || saveClicked}
              isResizable={false}
              value={emails?.join(',')}
              placeholder="Separate addresses by commas. Up to 10 users"
              onChange={onEmailsTextAreaChange}
              isInvalid={
                type === OPTIONS.EMAIL.value && (userEmailErrors?.length > 0 || validationErrors?.users?.length > 0)
              }
              invalidComponent={<ErrorDetails emailErrors={userEmailErrors || validationErrors?.users} />}
            />
          </div>
        </div>
        <div className={styles.option}>
          <RadioButtonList options={[OPTIONS.FILE]} value={type} onChange={onOptionChange} />
          <div className={styles.optionContent}>
            <div className={styles.description}>
              {`Upload CSV file with a single column, the column title should be 'email'. `}
              <span className={styles.emphasize}>Limited up to 10 users.</span>
            </div>
            <div>
              <Dropzone
                disabled={type === OPTIONS.EMAIL.value || saveClicked}
                accept=".csv"
                onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      className={classNames(styles.uploadFile, {
                        [styles.disabled]: type === OPTIONS.EMAIL.value,
                      })}
                      {...getRootProps()}
                    >
                      {acceptedFile ? (
                        <div className={styles.acceptedFile}>
                          {acceptedFile?.name}
                          <GenerateIcon iconName={ICONS.xMark.name} onClick={removeCsvFile} />
                        </div>
                      ) : (
                        <>
                          <DragAndDropUsersFile />
                          <div>
                            <input {...getInputProps()} />
                            <div className={styles.description}>Drag & Drop a CSV file, or click to select a file</div>
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="d-flex gap-5">
              <PreviewUserEmails userEmails={emailsFromCsvFile} />
              {type === OPTIONS.FILE.value && <ErrorDetails emailErrors={userEmailErrors || validationErrors?.users} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateUsers.propTypes = {
  emailsToSave: PropTypes.array,
  setEmailsToSave: PropTypes.func,
  validationErrors: PropTypes.array,
  saveClicked: PropTypes.bool,
  setValidationErrors: PropTypes.func,
};

export default CreateUsers;
