import React, { useState } from 'react';
import { GenerateIcon, ICONS, Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import PropTypes from 'prop-types';
import Button from 'shared/components/andtComponents/Button';
import useTable from 'shared/hooks/customHooks/useTable';
import PreviewEmailsTable from './PreviewEmailsTable';

import styles from './PreviewUserEmails.module.scss';

const PreviewUserEmails = ({ userEmails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPreviewUsersOpen, setIsPreviewUsersOpen] = useState(false);

  const { NewTableWrapper } = useTable();

  return (
    <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <PopoverTrigger>
        <Button
          automationId="previewAddedUsersList"
          disabled={!userEmails?.length}
          isTextButton
          icon={() => <GenerateIcon iconName={ICONS.mainUserRegular.name} />}
          onClick={() => {
            setIsPreviewUsersOpen(!isPreviewUsersOpen);
          }}
          overrideStyles={{ paddingLeft: 0 }}
          text="Preview Users List"
        />
      </PopoverTrigger>
      <PopoverContent className={styles.emailsContainer}>
        <div className={styles.previewUsersTitle}>Users List</div>
        <div className={styles.previewRolesContainer}>
          <NewTableWrapper className="sub-table" isCompact>
            <PreviewEmailsTable emails={userEmails?.map((e) => ({ email: e }))} />
          </NewTableWrapper>
        </div>
      </PopoverContent>
    </Popover>
  );
};

PreviewUserEmails.props = {
  userEmails: PropTypes.array,
};

export default PreviewUserEmails;
