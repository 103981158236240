import { useQuery, useMutation } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

export const useMultiAccountsResellerCustomerRoles = (options) =>
  useQuery(
    apiConstants.QUERY_KEYS.MULTI_ACCOUNTS_RESELLER_CUSTOMER_ROLES,
    () => API.get('billings', '/api/v1/admin/roles/multi-reseller-customer-roles'),
    options,
  );

export const useCreateMultiAccountsCutsomerRole = (mutationSettings = {}) =>
  useMutation({
    mutationFn: ({ customerName, accounts, linkedDivisions }) =>
      API.post('billings', '/api/v1/admin/roles/reseller-customer-multi-account', {
        body: { customerName, accounts, linkedDivisions },
      }),
    ...mutationSettings,
  });
