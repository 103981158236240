import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import Pagination from 'shared/components/andtComponents/Pagination';
import PropTypes from 'prop-types';
import { Search } from '../components/Search';
import RolesIcon from './RolesIcon';
import { DEFAULT_PAGE_SIZE } from '../consts';
import { useRolesContext } from './contexts/rolesContext';
import CreateRole from './roleModals/CreateRoleModal/CreateRole';

import styles from './RolesHeader.module.scss';

const RolesHeader = ({ total, pageChanged }) => {
  const { isAllExpanded, setIsAllExpanded, search, setSearch } = useRolesContext();

  const [isCsvDataFetching] = useState(false);

  const fetchExportData = () => {};

  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = total >= 0 ? Math.ceil(total / DEFAULT_PAGE_SIZE) : 0;

  const onPageChange = (newPage) => {
    pageChanged(newPage - currentPage);
    setCurrentPage(newPage);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <Button
          automationId="expand-rows"
          text=""
          isSecondary
          icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
          onClick={() => {
            setIsAllExpanded(!isAllExpanded);
          }}
        />
        <div className={styles.header}>
          <RolesIcon />
          <div className={styles.title}>
            Roles <span className={styles.count}>({total})</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        <CreateRole />
        <Search search={search} setSearch={setSearch} isDebounce />
        <Tooltip title="Export" arrow>
          <CustomCSVDownload
            fetchData={fetchExportData}
            automationId="export-roles"
            showDownloadIcon
            isLoading={isCsvDataFetching}
            filesNumber={1}
            isSecondary
            isLightButton
            hideText
            style={{ height: 36 }}
          />
          <span />
        </Tooltip>
        <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

RolesHeader.propTypes = {
  pageChanged: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default RolesHeader;
