import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { toast } from 'react-toastify';

const root = '/api/v1/user-management/customers';

const STALE_TIME = 60 * 1000 * 3;

const fetchMspCustomers = async ({ linkedAccounts }) => API.post('billings', root, { body: { linkedAccounts }});
const fetchCurrentAccountCustomers = async () => API.get('billings', root + '/current-account');
const fetchAvailableLinkedAccounts = async () => API.get('billings', `${root}/linked-accounts`);
export const fetchCustomersCSVData = async ({ linkedAccountIds, customerIds }) => API.post('billings', `${root}/csv-data`, {
  body: { linkedAccountIds, customerIds },
});
export const fetchCustomerAccounts = async (customer) => {
  if (!customer) {
    return [];
  }
  if (customer.linkedAccounts) {
    return customer.linkedAccounts;
  }
  return API.get('billings', `${root}/${customer.divisionId || customer.id}/accounts`);
};

export const useMspCustomers = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.MSP_CUSTOMERS];

  return {
    reset: () => queryClient.resetQueries(queryKey),
    fetchMspCustomersData: (options, linkedAccounts) =>
      useQuery({
        queryKey: [...queryKey, JSON.stringify(linkedAccounts)],
        queryFn: () => fetchMspCustomers({ linkedAccounts}),
        staleTime: STALE_TIME,
        onError: () =>
          toast.error('Error fetching reseller customers data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        ...options,
      }),
    fetchCurrAccountCustomersData: (options) =>
      useQuery({
        queryKey: [...queryKey, 'current-account'],
        queryFn: fetchCurrentAccountCustomers,
        staleTime: STALE_TIME,
        onError: () =>
          toast.error('Error fetching reseller customers data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        ...options,
      }),
    fetchCustomerAccounts: (customer) =>
      useQuery({
        queryKey: [...queryKey, customer?.id || customer?.divisionId],
        queryFn: () => fetchCustomerAccounts(customer),
        onError: () =>
          toast.error('Error fetching reseller customer accounts', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        staleTime: STALE_TIME,
      }),
    fetchAvailableLinkedAccounts: () =>
      useQuery({
        queryKey: [...queryKey, 'linked-accounts'],
        queryFn: fetchAvailableLinkedAccounts,
        staleTime: STALE_TIME,
        onError: () =>
          toast.error('Error fetching reseller customer accounts', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    createCustomer: useMutation((data) => API.post('billings', root, { body: data }), {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        toast.success('Customer created successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: () =>
        toast.error('Error creating customer', {
          position: toast.POSITION.BOTTOM_RIGHT,
        }),
    }),
    deleteCustomer: useMutation((id) => API.del('billings', `${root}/${id}`), {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        toast.success('Customer deleted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: () =>
        toast.error('Error deleting customer', {
          position: toast.POSITION.BOTTOM_RIGHT,
        }),
    }),
    updateCustomer: useMutation((data) => API.put('billings', `${root}/${data.id}`, { body: data }), {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        toast.success('Customer updated successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: () =>
        toast.error('Error updating customer', {
          position: toast.POSITION.BOTTOM_RIGHT,
        }),
    }),
  };
};
