import React from 'react';
import SaveAsDashboardPanel from './saveAsDashboardPanel';
import ViewsPanel from './viewsPanel';
import SavingsTracker from './savingsTracker';

import classes from './heatMapActions.module.scss';
import CostMode from 'recommendationsNew/components/heatMap/heatMapActions/costMode.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { ACCOUNT_FEATURES, UsersType } from 'users/constants/usersConstants.js';
import checkFeatureFlag from 'shared/utils/featureFlagUtil.js';

const HeatMapActions = () => {
    const { usersStore } = useRootStore();
    const { currentDisplayedUserType } = usersStore;
    const isNetAmortizedSupported = checkFeatureFlag(
        usersStore,
        ACCOUNT_FEATURES.SUPPORT_NET_AMORTIZE,
    );
    return (
        <div className={classes.actions}>
            {isNetAmortizedSupported && ![UsersType.RESELLER_CUSTOMER, UsersType.RESELLER_CUSTOMER_EDP].includes(currentDisplayedUserType) && <CostMode/>}
            <SavingsTracker/>
            <ViewsPanel/>
            <SaveAsDashboardPanel/>
        </div>
    );
}
export default HeatMapActions;
