import React, { useState, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import ProcessingDataPanel from 'shared/components/ProcessingDataPanel';
import DateFilter from 'shared/modules/dateFilter';
import CustomDashboardWrapper from 'usage/containers/CustomDashboard/components/CustomDashboardWrapper';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useMainDBData } from 'app/hooks/react-query/useMainDBData';
import UsageCostComponents from './components/UsageCostComponent.jsx';
import Charts from './components/Chart.jsx';

const iconStyle = {
  color: '#727272',
  size: 16,
};
const actionButtonStyle = {
  display: 'inline-block',
  float: 'right',
};
const calcNumOfCostsComponentsWithData = (mainDashBoardData) => {
  let count = 1;
  if (mainDashBoardData) {
    count += mainDashBoardData.forecastedMonthEndTotalCost ? 1 : 0;
    count += mainDashBoardData.annualExpectedSavings ? 1 : 0;
    count += mainDashBoardData.prevMonthTotalCost ? 1 : 0;
  }

  return count === 0 ? 1 : 12 / count;
};

const DashboardPage = () => {
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType, currDispUserAccountKey } = usersStore;
  const { mainDbData, mainDbDataLoading } = useMainDBData(currDispUserCloudAccountType, currDispUserAccountKey);
  const [topServicesColumnWidth, setTopServicesColumnWidth] = useState(null);
  const [topServicesColumns, setTopServicesColumns] = useState(null);
  const [defaultDashboard, setDefaultDashboard] = useState(null);
  const { usageStore } = useRootStore();
  const { customDashboardModel } = usageStore.customDbSubStore;
  const { dashboards } = customDashboardModel;
  const { mainDashBoardData } = mainDbData || {};
  const currMonth = DateFilter.getCurrMonthAndYear().month;
  const currYear = DateFilter.getCurrMonthAndYear().year;
  const prevMonth = DateFilter.getPrevMonthAndYear().month;
  const prevYear = DateFilter.getPrevMonthAndYear().year;

  const isShowCurrVsPreviousMonthCost = mainDashBoardData?.preparedCostPerDay?.length > 0;
  const isShowYtdCostPerMonth = mainDashBoardData?.preparedCostPerMonth?.length > 0;
  const xlColSize = calcNumOfCostsComponentsWithData(mainDashBoardData);

  const colSize = 6;
  const setTopServiceColumns = () => {
    let topsServicesColumnWidthTemp = [];
    let topsServicesColumnTemp = [];
    if (mainDbData?.mainDashBoardData) {
      // TODO - return forecast data once it's ready
      const isForecastedExist = false;
      const isChangeExist = mainDbData.mainDashBoardData?.preparedTopServices.some((row) => row.change);
      topsServicesColumnWidthTemp = [
        { columnName: 'serviceName', width: 'auto' },
        { columnName: 'prevMonthCost', width: 'auto' },
        { columnName: 'mtdCost', width: 'auto' },
        { columnName: 'forecasted', width: 'auto' },
        { columnName: 'change', width: 'auto' },
      ];
      topsServicesColumnTemp = [
        { name: 'serviceName', title: 'Service', getCellValue: (row) => row.name },
        { name: 'prevMonthCost', title: 'Prev Month Cost', getCellValue: (row) => row['Previous month'] },
        { name: 'mtdCost', title: 'MTD Cost', getCellValue: (row) => row['Current month'] },
      ];
      if (isForecastedExist) {
        topsServicesColumnTemp.push({
          name: 'forecasted',
          title: 'Forecasted',
          getCellValue: (row) => row.forecasted && +row.forecasted.toFixed(1),
        });
        topsServicesColumnTemp.push({ columnName: 'forecasted', width: 'auto' });
      }
      if (isChangeExist && isForecastedExist) {
        topsServicesColumnTemp.push({
          name: 'change',
          title: 'Change',
          getCellValue: (row) => row.change && `${row.change.toFixed()}%`,
        });
      }
      setTopServicesColumnWidth(topsServicesColumnWidthTemp);
      setTopServicesColumns(topsServicesColumnTemp);
    }
  };
  const handleGoToCostExplorerReport = async (id) => {
    await usageStore.fetchReportDataOnNavigationToCostExplorer(id, mainDbData.mainDashBoardData);
  };

  useEffect(() => {
    if (!topServicesColumnWidth || !topServicesColumns) {
      setTopServiceColumns();
    }
  }, [mainDbData?.mainDashBoardData]);

  useEffect(() => {
    const dashboard = dashboards.find((d) => d.isDefault);
    setDefaultDashboard(dashboard);
  }, [dashboards]);

  if (
    mainDbDataLoading ||
    (mainDbData.mainDashboardData?.isCustomDefaultDbSet && customDashboardModel.modelIsLoading)
  ) {
    return <Spinner />;
  }

  return (
    <>
      {defaultDashboard ? (
        <CustomDashboardWrapper id={defaultDashboard.uuid} />
      ) : (
        <div>
          {!mainDashBoardData ? (
            <Container className="dashboard">
              <PageHeader title={PageNames.DASHBOARD} showDate />
              <Row>
                <ProcessingDataPanel />
              </Row>
            </Container>
          ) : (
            <Container className="dashboard">
              <PageHeader title={PageNames.DASHBOARD} showDate className="scale" />
              <Row className="scale">
                <UsageCostComponents
                  mainDashBoardData={mainDashBoardData}
                  xlColSize={xlColSize}
                  colSize={colSize}
                  iconStyle={iconStyle}
                />
              </Row>
              <Charts
                mainDashBoardData={mainDashBoardData}
                currMonth={currMonth}
                currYear={currYear}
                prevMonth={prevMonth}
                prevYear={prevYear}
                isShowCurrVsPreviousMonthCost={isShowCurrVsPreviousMonthCost}
                isShowYtdCostPerMonth={isShowYtdCostPerMonth}
                topServicesColumns={topServicesColumns}
                topServicesColumnWidth={topServicesColumnWidth}
                handleGoToCostExplorerReport={handleGoToCostExplorerReport}
                actionButtonStyle={actionButtonStyle}
                setTopServicesColumnWidth={setTopServicesColumnWidth}
              />
            </Container>
          )}
        </div>
      )}
    </>
  );
};

export default DashboardPage;
