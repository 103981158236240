import {
  buildStartAndEndDate,
  buildTimeDiffDateFromBaseDate,
  dateToStr,
  getCurrentQuarterStartAndEndDates,
  getStartEndDatesFromRelativeString,
} from 'shared/utils/dateUtil';
import {
  prepareDataToAssetsTable,
  prepareDataToCloudWasteTable,
  prepraeDataToCauCostChart,
  prepraeDataToCauCostTable,
  prepraeDataToCauResourceTable,
  prepraeDataToK8sCauCostTable,
} from 'shared/utils/dataPrepareUtil';
import { ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import { getAssetsData } from 'usage/hooks/react-query/useAssets';
import { getMainDBDataForPanel } from 'app/hooks/react-query/useMainDBData';
import { prepareRecsSummeryTableData } from 'recommendations/containers/Dashboard/helpers/customDashboardHelperMethods.js';
import { fetchHeatMapGroup } from 'recommendationsNew/hooks/react-query/useGroupByOption.js';
import { fetchWasteReport } from 'recommendationsNew/hooks/react-query/useWasteReport.js';
import { getBudgetById } from 'usage/containers/Budget/hooks/useBudgets';
import CustomDashboardActivePanel from './customDashboardActivePanel';
import * as dataPrepUtil from './dataPrepareUtils';


const mapRelativeDateDiffStringToDate = new Map([
  [
    ReportPeriodTime.LAST_YEAR,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.CURRENT_MONTH,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_MONTH,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_2_MONTH,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_3_MONTH,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_6_MONTH,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_12_MONTH,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_2_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_3_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_4_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_5_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_6_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_7_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.PREVIOUS_8_WEEK,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    ReportPeriodTime.YTD,
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
  [
    'currentQuarter',
    (periodTime, panelLastProcessTime) => getStartEndDatesFromRelativeString(periodTime, panelLastProcessTime),
  ],
]);

export default class CustomDashboardActivePanelBuilder {
  constructor(rootStore, recommendationsFuncs, filters) {
    this.rootStore = rootStore;
    this.recommendationsFuncs = recommendationsFuncs;
    this.filters = filters;
  }

  getFetchFuncByPanelType = (pType, route = null) => {
    let func = null;
    const { fetchMonthlyServicesBalance } = this.rootStore.invoiceStore;
    const { fetchK8sDataByState } = this.rootStore.kubernetesStore;
    const {
      qtdPileusSavings,
      getKpiQtdCoverageByService,
      getKpiMonthlyCostPerHour,
      getKpiQtdUtilizationByService,
      getKpiCashBasis,
      getKpiCoverageByPricingMethod,
      getKpiBusinessUnitCoverageData,
      getKpiSavingsOnDemand,
      getKpiCostPerHour,
      getKpieBusinessUnitReservationUtilization,
    } = this.rootStore.usageStore;
    const { fetchRecommendationOpportunities, fetchCustomDashboardPanelSummaryTableData } = this.recommendationsFuncs;

    switch (`${pType}${route ? `-${route}` : ''}`) {
      case 'cue':
        func = fetchMonthlyServicesBalance;
        break;
      case 'cue-table':
        func = fetchMonthlyServicesBalance;
        break;
      case 'cue-resource-table':
        func = fetchMonthlyServicesBalance;
        break;
      case 'cue-pie-chart':
        func = fetchMonthlyServicesBalance;
        break;
      case 'k8s-pie-chart':
        func = fetchK8sDataByState;
        break;
      case 'k8s':
        func = fetchK8sDataByState;
        break;
      case 'k8s-table':
        func = fetchK8sDataByState;
        break;
      case 'k8s-pod-details-table':
        func = fetchK8sDataByState;
        break;
      case 'k8s-usage':
        func = fetchK8sDataByState;
        break;
      case 'kpis-business-unit-coverage':
        func = getKpiBusinessUnitCoverageData;
        break;
      case 'kpis-pricing-method-coverage':
        func = getKpiCoverageByPricingMethod;
        break;
      case 'kpis-business-unit-reservation-utilization':
        func = getKpieBusinessUnitReservationUtilization;
        break;
      case 'kpis-cash-basis':
        func = getKpiCashBasis;
        break;
      case 'kpis-cost-per-hour':
        func = getKpiCostPerHour;
        break;
      case 'kpis-monthly-cost-per-hour':
        func = getKpiMonthlyCostPerHour;
        break;
      case 'kpis-service-monthly-cost-per-hour':
        func = getKpiMonthlyCostPerHour;
        break;
      case 'kpis-qtd-service-coverage':
        func = getKpiQtdCoverageByService;
        break;
      case 'kpis-qtd-service-utilization':
        func = getKpiQtdUtilizationByService;
        break;
      case 'kpis-saving-on-demand':
        func = getKpiSavingsOnDemand;
        break;
      case 'kpis-qtd-pileus-savings':
        func = qtdPileusSavings;
        break;
      case 'kpis-forecasted-monthly-cost':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-month-to-date-cost':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-region-activity-cost':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-daily-change-top-5-services-costs':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-month-to-date-top-5-services':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-cost-history':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-curr-vs-previous-month-cost':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-annual-savings':
        func = getMainDBDataForPanel;
        break;
      case 'kpis-previous-month-costs':
        func = getMainDBDataForPanel;
        break;
      case 'recomm-cloud-waste':
        func = fetchRecommendationOpportunities;
        break;
      case 'recomm-summery-table':
        func = fetchCustomDashboardPanelSummaryTableData;
        break;
      case 'assets-table':
        func = getAssetsData;
        break;
      case 'k8s-assets-table':
        func = getAssetsData;
        break;
      case 'budget-chart':
        func = getBudgetById;
        break;
      case 'saving-opportunities':
        func = fetchHeatMapGroup;
        break;
      case 'cloud-waste':
        func = fetchWasteReport;
        break;
      default:
        break;
    }
    return func;
  };

  getFetchParamsPrepFuncByPanelType = (pType, route = null) => {
    let func = null;
    switch (`${pType}${route ? `-${route}` : ''}`) {
      case 'cue':
        func = dataPrepUtil.costChartAlignParamsToFetchFunc;
        break;
      case 'cue-table':
        func = dataPrepUtil.costChartAlignParamsToFetchFunc;
        break;
      case 'cue-pie-chart':
        func = dataPrepUtil.costChartAlignParamsToFetchFunc;
        break;
      case 'cue-resource-table':
        func = dataPrepUtil.costChartAlignParamsToFetchFunc;
        break;
      case 'k8s-pie-chart':
        func = dataPrepUtil.k8sCostChartAlignParamsToFetchFunc;
        break;
      case 'k8s':
        func = dataPrepUtil.k8sCostChartAlignParamsToFetchFunc;
        break;
      case 'k8s-table':
        func = dataPrepUtil.k8sCostChartAlignParamsToFetchFunc;
        break;
      case 'k8s-pod-details-table':
        func = dataPrepUtil.k8sCostChartAlignParamsToFetchFunc;
        break;
      case 'k8s-usage':
        func = dataPrepUtil.k8sCostChartAlignParamsToFetchFunc;
        break;
      case 'recomm-cloud-waste':
        func = dataPrepUtil.cloudSavingsChartAlignParamsToFetchFunc;
        break;
      case 'recomm-summery-table':
        func = dataPrepUtil.recommSummeryTableAlignParamsToFetchFunc;
        break;
      case 'saving-opportunities':
        func = dataPrepUtil.savingOpportunitiesFetchFunc;
        break;
      case 'cloud-waste':
        func = dataPrepUtil.wasteReportFetchFunc;
        break;
      case 'assets-table':
        func = dataPrepUtil.assetsParamsToFetchFunc(false);
        break;
      case 'k8s-assets-table':
        func = dataPrepUtil.assetsParamsToFetchFunc(true);
        break;
      case 'kpis-qtd-service-utilization':
        func = dataPrepUtil.utilizationByServiceParamsToFetchFunc;
        break;
      case 'kpis-saving-on-demand':
        func = dataPrepUtil.savingOnDemandParamsToFetchFunc;
        break;
      default:
        // func = params => params;
        break;
    }
    return func;
  };

  getDataPrepFuncByPanelType = (pType, route = null) => {
    let func = null;
    switch (`${pType}${route ? `-${route}` : ''}`) {
      case 'cue':
        func = prepraeDataToCauCostChart;
        break;
      case 'cue-pie-chart':
        func = prepraeDataToCauCostChart;
        break;
      case 'k8s-pie-chart':
        func = prepraeDataToCauCostChart;
        break;
      case 'k8s':
        func = prepraeDataToCauCostChart;
        break;
      case 'k8s-usage':
        func = prepraeDataToCauCostChart;
        break;
      case 'cue-table':
        func = prepraeDataToCauCostTable;
        break;
      case 'cue-resource-table':
        func = prepraeDataToCauResourceTable;
        break;
      case 'k8s-table':
        func = prepraeDataToCauCostTable;
        break;
      case 'k8s-pod-details-table':
        func = prepraeDataToK8sCauCostTable;
        break;
      case 'k8s-usage-table':
        func = prepraeDataToK8sCauCostTable;
        break;
      case 'recomm-cloud-waste':
        func = prepareDataToCloudWasteTable;
        break;
      case 'recomm-summery-table':
        func = prepareRecsSummeryTableData;
        break;
      case 'assets-table':
        func = prepareDataToAssetsTable;
        break;
      case 'k8s-assets-table':
        func = prepareDataToAssetsTable;
        break;
      case 'kpis-qtd-service-coverage':
        func = dataPrepUtil.prepareDataToKpiHalfPieChart;
        break;
      case 'kpis-qtd-service-utilization':
        func = dataPrepUtil.prepareDataToKpiHalfPieChart;
        break;
      default:
        func = (data) => data;
        break;
    }
    return func;
  };

  buildDefaultDatesBasedOnQueryParams = (params, accountId) => {
    let { startDate, endDate } = buildStartAndEndDate(null, null);
    const { periodType, relativeDatesDaysDiff, granularity } = params;
    if (periodType === 'relativeDate') {
      const userAccounts = this.rootStore.usersStore.getAllCurrDisplayedUserAccounts();
      const panelAccount = userAccounts.find((account) => account.accountId === accountId);
      if (
        relativeDatesDaysDiff === ReportPeriodTime.LAST_YEAR ||
        relativeDatesDaysDiff === ReportPeriodTime.CURRENT_MONTH ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_MONTH ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_2_MONTH ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_3_MONTH ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_6_MONTH ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_12_MONTH ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_2_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_3_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_4_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_5_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_6_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_7_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.PREVIOUS_8_WEEK ||
        relativeDatesDaysDiff === ReportPeriodTime.YTD ||
        relativeDatesDaysDiff === 'currentQuarter'
      ) {
        const { start, end } = mapRelativeDateDiffStringToDate.get(relativeDatesDaysDiff)(
          relativeDatesDaysDiff,
          panelAccount.getLastProcessTime(),
        );
        startDate = start;
        endDate = end;
      } else if (granularity === 'currQuarter') {
        const { startDate: start, endDate: end } = getCurrentQuarterStartAndEndDates();
        startDate = start;
        endDate = end;
      } else {
        endDate = panelAccount.getLastProcessTime() ? dateToStr(panelAccount.getLastProcessTime(), 'yyyy-mm-dd') : null;
        startDate = buildTimeDiffDateFromBaseDate(endDate, -parseInt(relativeDatesDaysDiff - 1, 10), 'd').startDate;
      }
    }
    if (params.periodType === 'snapShot') {
      ({ endDate, startDate } = params.routeParams);
    }
    return { startDate, endDate, granularity: params.granularity };
  };

  buildActiveDashboardInstance = (panel, panelDisplaySettings, defaultPanelIndex, dashboardId, fromTemplate) => {
    const { getCurrDisplayedAccount } = this.rootStore.usersStore;
    const panelAccountId = !fromTemplate && panel?.accountId ? panel.accountId : getCurrDisplayedAccount.accountId;
    const { startDate, endDate, granularity } = panel.periodParams
      ? this.buildDefaultDatesBasedOnQueryParams(panel.periodParams, panelAccountId)
      : {};
    const activeDashboardParams = {
      fetchFunc: this.getFetchFuncByPanelType(panel.type, panel.route),
      fetchParamsPrepFunc: this.getFetchParamsPrepFuncByPanelType(panel.type, panel.route, this.filters),
      dataPrepFunc: this.getDataPrepFuncByPanelType(panel.type, panel.route),
      chartType: panel.chartType,
      startDate,
      endDate,
      granularity,
    };
    return new CustomDashboardActivePanel(
      panel,
      activeDashboardParams,
      panelDisplaySettings,
      defaultPanelIndex,
      this.rootStore,
      dashboardId,
      fromTemplate,
      this.filters,
    );
  };

  buildActivePanels = () => {};
}
