import React from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import PropTypes from 'prop-types';
import PayerAccountsTable from './PayerAccountsTable';

import styles from './LinkedAccountsComponent.module.scss';

const PayerAccounts = ({
  additionalData,
  accountsData,
  cloudProviderId,
  expandedRows,
  isCreateEditMode,
  linkedAccountsColumns,
  modifiedSelectionById,
  onExpandedRowsChange,
  onRemoveLinkedAccount,
  onSelectionChange,
  payerAccountsColumns,
  entity,
}) => {
  const { NewTableWrapper } = useTable();

  return (
    <div className={styles.payerAccountsContainer}>
      <NewTableWrapper>
        <PayerAccountsTable
          entity={entity}
          accountsData={accountsData}
          additionalData={additionalData}
          cloudProviderId={cloudProviderId}
          expandedRows={expandedRows}
          isCreateEditMode={isCreateEditMode}
          linkedAccountsColumns={linkedAccountsColumns}
          modifiedSelectionById={modifiedSelectionById}
          onExpandedRowsChange={onExpandedRowsChange}
          onRemoveLinkedAccount={onRemoveLinkedAccount}
          onSelectionChange={onSelectionChange}
          payerAccountsColumns={payerAccountsColumns}
        />
      </NewTableWrapper>
    </div>
  );
};

PayerAccountsTable.propTypes = {
  accountsData: PropTypes.array.isRequired,
};

export default PayerAccounts;
