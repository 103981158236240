import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import {getDashboardFilter, transformKeys} from 'recommendationsNew/consts';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';

const NewRecommendationDashboardContext = (props) => {
  const {
    setRecommendationFilters: setFiltersContext,
    setRecommendationExternalFilters: setExternalFiltersContext,
    setIsDashboardPanel,
    setPanelCloudType,
  } = useRecommendationsNewContext();
  const { setHeatMapSortByOption, setHeatMapGroupByOptions } = useHeatMapContext();
  const { filterState, cloudType } = props;
  const { filterContext: panelFilters, sortBy, groupBy } = filterState || {};
  const { usageStore } = useRootStore();
  const getDashboardIdFromLocation = () => {
    const { id: idFromProps, match } = props;
    if (idFromProps) {
      return idFromProps;
    }
    const { id } = match.params;
    return id;
  };

  useEffect(() => {
    const dashboardId = getDashboardIdFromLocation();
    if (dashboardId) {
      const dashFilters = usageStore.customDbSubStore.customDashboardModel.getExistingDashboardByUuid(dashboardId, false) || {};
      const dashboardFilters = getDashboardFilter(dashboardId, dashFilters.filters);
      setExternalFiltersContext(dashboardFilters);
      if (panelFilters) {
        setFiltersContext(transformKeys(panelFilters));
      }
    }
    setHeatMapSortByOption(sortBy);
    setHeatMapGroupByOptions(groupBy);
    setIsDashboardPanel(true);
    setPanelCloudType(cloudType);
  }, []);

  return null;
};

NewRecommendationDashboardContext.propTypes = {
    filterState: PropTypes.object.isRequired,
    cloudType: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  id: PropTypes.string,
};

export default withRouter(observer(NewRecommendationDashboardContext));
