import { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

class UrlStateComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getInitialUrlState(), // UrlStateComponent state
      ...(this.state || {}), // Child component state, if exists
    };
  }

  componentDidMount() {
    this.urlStateComponentDidMount();
  }

  componentWillUnmount() {
    this.urlStateComponentWillUnmount();
  }

  getInitialUrlState() {
    return {
      isCtrlPressed: false,
    };
  }

  // UrlStateComponent's componentDidMount logic
  urlStateComponentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
    window.addEventListener('blur', this.handleKeyUp);
  }

  // UrlStateComponent's componentWillUnmount logic
  urlStateComponentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('blur', this.handleKeyUp);
  }

  handleKeyDown = (event) => {
    if (event.ctrlKey || event.metaKey) {
      // Use setTimeout with 0 ms delay to delay state update
      setTimeout(() => {
        this.setState({ isCtrlPressed: true });
      }, 0);
    }
  };

  handleKeyUp = () => {
    if (this.state.isCtrlPressed) {
      // Use setTimeout with 0 ms delay to delay state update
      setTimeout(() => {
        this.setState({ isCtrlPressed: false });
      }, 0);
    }
  };

  getMemoUrlState = (() => {
    const memoSearch = {};
    return (location) => {
      if (memoSearch[location.search]) {
        return memoSearch[location.search];
      }
      const searchParams = new URLSearchParams(location.search);
      const res = {};
      [...searchParams.keys()].forEach((key) => {
        try {
          if (searchParams.get(key) === 'undefined' || searchParams.get(key) === 'null') {
            res[key] = undefined;
          } else {
            res[key] = JSON.parse(searchParams.get(key));
          }
        } catch {
          res[key] = searchParams.get(key);
        }
      });
      memoSearch[location.search] = res;
      return res;
    };
  })();
  getUrlState = () => {
    const { location } = this.props;
    return {
      ...this.state,
      ...this.getMemoUrlState(location),
    };
  };
  setUrlState = (newState) => {
    const newStateParams = typeof newState === 'function' ? newState(this.getUrlState()) : newState;
    this.splitDataBetweenURLAndInternalState(newStateParams);
  };

  splitDataBetweenURLAndInternalState = (newState, isInitMount) => {
    const { history, location } = this.props;
    const urlKeys = Object.keys(this.baseState);
    const internalState = {};
    const urlState = {
      ...this.getMemoUrlState(location),
    };
    let someUrlStateChanged = false;
    Object.keys(newState).forEach((key) => {
      if (urlKeys.includes(key)) {
        if (!isEqual(urlState[key], newState[key])) {
          someUrlStateChanged = true;
        }
        urlState[key] = newState[key];
      } else {
        internalState[key] = newState[key];
      }
    }, {});
    if (someUrlStateChanged) {
      const paramsObj = Object.keys(urlState).reduce(
        (acc, item) => ({
          ...acc,
          [item]: JSON.stringify(urlState[item]),
        }),
        {},
      );
      if (this.state.isCtrlPressed) {
        const newUrl = `${location.pathname}?${new URLSearchParams(paramsObj).toString()}`;
        window.open(newUrl, '_blank');
      } else {
        history.replace({ pathname: location.pathname, search: new URLSearchParams(paramsObj).toString() });
      }
    }
    if (isInitMount) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = internalState;
    } else {
      this.setState(() => ({
        ...internalState,
      }));
    }
  };
  changeAccountAndScopeFromURL = async (isMount) => {
    const { accountKey, cloudAccountTypeId, divisionId, divisionName, isPpApplied } = this.getUrlState();
    const { usersStore, appStore } = this.props;
    const { updateCurrDisplayedDivIdAndName, changeCustomerUserType, rootStore } = usersStore;
    const { currDispUserAccountKey, currDispUserDivisionId } = usersStore;
    if (accountKey && cloudAccountTypeId !== undefined) {
      if (accountKey !== currDispUserAccountKey) {
        await usersStore.handleDisplayedAccountChange(accountKey, cloudAccountTypeId);
      }
    }
    if (isPpApplied !== undefined) {
      if (isPpApplied !== appStore.isPpApplied) {
        if (isMount) {
          rootStore.appStore.updatePricingMode(isPpApplied);
        } else {
          this.setUrlState({ isPpApplied: appStore.isPpApplied });
        }
      }
    }
    if (divisionId !== undefined) {
      if (divisionId !== currDispUserDivisionId) {
        changeCustomerUserType();
        rootStore.appStore.updatePricingMode(true);
        updateCurrDisplayedDivIdAndName(divisionId, divisionName, accountKey);
        rootStore.invalidateStores();
      }
    }
  };
  render() {
    return null;
  }
}

UrlStateComponent.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
};

export default UrlStateComponent;
