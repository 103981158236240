import { cloneDeep, uniqBy } from 'lodash';

export const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter((r) => r.parentId === (row ? row.id : null));
  return childRows.length ? childRows : null;
};

export const findFilteredData = (data, search, parentId) => {
  const children = data.filter((cc) => cc.parentId === parentId);
  if (children.length === 0) {
    return [];
  } else {
    return children.reduce((acc, cc) => {
      const filteredChildren = findFilteredData(data, search, cc.id);
      if (cc.name.toLowerCase().includes(search.toLowerCase()) || filteredChildren.length > 0) {
        return [...acc, cc, ...filteredChildren];
      }
      return acc;
    }, []);
  }
};

export const getCostCenterAccountsData = (accountsData, costCenterAccountsData, isCreateEditMode) => {
  const combinedCostCenterAccountsData = [];
  costCenterAccountsData?.fullyDedicatedPayerAccounts.forEach((pa) => {
    const payerAccount = accountsData.find((account) => account.payerAccount.id === pa.id);
    if (payerAccount) {
      const laCount = payerAccount.linkedAccounts.length;
      const tmpPayerAccount = {
        ...payerAccount,
        payerAccount: {
          ...payerAccount.payerAccount,
          isSelectedInitial: !!isCreateEditMode,
          isSelectionEnabled: true,
          isAssignedByCurrentEntity: true,
        },
      };
      payerAccount.linkedAccounts.forEach((la) => {
        la.isPayerFullyAssigned = true;
      });
      combinedCostCenterAccountsData.push({
        ...tmpPayerAccount,
        totalPayerLinkedAccounts: laCount,
        totalSelectedPayerLinkedAccounts: laCount,
      });
    }
  });

  const groupedByPayer = {};
  costCenterAccountsData?.linked_accounts.forEach((item) => {
    const payerAccountId = item.payerAccountId;
    const linkedAccountId = item.linked_account.id;

    if (!groupedByPayer[payerAccountId]) {
      groupedByPayer[payerAccountId] = [];
    }
    groupedByPayer[payerAccountId].push(linkedAccountId);
  });
  Object.entries(groupedByPayer)?.forEach(([payerAccountId, linkedAccountIds]) => {
    const payerAccount = accountsData.find((account) => account.payerAccount.id === payerAccountId);
    if (payerAccount) {
      const selectedLinkedAccounts = payerAccount.linkedAccounts
        .filter((la) => linkedAccountIds.includes(la.id))
        .map((la) => ({
          ...la,
          isSelectedInitial: !!isCreateEditMode,
          isSelectionEnabled: true,
          isAssignedByCurrentEntity: true,
        }));
      combinedCostCenterAccountsData.push({
        ...payerAccount,
        linkedAccounts: selectedLinkedAccounts,
        totalPayerLinkedAccounts: payerAccount.linkedAccounts.length,
        totalSelectedPayerLinkedAccounts: selectedLinkedAccounts.length,
      });
    }
  });

  if (isCreateEditMode) {
    accountsData.forEach((account) => {
      const isPayerAccountSelected = combinedCostCenterAccountsData.find(
        (cc) => cc.payerAccount.id === account.payerAccount.id,
      );
      if (!isPayerAccountSelected) {
        const laCount = account.linkedAccounts.length;
        const tmpPayerAccount = {
          ...account,
          payerAccount: {
            ...account.payerAccount,
            isSelectedInitial: false,
            isSelectionEnabled: true,
            isAssignedByCurrentEntity: false,
          },
        };
        combinedCostCenterAccountsData.push({
          ...tmpPayerAccount,
          totalPayerLinkedAccounts: laCount,
          totalSelectedPayerLinkedAccounts: 0,
        });
      }
    });
  }
  return combinedCostCenterAccountsData;
};

function enrichCostCenters(
  costCentersData,
  costCenterDataAccess,
  modifiedSelectionById,
  currentRoleId,
  isViewDataAccessOnly,
) {
  const copyOfCostCentersData = cloneDeep(costCentersData);
  costCenterDataAccess.forEach((roleDataAccess) => {
    let assignedCostCenterIds = roleDataAccess?.costCenters?.items;
    const modifiedSelectionIds = modifiedSelectionById ? Object.keys(modifiedSelectionById) : [];
    if (modifiedSelectionIds.length) {
      const newSelectedIds = modifiedSelectionIds.filter((key) => modifiedSelectionById[key] === true);
      assignedCostCenterIds = [...(roleDataAccess?.costCenters?.items || []), ...newSelectedIds];
    }
    assignedCostCenterIds?.forEach((assignedCostCenterId) => {
      if (modifiedSelectionById) {
        if (modifiedSelectionById[assignedCostCenterId] === false) {
          return;
        }
      }
      const costCenter = copyOfCostCentersData.find((cc) => cc.costCenter.id === assignedCostCenterId);
      if (costCenter) {
        if (isViewDataAccessOnly) {
          // Here we go through the first level of role data access, so in order to show this part only - setting parent to null.
          costCenter.costCenter.parentCcId = null;
        }
        costCenter.costCenter.isAllowed = true;
        costCenter.costCenter.isDirectlyAssigned =
          costCenter.costCenter.isDirectlyAssigned || currentRoleId === roleDataAccess.derivedFromRole.id;
        if (!costCenter.costCenter.assignedByRoles) {
          costCenter.costCenter.assignedByRoles = [];
        }
        costCenter.costCenter.assignedByRoles =
          costCenter.costCenter.isDirectlyAssigned === true
            ? []
            : [...costCenter.costCenter.assignedByRoles, roleDataAccess.derivedFromRole];
      }
    });
  });
  return uniqBy(copyOfCostCentersData, 'costCenter.id');
}

function mutateAddEffectiveIsAllowed(parentCostCenters) {
  parentCostCenters.forEach((parentCostCenter) => {
    const parent = parentCostCenter.costCenter;
    const children = parentCostCenters.filter((cc) => cc.costCenter.parentCcId === parent.id);

    if (parent.isAllowed || parent.effectiveIsAllowed) {
      for (const child of children) {
        child.costCenter.effectiveIsAllowed = true;
      }
    }

    if (children.length > 0) {
      mutateAddEffectiveIsAllowed(children);
    }
  });
}

export const getCostCentersDataAccessCombinedData = (
  costCentersData,
  costCenterDataAccess,
  currentRoleId,
  modifiedSelectionById,
  isViewDataAccessOnly,
) => {
  if (costCenterDataAccess.find((roleDataAccess) => roleDataAccess.costCenters.all === true)) {
    return costCentersData;
  }
  const enrichedCostCenters = enrichCostCenters(
    costCentersData,
    costCenterDataAccess,
    modifiedSelectionById,
    currentRoleId,
    isViewDataAccessOnly,
  );
  mutateAddEffectiveIsAllowed(enrichedCostCenters);

  return enrichedCostCenters;
};

export const mergeCostCentersDataWithDataAccess = (costCentersData, roleDataAccessCostCentersData) => {
  const subGroupIds = new Set(roleDataAccessCostCentersData.map((cc) => cc.costCenter.id));
  const remainingOriginals = costCentersData.filter((cc) => !subGroupIds.has(cc.costCenter.id));

  return [...roleDataAccessCostCentersData, ...remainingOriginals];
};
