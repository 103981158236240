import React from 'react';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import {
  CUSTOMER_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS,
} from '../../../consts';
import LinkedAccountsTable from 'users/containers/Organization/components/LinkedAccounts/LinkedAccountsTable.jsx';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import styles from './AccountCustomerExpanded.module.scss';
import { useMspCustomers } from 'users/new-user-management/hooks/reactQuery/useMspCustomers.js';
import Spinner from 'shared/components/andtComponents/Spinner.jsx';
import {ReactComponent as NoResults } from '../../img/no-results.svg';

const Tab = ({ customer, accountId }) => {
  const { fetchCustomerAccounts } = useMspCustomers();
  const { data, isLoading } = fetchCustomerAccounts(customer);
  const { NewTableWrapper } = useTable();
  if (isLoading) {
    return <div className={styles.spinner}><Spinner /></div>;
  }
  return (
    <div>
      <NewTableWrapper
        className="sub-table"
        isCompact
      >
        <LinkedAccountsTable
          entity="customer"
          accountsData={data.linked_accounts.filter(ln => ln.payerAccountId === accountId).map(a => ({
            ...a.linked_account,
            isNotAvailable: true,
          }))}
          isFullyAssignedByPayer={false}
          linkedAccountsColumns={[
            CUSTOMER_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS.LINKED_ACCOUNT,
          ]}
          onRemoveLinkedAccount={() => {
          }}
          selectedRowsTree={[]}
          selectionTreePath={[]}
          modifiedSelectionById={{}}
        />
      </NewTableWrapper>
    </div>
  );
};

const AccountCustomerExpanded = ({ customers, accountId }) => {
  const [selectedTab, setSelectedTab] = React.useState(customers[0]?.id);

  const onTabClick = (tab) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab);
    }
  };
  const isTabActive = (id) => id === selectedTab;
  const customer = customers.find((c) => c.id === selectedTab);
  if (!customers.length) {
    return (
      <div className={styles.customerContainerEmpty}>
        <NoResults />
        Account has no customers yet
      </div>
    )
  }
  return (
    <div className={styles.customerContainer}>
      <div className={styles.menu}>
        <div className={styles.customerTabs}>
          {customers.map((customer) => (
            <div
              className={classNames(styles.customerTab, { [styles.isActive]: isTabActive(customer.id) })}
              onClick={() => onTabClick(customer.id)}
              key={customer.id.id}
            >
              <div className={styles.title}>
                {customer.name}
              </div>
              <div className={classNames(styles.selectionIndicator, { [styles.isActive]: isTabActive(customer.id) })}>
                {isTabActive(customer.id) ? (
                  <GenerateIcon
                    iconName={ICONS.caretRight.name}
                    className={styles.indicator}
                  />
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.side} />
      </div>
      <div className={styles.data}><Tab customer={customer} accountId={accountId} /></div>
    </div>
  );
};

export default AccountCustomerExpanded;
