export const prepareRecommendationsFiltersMap = (filtersArr) => {
  const filterMap = new Map([]);
  if (filtersArr && filtersArr.length) {
    filtersArr.forEach((filter) => {
      filterMap.set(filter[0], filter[1]);
    });
  }
  return filterMap;
};

export const prepareFiltersMap = (filtersMap = '') => {
  const prepFilterMap = new Map();
  if (filtersMap) {
    filtersMap.forEach((values, key) => {
      const prepValue = values ? values.map(({ value }) => value) : [];
      const prepKey = key === 'linkedaccname' ? 'linkedaccid' : key;
      prepFilterMap.set(prepKey, prepValue);
    });
  }
  return prepFilterMap;
};
