import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import classnames from 'classnames';
import { getLinkedAccountAssignedBy, getPayerAssignedBy, STATES } from './dataAccessHelperFunctions';

import styles from './AssignedBy.module.scss';

const DIRECT = [{ title: 'DIRECT', icon: ICONS.right.name }];

const AssignedBy = ({ payerAccount, linkedAccount, modifiedSelectionById, isCreateEditMode, entity }) => {
  const account = linkedAccount ?? payerAccount;

  const accountAssignment = linkedAccount
    ? getLinkedAccountAssignedBy(
        linkedAccount,
        modifiedSelectionById[payerAccount?.id],
        modifiedSelectionById[linkedAccount?.id],
        entity,
      )
    : getPayerAssignedBy(payerAccount, isCreateEditMode, modifiedSelectionById[payerAccount?.id], entity);

  const assignedByList = useMemo(() => {
    if (accountAssignment === STATES.DIRECT) {
      return DIRECT;
    }
    if (accountAssignment === STATES.ROLE) {
      const derivedFrom = account?.derivedFrom;
      const roles = derivedFrom?.filter((item) => item.roleId).map((item) => item.roleId);
      const costCenters = derivedFrom?.filter((item) => item.costCenterId).map((item) => item.costCenterId);
      const assignedData = [
        { title: 'ROLE', icon: ICONS.userGearSolid.name, data: roles },
        { title: 'COST CENTER', icon: ICONS.listTree.name, data: costCenters },
      ];
      return assignedData.filter((item) => item.data?.length > 0);
    }
    return null;
  }, [account?.derivedFrom, accountAssignment]);

  const getTooltipContent = (items) =>
    accountAssignment !== STATES.DIRECT && items?.length ? (
      <div className="d-flex flex-column">
        {items.map((item) => (
          <div className="small" key={item}>
            {item}
          </div>
        ))}
      </div>
    ) : null;

  const onClick = (e) => {
    e.stopPropagation();
  };

  if ((!payerAccount && !linkedAccount) || !modifiedSelectionById) {
    return undefined;
  }
  return assignedByList?.length ? (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.title}>Assigned By</div>
      {assignedByList.map((item) => (
        <div className={styles.assignedByContainer} key={item.title}>
          <Tooltip disabled={item.data?.length === 0} title={getTooltipContent(item.data)} placement="top">
            <div
              className={classnames(styles.assignedBy, {
                [styles.isDisabled]: accountAssignment !== STATES.DIRECT,
              })}
            >
              <GenerateIcon iconName={item.icon} className={styles.assignedByIcon} />
              {item.title}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  ) : (
    <div />
  );
};

AssignedBy.propTypes = {
  entity: PropTypes.string,
  isCreateEditMode: PropTypes.bool,
  linkedAccount: PropTypes.object,
  modifiedSelectionById: PropTypes.object,
  payerAccount: PropTypes.object,
};

export default AssignedBy;
