import React from 'react';
import PropType from 'prop-types';
import { ReactComponent as NoCostCenters } from 'users/containers/Organization/CostCenters/img/no-cost-centers.svg';

import styles from './CostCentersNoData.module.scss';

const CostCentersNoData = ({ colSpan, mainText, subText }) => {
  return (
    <td colSpan={colSpan} className="border-bottom-0 border-start-0 border-end-0 border-top-0">
      <div className={styles.costCentersEmptyState}>
        <NoCostCenters className={styles.svgImage} />
        <div className={styles.emptyStateText}>
          <span className={styles.mainText}>{mainText || 'No Cost Centers found'}</span>
          <span className={styles.text}>{subText || 'There are no Cost Centers matching your search'}</span>
        </div>
      </div>
    </td>
  );
};

CostCentersNoData.propTypes = {
  colSpan: PropType.number,
  mainText: PropType.string,
  subText: PropType.string,
};

export default CostCentersNoData;
