import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SortingState,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableColumnResizing,
  TableFilterRow,
  TableFixedColumns,
  TableHeaderRow,
  TableSummaryRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import { numberWithCommas } from 'shared/utils/strUtil';
import { CostTrackingConstants, DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;
FilterIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

const getCellColor = (value) => {
  try {
    const valueAsNumber = +value.split('(')[0].replace('$', '').replace('K', '').replace('M', '');
    let color = 'white';
     
    if (valueAsNumber > 0) {
      color = '#d99694';
    } else if (valueAsNumber <= 0) {
      color = '#c3d69b';
    }
    return color;
  } catch {
    return 'white';
  }
};
const TrendCell = ({ value, style, ...restProps }) => (
  <Table.Cell
    {...restProps}
    style={{
      backgroundColor: getCellColor(value),
      ...style,
    }}
  >
    <span>{value}</span>
  </Table.Cell>
);

TrendCell.propTypes = {
  value: PropTypes.string.isRequired,
  style: PropTypes.object,
};

TrendCell.defaultProps = {
  style: {},
};

const Cell = (props) => {
  const { row } = props;
  try {
    if (row.groupBy?.includes('Trend')) {
      return <TrendCell {...props} />;
    }
    return <Table.Cell {...props} />;
  } catch {
    return <Table.Cell {...props} />;
  }
};

class FilterTable extends Component {
  static propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    renderedColumnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    displayedMetric: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    handleTableColumnWidthChange: PropTypes.object.isRequired,
    totalSummaryItems: PropTypes.array.isRequired,
    setSelectedPageSize: PropTypes.func.isRequired,
    selectedUsageType: PropTypes.string.isRequired,
    isUsageChecked: PropTypes.bool,
    isShowSummaryRow: PropTypes.bool,
    isTableTrendRow: PropTypes.bool,
    tableId: PropTypes.string,
    isGranLevelWeekly: PropTypes.bool,
    isGroupByDate: PropTypes.bool,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isShowSummaryRow: true,
    isTableTrendRow: false,
    isUsageChecked: false,
    tableId: null,
    isGranLevelWeekly: false,
    isGroupByDate: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      leftColumns: ['date'],
      pageSizes: [10, 15, 20, 25, 0],
      totalSummaryItems: props.totalSummaryItems,
    };
  }

  componentDidMount() {
    const { setSelectedPageSize } = this.props;
    if (setSelectedPageSize) {
      setSelectedPageSize(15);
    }
  }

  getFormatterComponentByUsageType = (usageType) => {
    if (usageType === 'Bytes') {
      const bytesFormatter = (value) => (value.value === 0 ? '-' : `${numberWithCommas(value.value, 2)} GB`);
      return bytesFormatter;
    }
    return this.numberFormatter;
  };

  getFormatterComponent = (isUsage, usageType) => {
    if (isUsage) {
      return this.getFormatterComponentByUsageType(usageType);
    }
    return this.numberFormatter;
  };
  messages = {
    sum: ' ',
  };
  localCostStrFromString = (strValue) => {
    const { getCurrencyNumber, displayedMetric } = this.props;
    if (displayedMetric === DisplayMetricTypes.COST) {
      return `${getCurrencyNumber(strValue)}`;
    }
    return strValue;
  };

  numberFormatter = (value) =>
    value.value === 0 ? '-' : this.localCostStrFromString(numberWithCommas(value.value, 2));

  changeColumnWidths = (columnWidths) => {
    this.props.handleTableColumnWidthChange([...columnWidths]);
  };

  formatDates = (dateObj) => {
    const { name: date } = dateObj.props.column;
    const [, week, year] = date.split('-');
    if (!year) {
      return {};
    }
    const weekDateStart = moment()
      .day('Monday')
      .year(year)
      .isoWeek(+week)
      .format('MM-DD');
    const weekDateEnd = moment()
      .day('Sunday')
      .year(year)
      .isoWeek(+week)
      .format('MM-DD');
    return { weekDateStart, weekDateEnd };
  };

  tableHeaderContent = ({ column, children, ...restProps }) => {
    const { isGranLevelWeekly, isGroupByDate } = this.props;
    if (isGroupByDate && isGranLevelWeekly && column.name !== CostTrackingConstants.DATA_LEVEL_TYPE_GROUP_BY) {
      const { weekDateStart, weekDateEnd } = this.formatDates(children);
      return (
        <TableHeaderRow.Content column={column} {...restProps}>
          <div>
            <div>{children}</div>
            {weekDateStart || weekDateEnd ? (
              <p className="cue-table-weekly-date-header">{`${weekDateStart} - ${weekDateEnd}`}</p>
            ) : null}
          </div>
        </TableHeaderRow.Content>
      );
    }
    return (
      <TableHeaderRow.Content column={column} {...restProps}>
        {children}
      </TableHeaderRow.Content>
    );
  };

  render() {
    const {
      rows,
      columns,
      renderedColumnWidths: columnWidths,
      tableColumnExtensions,
      defaultHiddenColumnNames: hiddenColumnNames,
      isTableTrendRow,
      isShowSummaryRow,
      setSelectedPageSize,
      isUsageChecked,
      selectedUsageType,
      tableId,
    } = this.props;
    const { leftColumns, numericFilterOperations, totalSummaryItems, pageSizes } = this.state;
    const arrOfColumnNames = columns
      .map((col) => col.name)
      .filter((name) => name !== 'groupBy' && name !== 'linkedAccountId');
    return (
      <div className="w-100" style={{ marginTop: '35px' }} id={tableId}>
        <Col xs={12} md={12} lg={12} xl={12} style={{ marginTop: '5px' }}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <FilteringState defaultFilters={[{}]} />
              <IntegratedFiltering />
              <SortingState
                defaultSorting={[
                  { columnName: 'totalCost', direction: 'desc' },
                  {
                    columnName: 'totalUsage',
                    direction: 'desc',
                  },
                ]}
              />
              <IntegratedSorting />
              <PagingState
                defaultCurrentPage={0}
                defaultPageSize={20}
                onPageSizeChange={(size) => setSelectedPageSize(size)}
              />
              <IntegratedPaging />
              {isShowSummaryRow && !isTableTrendRow && <SummaryState totalItems={totalSummaryItems} />}
              {isShowSummaryRow && !isTableTrendRow && <IntegratedSummary />}
              <DataTypeProvider
                for={arrOfColumnNames}
                formatterComponent={this.getFormatterComponent(isUsageChecked, selectedUsageType)}
                availableFilterOperations={numericFilterOperations}
              />
              <TableWrapper cellComponent={Cell} columnExtensions={tableColumnExtensions} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              {isShowSummaryRow && !isTableTrendRow && <TableSummaryRow messages={this.messages} />}
              <TableHeaderRow contentComponent={this.tableHeaderContent} showSortingControls />
              <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
              <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
              <PagingPanel pageSizes={pageSizes} />
              <Toolbar />
              <ColumnChooser />
              <TableFixedColumns leftColumns={leftColumns} />
            </Grid>
          </Paper>
        </Col>
      </div>
    );
  }
}

const ObserverFilterTable = withUserSettingsConsumer(FilterTable);
export default ObserverFilterTable;
