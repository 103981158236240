import React, { useContext } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRecommendationsNewContext } from '../../../contexts/recommendationsNewContext';
import HeatMapActionsContext from '../contexts/heatMapActionsContext';
import { useHeatMapContext } from '../contexts/heatMapContext';

import classes from './heatMapActions.module.scss';
import {FILTERS, STATUS_FILTER_PRESETS} from 'recommendationsNew/consts.js';

const SavingsTracker = () => {
  const { recommendationFilters, setRecommendationFilters } = useRecommendationsNewContext();
  const { setIsSavingsTracker } = useContext(HeatMapActionsContext);
  const { isWasteChart } = useHeatMapContext();

  const handleClick = () => {
    if (isWasteChart) {
      return;
    }
    setIsSavingsTracker(true);
    setRecommendationFilters({
      ...recommendationFilters,
      [FILTERS.STATUS_FILTER.id]: STATUS_FILTER_PRESETS.ACTUAL_SAVINGS.id,
      [FILTERS.IS_OPEN.id]: false
    });
  };

  return (
    <Tooltip title="Savings Tracker (History)" disabled={isWasteChart}>
      <div className={`${classes.actionsPanelButton} ${isWasteChart ? classes.isDisabled : ''}`} onClick={handleClick}>
        <GenerateIcon iconName={ICONS.sackDollar.name} automation-id="savings-tracker" />
      </div>
    </Tooltip>
  );
};

export default SavingsTracker;
