import React, { memo } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const Icon = memo((props) => {
  const styles = {
    img: {
      width: `${props.size}`,
      height: props.matchHeight ? `${props.height || props.size}` : '100%',
      // height: `${props.size}`,
    },
  };
  if (props.icon && typeof props.icon !== 'string') {
    if (!React.isValidElement(props.icon)) {
      const IconComp = props.icon;
      return <IconComp />;
    }
    return props.icon;
  }
  return <img className={props.className} style={styles.img} src={props.icon} alt="img" />;
});

Icon.propTypes = {
  size: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
  matchHeight: PropTypes.bool,
};

Icon.defaultProps = {
  size: 23,
  height: null,
  className: '',
  matchHeight: false,
};

export default Icon;
