import moment from 'moment';
import { isOperatorInput } from 'shared/constants/appConstants';

const updateIfMarginTypeCustomAggPercent = (marginType, pricingParams) => {
  if (marginType !== 'custom-service-calc-cost') {
    return pricingParams;
  }
  const newPricingParams = { ...pricingParams };
  const isAllServices = pricingParams.services.some((val) => val === 'ALL');
  newPricingParams.includeOrExcludeServices = isAllServices ? 'all' : [...newPricingParams.services];
  newPricingParams.services = ['Custom Service'];
  return newPricingParams;
};

const prepareParamIfAllValueSelected = (values) => {
  const valuesAsArray = Array.isArray(values) ? values : [values];
  if (!values || valuesAsArray.some((item) => Array.isArray(item.value) && !item.value.length)) {
    return [];
  }
  return valuesAsArray.map(({ value }) => (value === 'all' ? 'ALL' : value));
};

const prepareScopeParam = (pricingParams, mapLinkedAccIdToDivisionName) => {
  const isLa = pricingParams.isLinkedAccountsChecked;
  const items = isLa ? pricingParams.linkedAccounts : pricingParams.divNames;
  let scopes = [];
  if (Array.isArray(items)) {
    if (items.some(({ value }) => value.toLowerCase() === 'all')) {
      scopes = [{ customer: 'Internal_Pileus:Account_id:All_Customers', linkedAccountIds: 'all' }];
    } else {
      scopes = items.map(({ value }) => ({
        customer: isLa && mapLinkedAccIdToDivisionName ? mapLinkedAccIdToDivisionName.get(value) : value,
        linkedAccountIds: isLa ? value : 'all',
      }));
    }
  }
  if (scopes?.length === 1 && scopes[0]?.linkedAccountIds !== 'all') {
    scopes[0].linkedAccountIds = [scopes[0].linkedAccountIds];
  }
  if (scopes?.length > 1) {
    // combine linked account ids of duplicate customers
    const uniqueCustomers = [...new Set(scopes.map(({ customer }) => customer))];
    if (uniqueCustomers.length < scopes.length) {
      scopes = uniqueCustomers.map((cust) => {
        const linkedAccIds = scopes
          .filter((item) => item.customer === cust)
          .map(({ linkedAccountIds }) => linkedAccountIds);
        return { customer: cust, linkedAccountIds: linkedAccIds };
      });
    } else {
      scopes = scopes.map((item) => ({
        ...item,
        linkedAccountIds: !isLa || !item.linkedAccountIds ? 'all' : [item.linkedAccountIds],
      }));
    }
  }
  return scopes;
};

export const preparePPobjectForDB = (pricingParams) => {
  const billingRule = { ...pricingParams };
  billingRule.name = (pricingParams.name || '').trim();
  billingRule.customService = (pricingParams.customService || '').trim();
  billingRule.scopes = prepareScopeParam(pricingParams);
  billingRule.services = prepareParamIfAllValueSelected(pricingParams.services);
  billingRule.usageTypes =
    pricingParams.usageTypeOperator && isOperatorInput(pricingParams.usageTypeOperator)
      ? pricingParams.usageTypes
      : prepareParamIfAllValueSelected(pricingParams.usageTypes);
  billingRule.regions = prepareParamIfAllValueSelected(pricingParams.regions);
  billingRule.linkedAccounts = prepareParamIfAllValueSelected(pricingParams.linkedAccounts);
  billingRule.divNames = prepareParamIfAllValueSelected(pricingParams.divNames);
  billingRule.cloudFrontRegions = prepareParamIfAllValueSelected(pricingParams.cloudFrontRegions);
  billingRule.effectiveMonth = pricingParams.effectiveMonth ? `${pricingParams.effectiveMonth}-01` : '';
  billingRule.startDate = pricingParams.startDate ? `${pricingParams.startDate}-01` : '';
  billingRule.endDate = pricingParams.endDate
    ? `${moment(pricingParams.endDate).endOf('month').format('YYYY-MM-DD')}`
    : '';
  const preparedBillingRule = updateIfMarginTypeCustomAggPercent(pricingParams.marginType, billingRule);
  return preparedBillingRule;
};
